// noinspection JSUnusedLocalSymbols

import {Typography} from "@mui/material";
import React from 'react';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {styled} from '@mui/material/styles';

const PasswordProgressBar = (props) => {
  const {passScore} = props;

  const StyledLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: 25,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#D8EEF2",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: passScore.color,
    },
  }));

  return <div style={{position: "relative"}}>
    <StyledLinearProgress variant="determinate" value={passScore.score} />
    <Typography
      variant="body2"
      style={{
        position: "absolute",
        color: "white",
        top: 0,
        left: `15%`,
        transform: "translateX(-50%)",
      }}
    >{passScore.text}
    </Typography>
  </div>
};

export default PasswordProgressBar;