// noinspection JSUnresolvedVariable

import {combineReducers, configureStore} from "@reduxjs/toolkit";

import manifestationsReducer from "../manifestations/manifestationSlice";
import usersReducer from "../users/userSlice";
import multiManifestationsReducer from "../manifestations/multiManifestationSlice";
import relationsReducer from "../relations/relationSlice";

import {apiSlice} from "../api/apiSlice";

const combinedReducer = combineReducers({
  manifestations: manifestationsReducer,
  users: usersReducer,
  multiManifestations: multiManifestationsReducer,
  relations: relationsReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'users/logoutUser') {
    state = undefined
  }
  return combinedReducer(state, action)
}

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: getDefaultMiddleWare =>
    getDefaultMiddleWare().concat(apiSlice.middleware),
});