// noinspection DuplicatedCode

import {Grid, Typography} from "@mui/material";
import React from "react";

import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {Helmet} from "react-helmet";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";
import {DefaultInputValGetters} from "./Helpers/defaultInputValGetters";
import useReduxFormData from "../../Hooks/useReduxFormData";
import {useValidateToken} from "../../Hooks/useValidateToken";

const LifeStyleAlcohol = () => {
  useValidateToken(true, "basic");
  const [dispatch, usersState, , , manifestationsState, addManifestations] = useReduxFormData();
  const {
    ids: maniIds,
    manifestationsDirty,
    manifestationsDbPostLoading,
    entities,
    manifestationsFetchLoading
  } = manifestationsState;
  const {usersSelfRelationId} = usersState;
  const {handleFormNavigation} = useFormNavigation();

  const {getDefaultNormalFieldValue} = DefaultInputValGetters();
  const {
    handleOptValInputChange,
    handleRadioChanged,
  } = useReduxDataHandler();

  const validateInputs = () => {return true;}

  return (
    manifestationsFetchLoading
      ? undefined
      : (<>
        <Helmet>
          <title>Alcohol Usage</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
          <Grid item xs={8}>
            <Typography textAlign={"center"} variant="h2" mt={5}>Wrapping up the <b
              className="blue-emphasis">basics</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className={"questionnaire-content"}
                  onSubmit={async (e) => {
                    e.preventDefault()
                    if (validateInputs()) {
                      if (manifestationsDirty) {
                        await addManifestations(Object.values(entities).filter((m) => m.edited === true))
                          .unwrap()
                          .then(() => {dispatch(fetchManifestations(usersSelfRelationId))})
                          .then(() => handleFormNavigation(e))
                      }
                      handleFormNavigation(e)
                    }
                  }}>
              <Grid spacing={2} container justifyContent={"center"} item xs={12} ml={"auto"} mr={"auto"}
                    className="questionnaire-inner-content">
                <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                  <Grid item>
                    <label htmlFor="drinks">Do you drink alcohol (beer, mixed drinks, wine)?</label>
                  </Grid>
                  <Grid item>
                    <input type="radio"
                           value={261}
                           name={"drinks"}
                           opp-manifestation={263}
                           defaultChecked={maniIds.includes(261) || maniIds.includes("261")}
                           onChange={(e) => {
                             handleRadioChanged(e, usersSelfRelationId)
                           }} />
                    <span>&nbsp;Yes&nbsp;</span>
                    <input type="radio"
                           value={263}
                           name={"drinks"}
                           opp-manifestation={261}
                           defaultChecked={maniIds.includes(263) || maniIds.includes("263")}
                           onChange={(e) => {
                             handleRadioChanged(e, usersSelfRelationId)
                           }} />
                    <span>&nbsp;No&nbsp;</span>
                  </Grid>
                </Grid>
                {
                  (maniIds.includes(261) || maniIds.includes("261")) &&
                  <Grid container item justifyContent={{sm: "center"}} xs={8} mt={1}>
                    <Grid container item justifyContent={"center"} flexDirection={"column"} xs={12} md={6}>
                      <Grid container item justifyContent={"center"}>
                        <Grid container item justifyContent={"center"}>
                          <label htmlFor="drinking-frequency">How often?</label>
                        </Grid>
                        <Grid container item justifyContent={"center"}>
                          <select className="dropdown-input"
                                  name="drinking-frequency"
                                  manioptid={19}
                                  defaultValue={getDefaultNormalFieldValue(entities, 261, 19)}
                                  opts-for={261}
                                  onChange={(e) => {
                                    handleOptValInputChange(e, false, false, manifestationsState);
                                  }}>
                            <option value={null}>Select an option</option>
                            <option value={81}>Weekly</option>
                            <option value={82}>Monthly</option>
                            <option value={83}>Yearly</option>
                          </select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent={"center"} flexDirection={"column"} xs={12} md={6}>
                      <Grid container item justifyContent={"center"}>
                        <Grid container item justifyContent={"center"}>
                          <label htmlFor="drinking-amount">When you drink, how many do you have?</label>
                        </Grid>
                        <select className="dropdown-input"
                                name="drinking-amount"
                                manioptid={40}
                                defaultValue={getDefaultNormalFieldValue(entities, 261, 40)}
                                opts-for={261}
                                onChange={(e) => {
                                  handleOptValInputChange(e, false, false, manifestationsState);
                                }}>
                          <option value={null}>Select an option</option>
                          <option value={214}>1</option>
                          <option value={215}>2</option>
                          <option value={216}>3</option>
                          <option value={217}>4</option>
                          <option value={218}>5 or more</option>
                        </select>
                      </Grid>
                    </Grid>
                  </Grid>
                }
                <BackContinueButtons loading={manifestationsDbPostLoading} />
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>))
}

export default LifeStyleAlcohol;