// Libraries, stores, styles, hooks
import {Grid} from "@mui/material";
import React from "react";

import useExitPrompt from "../Hooks/useExitPrompt";
import "../Styles/HealthHistory.css";
import {useNavigate, useRoutes} from "react-router-dom";
import {stepOrder} from "./PatientQuestionnaire/Helpers/stepOrder";

const Questionnaire = () => {
  const [, setShowExitPrompt] = useExitPrompt(true);
  const stepElement = useRoutes(stepOrder);
  const history = useNavigate();

  React.useLayoutEffect(() => {
    // Always remove the exit prompt when clicking save and return later
    if (document.contains(document.getElementById("questionnaire-save-quit"))) {
      let saveAndQuitLink = document.getElementById("questionnaire-save-quit");
      saveAndQuitLink.addEventListener("click", () => {
        setShowExitPrompt(false);
      });
    }
    if (document.contains(document.getElementById("footer"))) {
      let footer = document.getElementById("footer");
      footer.style.display = "none";
    }
  }, [history, setShowExitPrompt]);

  React.useLayoutEffect(() => {
    if (document.contains(document.getElementById("questionnaire-save-quit"))) {
      let saveAndQuitLink = document.getElementById("questionnaire-save-quit");
      saveAndQuitLink.addEventListener("click", () => {
        setShowExitPrompt(false);
      });
    }
  }, [setShowExitPrompt])

  return (
    <Grid container justifyContent={"center"} className="questionnaire-wrapper">
      {stepElement}
    </Grid>
  )
}

export default Questionnaire;