import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  startHealthDiary,
  updateQuestionnaireReturnLater,
  updateUserLocation,
  validateToken
} from "../Redux/users/userSlice";
import {useParams} from "react-router-dom";


export function useValidateToken(bool, activeLink) {
  const usersState = useSelector(state => state.users);
  const {
    validating,
    usersHasValidToken,
    usersPatientId,
    usersSelfRelationId,
    inHealthDiary,
    usersFetchLoading,
    entities,
    ids
  } = usersState;
  const dispatch = useDispatch();
  const currLocation = useParams()["*"]?.toLowerCase()
  React.useEffect(() => {
    const getData = async () => {
      if (localStorage.getItem("userToken-v1") != null && (!validating && !usersFetchLoading)) {
        await dispatch(validateToken(localStorage.getItem("userToken-v1")));
      }
    }
    if ((bool && !inHealthDiary) || (!bool && inHealthDiary)) {
      dispatch(startHealthDiary());
      dispatch(updateUserLocation(currLocation, activeLink));
    }
    if (inHealthDiary) {
      ((entities && ids) && entities[usersPatientId] && !entities[usersPatientId].questionnaireReturnLater)
      && dispatch(updateQuestionnaireReturnLater(currLocation));
      dispatch(updateUserLocation(currLocation, activeLink));
    }
    getData().then(() => 0);
  }, []);

  return [validating, usersHasValidToken, usersPatientId, usersSelfRelationId, usersFetchLoading, entities, ids];
}