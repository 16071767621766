import {useUpdateManifestationsMutation, useUpdateUserMutation} from "../Redux/api/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {fetchUserData} from "../Redux/users/userSlice";
import {fetchManifestations} from "../Redux/manifestations/manifestationSlice";

export default function useReduxFormData() {
  const [addManifestations, {isLoading: maniApiIsLoading}] = useUpdateManifestationsMutation();
  const [updateUser, {isLoading: userApiIsLoading}] = useUpdateUserMutation();
  
  const usersState = useSelector(state => state.users);
  const {
    ids: userIds,
    usersPatientId,
    usersSelfRelationId,
    usersFetchLoading: fetchUserLoading
  } = usersState;
  
  const manifestationsState = useSelector(state => state.manifestations);
  const {ids: maniIds, manifestationsFetchLoading: fetchManiLoading} = manifestationsState;
  
  const dispatch = useDispatch();
  
  React.useEffect(() => {
    const getData = async () => {
      if (userIds.length === 0 && !fetchUserLoading) {
        await dispatch(fetchUserData(usersPatientId));
      }
      if (maniIds.length === 0 && !fetchManiLoading) {
        await dispatch(fetchManifestations(usersSelfRelationId));
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    getData()/*and theeeeeen*/
  }, []);
  
  return [
    dispatch,
    usersState,
    updateUser,
    userApiIsLoading,
    manifestationsState,
    addManifestations,
    maniApiIsLoading,
  ]
}