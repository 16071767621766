import {Grid, InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import React, {useContext} from "react";
import {Biopsy} from "../Context/80_Biopsy";
import {DefaultInputValGetters} from "../Helpers/defaultInputValGetters";
import {ReactComponent as TrashIcon} from "../../../Resources/Images/Icons/Trash.svg";

import {
  biopsyTypesAll,
  biopsyTypesMale,
  biopsyTypesFemale,
  breastResults,
  colonResults,
  endoOrUterineResults,
  prostateResults,
  thyroidOrSkinResults, maniOptionIdBiopsies
} from "../Helpers/manifestations";

const BiopsyRow = (props) => {
    const {id, typeChangeHandler, resultChangeHandler, existingBiopsy, gender} = props;
    const {deleteBiopsy} = useContext(Biopsy);
    const [microState, setMicroState] = React.useState({});
    const [resultState, setResultState] = React.useState();
    const {getModularFieldValue} = DefaultInputValGetters();
    const {existedInDb} = existingBiopsy;

    const biopsiesMap = gender === "Male"
      ? [...biopsyTypesAll, ...biopsyTypesMale]
      : [...biopsyTypesAll, ...biopsyTypesFemale];

    React.useEffect(() => {
      if (existedInDb) {
        if (Object.keys(existingBiopsy).length > 0) {
          setMicroState((prevState) => {
            return {
              ...prevState,
              type: existingBiopsy.manifestationId,
              optionId: maniOptionIdBiopsies[existingBiopsy.manifestationId],
              result: existingBiopsy.prmOptVals.length > 0
                ? existingBiopsy.prmOptVals[0].manifestationOptionValueId
                : "",
            }
          });
        }
      }
    }, [])

    let selectOptions;

    microState.type === 308 ? selectOptions = breastResults :
      microState.type === 4506 ? selectOptions = colonResults :
        (microState.type === 313 && gender === "Male") ? selectOptions = prostateResults :
          (microState.type === 306 && gender === "Female") ? selectOptions = endoOrUterineResults :
            microState.type === 4507 ? selectOptions = thyroidOrSkinResults : selectOptions = [];

    return (
      <Grid container item xs={12} flexDirection={"row"} style={{maxWidth: "720px"}} mt={1} className={"white-card"}
            id={`biopsy-wrapper-${id}`}>
        <Grid spacing={2} container item xs>
          <Grid item xs={"auto"}>
            <InputLabel id={`${id}-biopsy-type`}>Biopsy Type</InputLabel>
            <Select labelId={`${id}-biopsy-type`}
                    displayEmpty
                    required
                    name={`${id}-biopsy-type`}
                    value={microState.type ? microState.type : ""}
                    onChange={(e) => {
                      setMicroState((prevState) => {
                        return {
                          ...prevState,
                          type: e.target.value,
                          optionId: maniOptionIdBiopsies[e.target.value],
                          result: "",
                        }
                      });
                      typeChangeHandler(e)
                    }}>
              <MenuItem value={""}>Please select</MenuItem>
              {biopsiesMap
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((biopsyType) => {
                  return (
                    <MenuItem key={biopsyType.id}
                              value={biopsyType.id}>{biopsyType.name}</MenuItem>
                  )
                })}
            </Select>
          </Grid>
          <Grid item xs={"auto"}>
            <InputLabel id={`${id}-biopsy-result`}>Result</InputLabel>
            <Select labelId={`${id}-biopsy-result`}
                    displayEmpty
                    required
                    name={`${id}-${microState.type}-biopsy-result`}
                    id={`${id}-${microState.result}`}
                    value={microState.result ? microState.result : ""}
                    onChange={(e) => {
                      setMicroState((prevState) => {
                        return {
                          ...prevState,
                          result: e.target.value,
                        }
                      });
                      resultChangeHandler(e);
                    }}>
              <MenuItem value={""}>Please select</MenuItem>
              {selectOptions}
            </Select>
          </Grid>
        </Grid>
        <Grid container item xs={"auto"}>
          <Tooltip title={"Delete this entry"}>
            <button className="trash-button"
                    style={{marginTop: "20px"}}
                    onClick={() => deleteBiopsy(id)}>
              <TrashIcon aria-label={"Delete this entry"} />
            </button>
          </Tooltip>
        </Grid>
      </Grid>
    )
  }
;

export default BiopsyRow;