// noinspection DuplicatedCode

import {Grid, TextField} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import {DefaultInputValGetters} from "../Helpers/defaultInputValGetters";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

export const ScreeningBoxMonthYear = (props) => {
  const {labelText, inputName, manifestationsState, maniId, handleInputChange} = props;
  const {entities} = manifestationsState;
  const {getDateFieldValue} = DefaultInputValGetters();
  const [dateState, setDateState] = React.useState({});
  const [loadNum, setLoadNum] = React.useState(0);

  React.useEffect(() => {
    setLoadNum(loadNum + 1);
    if (dateState.month && dateState.year) {
      handleInputChange(dateState, maniId, loadNum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateState])

  React.useEffect(() => {
    const date = getDateFieldValue(entities, maniId, "existing");
    if (date) {
      setDateState(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container item xs={11} sm={8} md={5} justifyContent={"space-between"} className={"white-card"} ml={"auto"}
          mr={"auto"}>
      <Grid item>
        <label
          htmlFor={`${inputName}`}
          className={"sublabel"}>
          {labelText}
        </label>
      </Grid>
      <Grid item mt={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['year', 'month']}
            label="Month and Year"
            openTo={'year'}
            minDate={dayjs('2008-01-01')}
            maxDate={`${dayjs().get('year')}-${dayjs().get('month') + 1}`}
            value={dayjs(`${dateState.year}-${dateState.month}`)}
            onChange={(newValue) => {
              setDateState({...dateState, month: (newValue["$M"] + 1), year: newValue["$y"]});
            }}
            renderInput={(params) => <TextField {...params} helperText={null} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  )
}
export const ScreeningBoxMonthYearRadio = (props) => {
  const {
    labelText, secondLabelText,
    inputName, secondInputName, secondRadioOpts,
    handleRadioChange, handleInputChange,
    manifestationsState, maniId, prId
  } = props;

  const {ids: maniIds, entities} = manifestationsState;
  const {getDateFieldValue} = DefaultInputValGetters();
  const [dateState, setDateState] = React.useState({});
  const [loadNum, setLoadNum] = React.useState(0);

  React.useEffect(() => {
    setLoadNum(loadNum + 1);
    if (dateState.month && dateState.year) {
      handleInputChange(dateState, maniId, loadNum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateState])

  React.useEffect(() => {
    const date = getDateFieldValue(entities, maniId, "existing");
    if (date) {
      setDateState(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container item xs={11} sm={8} md={5} className={"white-card"} ml={"auto"}
          mr={"auto"}>
      <Grid container item justifyContent={"space-between"} flexDirection={"row"}>
        <Grid item>
          <label htmlFor={`${inputName}`}
                 className={"sublabel"}>
            {labelText}
          </label>
        </Grid>
        <Grid item mt={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year', 'month']}
              label="Month and Year"
              openTo={'year'}
              minDate={dayjs('2008-01-01')}
              maxDate={`${dayjs().get('year')}-${dayjs().get('month') + 1}`}
              value={dayjs(`${dateState.year}-${dateState.month}`)}
              onChange={(newValue) => {
                setDateState({...dateState, month: (newValue["$M"] + 1), year: newValue["$y"]});
              }}
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container item justifyContent={"space-between"}>
        <Grid item>
          <label className={"sublabel"}
                 htmlFor={`${secondInputName}`}>
            {secondLabelText}
          </label>
        </Grid>
        <Grid item>
          <input type="radio"
                 value={secondRadioOpts[0]}
                 opp-manifestation={secondRadioOpts[1]}
                 name={`${secondInputName}`}
                 defaultChecked={maniIds.includes(secondRadioOpts[0]) || maniIds.includes(secondRadioOpts[0].toString())}
                 onChange={(e) => handleRadioChange(e, prId)}
          /><span>&nbsp;Yes&nbsp;</span>
          <input type="radio"
                 value={secondRadioOpts[1]}
                 opp-manifestation={secondRadioOpts[0]}
                 name={`${secondInputName}`}
                 defaultChecked={maniIds.includes(secondRadioOpts[1]) || maniIds.includes(secondRadioOpts[1].toString())}
                 onChange={(e) => handleRadioChange(e, prId)}
          /><span>&nbsp;No&nbsp;</span>
        </Grid>
      </Grid>
    </Grid>
  )
}
