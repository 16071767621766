// noinspection DuplicatedCode

import {Grid, Typography} from "@mui/material";
import React from "react";

import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {Helmet} from "react-helmet";
import {ScreeningBoxMonthYear} from "./QuestionnaireComponents/ScreeningBoxes";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";
import useReduxFormData from "../../Hooks/useReduxFormData";
import {useValidateToken} from "../../Hooks/useValidateToken";

const BreastCancerScreenings = () => {
  useValidateToken(true, "health");
  const [dispatch, usersState, , , manifestationsState, addManifestations] = useReduxFormData();
  const {
    ids: maniIds,
    manifestationsDirty,
    manifestationsDbPostLoading,
    entities,
    manifestationsFetchLoading
  } = manifestationsState;
  const {usersSelfRelationId} = usersState;
  const {handleFormNavigation} = useFormNavigation();

  const {handleDateColChange, handleRadioChanged} = useReduxDataHandler();

  const validateInputs = () => {return true;}

  return (
    manifestationsFetchLoading
      ? undefined
      : (<>
          <Helmet>
            <title>Breast Cancer Screenings</title>
          </Helmet>
          <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
            <Grid item>
              <Typography textAlign={"center"} variant="h2" mt={5}>
                Let's review your <b className="blue-emphasis">cancer screenings</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form className={"questionnaire-content"}
                    onSubmit={async (e) => {
                      e.preventDefault()
                      if (validateInputs()) {
                        if (manifestationsDirty) {
                          await addManifestations(Object.values(entities).filter((m) => m.edited === true))
                            .unwrap()
                            .then(() => {dispatch(fetchManifestations(usersSelfRelationId))})
                            .then(() => handleFormNavigation(e))
                        }
                        handleFormNavigation(e)
                      }
                    }}>
                <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                      className="questionnaire-inner-content">
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="selfBreastExams">Do you do self-breast exams?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={273}
                             name={"selfBreastExams"}
                             opp-manifestation={4476}
                             defaultChecked={maniIds.includes(275) || maniIds.includes("275")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }} />
                      <span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4476}
                             name={"selfBreastExams"}
                             opp-manifestation={273}
                             defaultChecked={maniIds.includes(4476) || maniIds.includes("4476")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }} />
                      <span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(273) || maniIds.includes("273")) &&
                    <ScreeningBoxMonthYear labelText="When did you do your last exam?"
                                           inputName="selfBreastExams"
                                           manifestationsState={manifestationsState}
                                           maniId={273}
                                           handleInputChange={handleDateColChange} />
                  }
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="clinicalBreastExams">
                        Has your doctor ever done a breast exam for you?
                      </label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={275}
                             name={"clinicalBreastExams"}
                             opp-manifestation={4475}
                             defaultChecked={maniIds.includes(275) || maniIds.includes("275")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }} />
                      <span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4475}
                             name={"clinicalBreastExams"}
                             opp-manifestation={275}
                             defaultChecked={maniIds.includes(4475) || maniIds.includes("4475")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }} />
                      <span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(275) || maniIds.includes("275")) &&
                    <ScreeningBoxMonthYear labelText="When was your last doctor exam?"
                                           inputName="clinicalBreastExams"
                                           manifestationsState={manifestationsState}
                                           maniId={275}
                                           handleInputChange={handleDateColChange} />
                  }
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="hadMammogram">
                        Have you ever had a mammogram?
                      </label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={277}
                             name={"hadMammogram"}
                             opp-manifestation={4477}
                             defaultChecked={maniIds.includes(277) || maniIds.includes("277")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4477}
                             name={"hadMammogram"}
                             opp-manifestation={277}
                             defaultChecked={maniIds.includes(4477) || maniIds.includes("4477")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(277) || maniIds.includes("277")) &&
                    <ScreeningBoxMonthYear labelText="When did you have your last mammogram?"
                                           inputName="hadMammogram"
                                           manifestationsState={manifestationsState}
                                           maniId={277}
                                           handleInputChange={handleDateColChange} />
                  }
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="breastMriOrUltrasound">
                        Have you ever had a breast MRI or ultrasound?
                      </label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={296}
                             name={"breastMriOrUltrasound"}
                             opp-manifestation={4480}
                             defaultChecked={maniIds.includes(296) || maniIds.includes("296")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4480}
                             name={"breastMriOrUltrasound"}
                             opp-manifestation={296}
                             defaultChecked={maniIds.includes(4480) || maniIds.includes("4480")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(296) || maniIds.includes("296")) &&
                    <ScreeningBoxMonthYear labelText="When was the last one?"
                                           inputName="breastMriOrUltrasound"
                                           manifestationsState={manifestationsState}
                                           maniId={296}
                                           handleInputChange={handleDateColChange} />
                  }
                  <BackContinueButtons loading={manifestationsDbPostLoading} />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </>
      ))
}

export default BreastCancerScreenings;