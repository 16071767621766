import React, {useState} from "react";
import {Grid, MenuItem, Select, Tooltip} from "@mui/material";
import {ReactComponent as TrashIcon} from "../../../Resources/Images/Icons/Trash.svg";
import {ancestryOptions} from "../Helpers/manifestations";
import {useDispatch} from "react-redux";
import {relationsMultiManiAddEdit} from "../../../Redux/relations/relationSlice";

export const RelationAncestryFormRow = (props) => {
  const {ancestry, deleteAncestry, relationReactId} = props;
  const [selected, setSelected] = useState(ancestry.manifestationId);
  const dispatch = useDispatch();

  return (
    <Grid container item xs={"auto"}>
      <Select
        name={`${relationReactId}`}
        displayEmpty
        value={selected}
        onChange={(e) => {
          setSelected(e.target.value);
          dispatch(relationsMultiManiAddEdit(relationReactId, ancestry, e.target.value));
        }}>
        {ancestryOptions.map((option, index) => {
          return <MenuItem key={index}
                           value={option.id}>{option.label}</MenuItem>
        })}
      </Select>
      <Tooltip title={"Delete this entry"}>
        <button className="trash-button"
                onClick={(e) => {
                  e.preventDefault();
                  deleteAncestry(ancestry["reactId"], relationReactId);
                }}>
          <TrashIcon aria-label={"Delete this entry"} />
        </button>
      </Tooltip>
    </Grid>
  )
}