import {Grid, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import {useDispatch} from "react-redux";
import {relationsMultiManiAddEdit} from "../../../Redux/relations/relationSlice";
import {ReactComponent as TrashIcon} from "../../../Resources/Images/Icons/Trash.svg";
import React, {useState} from "react";
import InputValLimiters from "../Helpers/inputValLimiters";
import {DefaultInputValGetters} from "../Helpers/defaultInputValGetters";
import {allCancers, femaleCancers, guessList, hasMoreValues, maleCancers} from "../Helpers/manifestations";
import {useRelationModalChangeHandlers} from "../../../Hooks/useRelationModalChangeHandlers";
import {labelAndOptValsByOptId} from "../Helpers/optValObjsCancer";

export const RelationCancerFormRow = (props) => {
  const {existingCancer, deleteCancerHist, relationGender, relationReactId} = props;
  const existedInDb = existingCancer.prmId ? true : false;
  const {reactId: manifestationReactId} = existingCancer;
  const {getMultiInputModularFieldValue} = DefaultInputValGetters();
  const [hasMore, setHasMore] = useState([]);
  const [microState, setMicroState] = useState(existedInDb ? {
    age: existingCancer.ageOfDiagnosis,
    type: existingCancer.manifestationId,
    confidence: existingCancer.confidenceInAgeOfDiagnosis,
  } : {});
  const dispatch = useDispatch();
  const {handleRelationColDataChange, handleRelationCancerResultChange} = useRelationModalChangeHandlers();

  const cancersMap =
    relationGender === "male"
      ? [...allCancers, ...maleCancers]
      : [...allCancers, ...femaleCancers]

  const deleteButton = (
    <Grid item xs={"auto"} ml={"auto"} alignSelf={"flex-end center"}>
      <Tooltip title={"Delete this entry"}>
        <button className="trash-button"
                style={{marginTop: "20px",}}
                onClick={() => {
                  deleteCancerHist(existingCancer["reactId"], relationReactId)
                }}>
          <TrashIcon />
        </button>
      </Tooltip>
    </Grid>
  )

  const checkForMoreQuestions = (maniId) => {
    if (hasMoreValues[`${maniId}-relation`]) {
      setHasMore(hasMoreValues[`${maniId}-relation`]);
    }
    if (!hasMoreValues[`${maniId}-relation`]) {
      setHasMore([]);
    }
  }

  React.useEffect(() => {
    if (existedInDb) {
      if (existingCancer.prmOptVals && existingCancer.prmOptVals.length > 0) {
        let more = [];
        existingCancer.prmOptVals
          .forEach((optVal) => {
            setMicroState((prevState) => {
              return {
                ...prevState,
                [optVal.manifestationOptionId]: optVal.manifestationOptionValueId
              }
            })
            more.push(optVal.manifestationOptionId)
          })
        setHasMore(more);
      }
    }
  }, [])

  if (existingCancer) {
    return (
      <Grid container item mt={1}
            className={"white-card"}
            id={`cancer-${manifestationReactId}`}
            key={`cancer-${manifestationReactId}`}>
        <Grid columnSpacing={2} container item>
          <Grid item xs={"auto"}>
            <InputLabel id={`${relationReactId}-${manifestationReactId}-cancer-type`}>
              Type of Cancer
            </InputLabel>
            <Select
              name={`${manifestationReactId}-cancer-type`}
              labelId={`${relationReactId}-${manifestationReactId}-cancer-type`}
              required
              displayEmpty
              value={microState.type ? microState.type : ""}
              onChange={(e) => {
                setMicroState((prevState) => {
                  return {
                    ...prevState,
                    type: e.target.value
                  }
                });
                checkForMoreQuestions(e.target.value);
                dispatch(relationsMultiManiAddEdit(relationReactId, existingCancer, e.target.value));
              }}>
              <MenuItem value={""}>Select a Cancer</MenuItem>
              {
                cancersMap
                  .sort((a, b) => (a.label > b.label) ? 1 : -1)
                  .map((cancer, index) => {
                    return (<MenuItem key={index}
                                      value={cancer.id}>{cancer.label}</MenuItem>)
                  })
              }
            </Select>
          </Grid>
          <Grid item xs={"auto"}>
            <InputLabel htmlFor={`${manifestationReactId}-ageOfDiagnosis`}
                        id={`${relationReactId}-${manifestationReactId}-age-diag`}>
              Age at Diagnosis
            </InputLabel>
            <TextField
              aria-labelledby={`${relationReactId}-${manifestationReactId}-age-diag`}
              style={{maxWidth: "120px"}}
              name={`${manifestationReactId}-ageOfDiagnosis`}
              required
              inputProps={{inputMode: "numeric", pattern: "[0-9]*", title: "Numbers only"}}
              id={`${manifestationReactId}-ageOfDiagnosis`}
              placeholder="Age"
              value={microState.age ? microState.age : ""}
              onChange={(e) => {
                setMicroState((prevState) => {
                  return {
                    ...prevState,
                    age: e.target.value
                  }
                });
                handleRelationColDataChange(e, relationReactId);
              }} />
          </Grid>
          <Grid item xs={"auto"}>
            <InputLabel id={`${manifestationReactId}-confidence`}>
              How sure are you of the age?
            </InputLabel>
            <Select
              name={`${manifestationReactId}-confidenceInAgeOfDiagnosis`}
              displayEmpty
              labelId={`${manifestationReactId}-confidence`}
              value={microState.confidence ? microState.confidence : ""}
              onChange={(e) => {
                setMicroState((prevState) => {
                  return {
                    ...prevState,
                    confidence: e.target.value
                  }
                });
                handleRelationColDataChange(e, relationReactId);
              }}>
              {guessList}
            </Select>
          </Grid>
          {
            hasMore.length > 0
              ? (hasMore.map((id, index) => {
                return (
                  <Grid item key={`cancer-more-${manifestationReactId}-${index}`}>
                    <InputLabel id={`${manifestationReactId}-${id}`}>
                      {labelAndOptValsByOptId[id].label}
                    </InputLabel>
                    <Select
                      labelId={`${manifestationReactId}-${id}`}
                      displayEmpty
                      name={`${manifestationReactId}-${id}-cancer-opt`}
                      id={`${manifestationReactId}-${id}`}
                      value={microState[id] ? microState[id] : ""}
                      onChange={(e) => {
                        setMicroState({...microState, [id]: e.target.value});
                        handleRelationCancerResultChange(e, relationReactId);
                      }}>
                      {labelAndOptValsByOptId[id].select}
                    </Select>
                  </Grid>)
              }))
              : undefined
          }
          {deleteButton}
        </Grid>
      </Grid>)
  }
}