import React, { useEffect, createRef } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const TitleAnnouncer = () => {
  const [title, setTitle] = React.useState("");
  const titleRef = createRef();
  const { pathname } = useLocation();
  const onHelmetChange = ({ title }) => setTitle(title);

  useEffect(() => {
    if (titleRef.current) titleRef.current.focus();
  }, [pathname, titleRef]);

  return (
    <>
      <p tabIndex={-1} ref={titleRef} className="hidden">
        {title}
      </p>
      <Helmet onChange={onHelmetChange} />
    </>
  );
};

export default TitleAnnouncer;
