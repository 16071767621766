// noinspection DuplicatedCode

import {Grid, Typography} from "@mui/material";
import React from "react";
import {Helmet} from "react-helmet";
import DualCounter from "./FamilyTreeComponents/dualCounter";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useValidateToken} from "../../Hooks/useValidateToken";
import {useRelations} from "../../Hooks/useRelations";
import {useDispatch} from "react-redux";
import {addRelationsToDb, fetchRelations} from "../../Redux/relations/relationSlice";
import {filterRelationsIntoArray, filterRelationsWithNoData} from "./Helpers/filterRelations";
import {relationIDs} from "./Helpers/relationsMap";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import useReduxFormData from "../../Hooks/useReduxFormData";

const TreeList_FathersRelations = () => {
  const {handleRadioChanged} = useReduxDataHandler();

  useValidateToken(true, "history");
  const [, , , , manifestationsState, addManifestations] = useReduxFormData();
  const {entities} = manifestationsState;

  const {handleFormNavigation} = useFormNavigation();
  const [selfRelativeState, setSelfRelativeState] = React.useState({});
  const dispatch = useDispatch();

  const {relationsIsLoading, relationsState} = useRelations();

  const {
    relationsUserId,
    fatherPrId,
    dadGrandmaId,
    dadGrandpaId,
    dadGrandpaMomId,
    dadGrandpaDadId,
    dadGrandmaMomId,
    dadGrandmaDadId,
    relationsDbPosting,
    fatherRelation
  } = relationsState;

  const parentManis = []

  React.useEffect(() => {
    if (fatherRelation && Object.keys(fatherRelation).length) {
      for (let i = 0; i < fatherRelation.relationManis.length; i++) {
        parentManis.push(fatherRelation.relationManis[i].manifestationId)
      }
    }
  }, [fatherRelation])

  return (
    relationsIsLoading
      ? undefined
      : <>
        <Helmet>
          <title>Your Dad's Family</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
          <Grid item>
            <Typography textAlign={"center"} variant="h2" mt={5}>
              Tell us about your <b className="blue-emphasis">Dad's side of the family</b>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className="questionnaire-content family-tree"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const filtered = filterRelationsWithNoData(selfRelativeState, relationsUserId);
                    const arrToPost = filterRelationsIntoArray(filtered, relationsUserId);
                    await addManifestations(Object.values(entities).filter((m) => m.edited === true))
                      .unwrap()
                      .then(() => {
                        dispatch(addRelationsToDb(arrToPost))
                      })
                      .then(() => {
                        dispatch(fetchRelations())
                          .then(() => {
                            handleFormNavigation(e);
                          })
                      })
                  }}>

              <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                    className="questionnaire-inner-content">
                {!relationsIsLoading &&
                  <>
                    <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                      <Grid item xs={6} md={4}>
                        <label htmlFor="unknownFHDad">
                          Do you know about your dad's family history?
                        </label>
                      </Grid>
                      <Grid item>
                        <input type="radio"
                               name={"unknownFHDad"}
                               value={4519}
                               opp-manifestation={163}
                               defaultChecked={parentManis.includes(4519) || parentManis.includes("4519")}
                               onChange={(e) => {
                                 handleRadioChanged(e, fatherPrId)
                               }}
                        /><span>&nbsp;Yes&nbsp;</span>
                        <input type="radio"
                               name={"unknownFHDad"}
                               value={163}
                               opp-manifestation={4519}
                               defaultChecked={parentManis.includes(163) || parentManis.includes("163")}
                               onChange={(e) => {
                                 handleRadioChanged(e, fatherPrId)
                               }}
                        /><span>&nbsp;No&nbsp;</span>
                      </Grid>
                    </Grid>

                    <div className={"family-tree-counters"}>
                      <div className={"family-tree-labels"}>
                        <label className="bold-text"
                               style={{marginBottom: "25px"}}>How many of the following do you have?</label>
                      </div>
                      <label className="blue-label">Dad's Family</label>
                      <br />
                      <DualCounter relationId={relationIDs.Uncle}
                                   side={"Paternal"}
                                   parentIds={{motherPrId: dadGrandmaId, fatherPrId: dadGrandpaId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState} />
                      <DualCounter relationId={relationIDs.Aunt}
                                   side={"Paternal"}
                                   parentIds={{motherPrId: dadGrandmaId, fatherPrId: dadGrandpaId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState} />
                      <label className="blue-label">Your paternal grandmother's family (Dad's Mom)</label>
                      <DualCounter relationId={relationIDs.Great_Uncle}
                                   side={"Paternal"}
                                   parentIds={{motherPrId: dadGrandmaMomId, fatherPrId: dadGrandmaDadId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState} />
                      <DualCounter relationId={relationIDs.Great_Aunt}
                                   side={"Paternal"}
                                   parentIds={{motherPrId: dadGrandmaMomId, fatherPrId: dadGrandmaDadId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState} />
                      <label className="blue-label">Your paternal grandfather's family (Dad's Dad)</label>
                      <DualCounter relationId={relationIDs.Great_Uncle}
                                   side={"Paternal"}
                                   parentIds={{motherPrId: dadGrandpaMomId, fatherPrId: dadGrandpaDadId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState}
                                   parentKey={"a" + relationIDs.Great_Uncle}//for uniqueness on the page
                      />
                      <DualCounter relationId={relationIDs.Great_Aunt}
                                   side={"Paternal"}
                                   parentIds={{motherPrId: dadGrandpaMomId, fatherPrId: dadGrandpaDadId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState}
                                   parentKey={"a" + relationIDs.Great_Aunt}//for uniqueness on the page
                      />
                    </div>
                  </>
                }
                <BackContinueButtons loading={relationsDbPosting} />
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>
  );
}

export default TreeList_FathersRelations