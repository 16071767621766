import React from "react";
import {KeyboardArrowDown} from "@mui/icons-material";
import {
  Accordion, AccordionSummary,
  CardContent,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from "@mui/material";

const RelationConditions = (props) => {
  const {cancerManis, otherManis, expanded, nameOrLabel} = props;

  return (
    ((cancerManis.length > 0 || otherManis.length > 0)) &&
    <>
      <hr />
      <Collapse in={!expanded} unmountOnExit>
        <Accordion>
          <AccordionSummary expandIcon={<KeyboardArrowDown />}>
            <Typography>{`${nameOrLabel}'s conditions`}</Typography>
          </AccordionSummary>
          <CardContent>
            {(cancerManis.length > 0) &&
              <TableContainer component={Paper}>
                <Table size={"small"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <label>Cancer{cancerManis.length > 1 ? "s" : ""}</label>
                      </TableCell>
                      <TableCell>
                        <label>Age of Diagnosis</label>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cancerManis.map((mani, index) => {
                      return (
                        <TableRow key={`${mani.id}-${index}`}>
                          <TableCell sx={{borderBottom: 0}}>
                            <label>{mani.manifestationName}</label>
                          </TableCell>
                          <TableCell sx={{borderBottom: 0}} id={`cancer-age-${index}`}>
                            <label>{mani.ageOfDiagnosis}</label>
                          </TableCell>
                        </TableRow>
                      )
                    })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            }
            <br />
            {(otherManis.length > 0) &&
              <TableContainer component={Paper}>
                <Table size={"small"}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <label>Other Condition{otherManis.length > 1 ? "s" : ""}</label>
                      </TableCell>
                      <TableCell>
                        <label>Age of Diagnosis</label>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {otherManis.map((mani, index) => {
                      return (
                        <TableRow key={`${mani.id}-${index}`}>
                          <TableCell sx={{borderBottom: 0}}>
                            <label>{mani.manifestationName}</label>
                          </TableCell>
                          <TableCell sx={{borderBottom: 0}} id={`cancer-age-${index}`}>
                            <label>{mani.ageOfDiagnosis}</label>
                          </TableCell>
                        </TableRow>
                      )
                    })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </CardContent>
        </Accordion>
      </Collapse>
    </>)
}


export default RelationConditions;