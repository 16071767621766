import {Grid, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import React from "react";
import {useDispatch} from "react-redux";
import {relationsMultiManiAddEdit} from "../../../Redux/relations/relationSlice";
import {ReactComponent as TrashIcon} from "../../../Resources/Images/Icons/Trash.svg";
import {useRelationModalChangeHandlers} from "../../../Hooks/useRelationModalChangeHandlers";
import {otherHistoryList, guessList, polypOptions} from "../Helpers/manifestations";

export const RelationOtherFormRow = (props) => {
  const {existingOther, deleteOtherHist, relationGender, relationReactId} = props;
  const existedInDb = existingOther.prmId ? true : false;
  const {reactId: manifestationReactId} = existingOther;
  const dispatch = useDispatch();
  const {
    handleRelationColDataChange,
    handleRelationCancerResultChange: handlePolypResultChange
  } = useRelationModalChangeHandlers();
  const [showPolypOptions, setShowPolypOptions] = React.useState(false);
  const existingOptVals =
    (existingOther && (existingOther.prmOptVals && existingOther.prmOptVals.length > 0))
      ? existingOther.prmOptVals
      : undefined;
  const [microState, setMicroState] = React.useState({});

  React.useEffect(() => {
    if (existingOther) {
      if (Object.keys(existingOther).length > 0) {
        setMicroState((prevState) => {
          return {
            ...prevState,
            age: existingOther.ageOfDiagnosis,
            type: existingOther.manifestationId,
            confidence: existingOther.confidenceInAgeOfDiagnosis,
            polypType: existingOptVals ? existingOther.prmOptVals[0].manifestationOptionValueId : "",
            polypQuantity: existingOptVals ? existingOther.quantity : ""
          }
        })
      }
    }
  }, []);

  React.useEffect(() => {
    if (existingOptVals) {
      setShowPolypOptions(true);
    }
  }, [existingOptVals])

  const deleteButton = (
    <Grid item xs={"auto"} ml={"auto"} alignSelf={"flex-end center"}>
      <Tooltip title={"Delete this entry"}>
        <button className="trash-button"
                style={{marginTop: "3.75rem"}}
                onClick={() => {
                  deleteOtherHist(existingOther["reactId"], relationReactId)
                }}>
          <TrashIcon aria-label={"Delete this entry"} />
        </button>
      </Tooltip>
    </Grid>
  )

  if (existingOther) {
    return (<>
      <Grid container item mt={1}
            className={"white-card"}
            id={`other-${manifestationReactId}`}
            key={`other-${manifestationReactId}`}>
        <Grid columnSpacing={2} container item>
          <Grid item xs={"auto"}>
            <InputLabel id={`${manifestationReactId}-other`}>Condition</InputLabel>
            <Select
              name={`${manifestationReactId}-other-type`}
              labelId={`${manifestationReactId}-other`}
              required
              displayEmpty
              value={microState.type ? microState.type : ""}
              onChange={(e) => {
                if (e.target.value === 1427) {
                  setShowPolypOptions(true);
                }
                setMicroState({
                  ...microState,
                  type: e.target.value
                })
                dispatch(relationsMultiManiAddEdit(relationReactId, existingOther, e.target.value));
              }}>
              <MenuItem value={""}>Select a Condition</MenuItem>
              {
                otherHistoryList.map((other, index) => {
                  return ((other.gender === "" || other.gender === undefined) || other.gender.toLowerCase() === relationGender) ?
                    (<MenuItem key={index}
                               value={parseInt(other.id)}>{other.label}</MenuItem>)
                    : undefined
                })
              }
            </Select>
          </Grid>
          <Grid item xs={"auto"}>
            <InputLabel htmlFor={`${manifestationReactId}-ageOfDiagnosis`} id={`${manifestationReactId}-ageDiag`}>
              Age at Diagnosis
            </InputLabel>
            <TextField
              aria-labelledby={`${manifestationReactId}-ageDiag`}
              name={`${manifestationReactId}-ageOfDiagnosis`}
              required
              style={{maxWidth: "120px"}}
              inputProps={{inputMode: "numeric", pattern: "[0-9]*", title: "Numbers only"}}
              id={`${manifestationReactId}-ageOfDiagnosis`}
              placeholder="Age"
              value={microState.age ? microState.age : ""}
              onChange={(e) => {
                setMicroState({
                  ...microState,
                  age: e.target.value
                })
                handleRelationColDataChange(e, relationReactId);
              }} />
          </Grid>
          <Grid item xs={"auto"}>
            <InputLabel id={`${manifestationReactId}-confidence`}>
              How sure are you of the age?
            </InputLabel>
            <Select
              name={`${manifestationReactId}-confidenceInAgeOfDiagnosis`}
              labelId={`${manifestationReactId}-confidence`}
              displayEmpty
              id={`${manifestationReactId}-confidenceInAgeOfDiagnosis`}
              value={microState.confidence ? microState.confidence : ""}
              onChange={(e) => {
                setMicroState({...microState, confidence: e.target.value})
                handleRelationColDataChange(e, relationReactId);
              }}>
              {guessList}
            </Select>
          </Grid>
          {
            showPolypOptions
              ? (
                <Grid spacing={2} container item>
                  <Grid item xs={"auto"}>
                    <InputLabel id={`${manifestationReactId}-polyps`}>
                      Polyp Type
                    </InputLabel>
                    <Select
                      name={`${manifestationReactId}-57`}
                      labelId={`${manifestationReactId}-polyps`}
                      displayEmpty
                      value={microState.polypType ? microState.polypType : ""}
                      onChange={(e) => {
                        setMicroState({...microState, polypType: e.target.value})
                        handlePolypResultChange(e, relationReactId);
                      }}>
                      {polypOptions}
                    </Select>
                  </Grid>
                  <Grid item xs={"auto"}>
                    <InputLabel id={`${manifestationReactId}-polypQty`}>
                      How many polyps?
                    </InputLabel>
                    <TextField
                      name={`${manifestationReactId}-quantity`}
                      style={{maxWidth: "120px"}}
                      inputProps={{inputMode: "numeric", pattern: "[0-9]*", title: "Numbers only"}}
                      placeholder="Quantity"
                      value={microState.polypQuantity ? microState.polypQuantity : ""}
                      onChange={(e) => {
                        setMicroState({...microState, polypQuantity: e.target.value})
                        handleRelationColDataChange(e, relationReactId);
                      }} />
                  </Grid>
                </Grid>)
              : undefined
          }
          {deleteButton}
        </Grid>
      </Grid>
    </>)
  }
}