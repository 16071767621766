import {createContext, useState} from "react";
import {randomId} from "../../../Helpers/utils";
import {useDispatch} from "react-redux";
import {
  relationsGeneDelete,
  relationsGeneInitialAdd
} from "../../../Redux/relations/relationSlice";

export const RelationGenes = createContext({});

export const GeneProvider = ({children}) => {
  const [genes, setGenes] = useState([]);
  const [baseManifestationReactId, setBaseManifestationReactId] = useState("");

  const dispatch = useDispatch();

  const deleteGene = (relationReactId, kgmManiReactId, geneOptValReactId) => {
    const {existedInDb} = genes.filter((gene) => gene["reactId"] === geneOptValReactId)[0];
    setGenes(genes.filter((gene) => gene["reactId"] !== geneOptValReactId));
    dispatch(relationsGeneDelete(relationReactId, kgmManiReactId, geneOptValReactId));

  }

  const addGene = (relationReactId, hasKgmMani) => {
    if (!hasKgmMani) {
      dispatch(relationsGeneInitialAdd(relationReactId, baseManifestationReactId));
    }
    setGenes(prevState => {
      return [...prevState, {
        reactId: randomId() + randomId(),
        existedInDb: false,
      }]
    })
  }

  return (
    <RelationGenes.Provider
      value={{genes, setGenes, baseManifestationReactId, setBaseManifestationReactId, addGene, deleteGene}}>
      {children}
    </RelationGenes.Provider>
  )
}