import React from "react";
import AddRelationButton from "./addRelationButton";
import RelationCard from "./relationCard";

import {Grid, Typography} from "@mui/material";

const RenderParental = (props) => {
  const {relationsToShow, parentalRelations, parentLabel, sideLabel, isMobile} = props;

  // Order is guaranteed by API
  const grandmother = parentalRelations["ancestors"][0];
  const grandfather = parentalRelations["ancestors"][1];

  // Great Grandmother (grandma's mom)
  const grandmotherMother = grandmother["ancestors"][0];
  // Great Grandfather (grandma's dad)
  const grandmotherFather = grandmother["ancestors"][1];

  // Great Grandmother (grandpa's mom)
  const grandfatherMother = grandfather["ancestors"][0];
  // Great Grandfather (grandpa's dad)
  const grandfatherFather = grandfather["ancestors"][1];


  return (<>
      {
        <>
          {relationsToShow === "all" && <hr style={{marginTop: "25px"}} />}
          <Typography textAlign={"center"} variant={"h2"}
                      mt={relationsToShow === "all" ? 1 : 5}
                      className={"orange-label"}>Your {parentLabel} & {parentLabel === "father" ? "his" : "her"} relatives</Typography>
        </>
      }
      <Typography
        variant="h5"
        textAlign={"left"} mt={5}
        className={"blue-label"}>
        Your {parentLabel}
      </Typography>
      <RelationCard
        reactKey={parentalRelations.reactId}
        entity={parentalRelations}
        multiBirthSelection={parentalRelations["fullSiblings"]}
        isMobile={isMobile}
      />
      {
        <>
          <Grid item xs={11} lg={8} ml="auto" mr="auto">
            <Typography
              variant="h5"
              textAlign={"left"} mt={5}
              className={"blue-label"}>
              {parentalRelations["fullSiblings"]?.length >= 1
                ? `Your ${parentLabel}'s siblings`
                : `Add your ${parentLabel}'s siblings`
              }
            </Typography>
          </Grid>
          <AddRelationButton
            label="Add Aunt/Uncle"
            side={sideLabel}
            types={["Aunt", "Uncle"]}
            parentIds={{
              motherPrId: grandmother["patientAndRelationsId"],
              fatherPrId: grandfather["patientAndRelationsId"],
            }} />
          {
            parentalRelations["fullSiblings"]?.length > 0 &&
            parentalRelations["fullSiblings"].map((sib) => {
              return (
                <RelationCard
                  key={sib.reactId}
                  reactKey={sib.reactId}
                  entity={sib}
                  multiBirthSelection={[...parentalRelations["fullSiblings"], parentalRelations]}
                  isMobile={isMobile}
                />
              )
            })}
        </>
      }
      {
        <>
          <Typography
            variant="h5"
            textAlign={"left"} mt={5}
            className={"blue-label"}>
            Your {sideLabel.toLowerCase()} grandmother
          </Typography>
          <RelationCard
            key={grandmother.reactId}
            reactKey={grandmother.reactId}
            entity={grandmother}
            multiBirthSelection={grandmother["fullSiblings"]}
            isMobile={isMobile} />
          {
            <>
              <Grid item xs={11} lg={8} ml="auto" mr="auto">
                <Typography
                  variant="h5"
                  textAlign={"left"} mt={5}
                  className={"blue-label"}>
                  {grandmother["fullSiblings"]?.length >= 1
                    ? `Your ${sideLabel.toLowerCase()} grandmother's ${grandmother["fullSiblings"]?.length === 1 ? "sibling" : "siblings"}`
                    : `Add your ${sideLabel.toLowerCase()} grandmother's siblings`
                  }
                </Typography>
              </Grid>
              <AddRelationButton
                label="Add Great Aunt/Great Uncle"
                side={sideLabel}
                types={["Great Aunt", "Great Uncle"]}
                parentIds={{
                  motherPrId: grandmotherMother["patientAndRelationsId"],
                  fatherPrId: grandmotherFather["patientAndRelationsId"],
                }} />
              {
                grandmother["fullSiblings"]?.length > 0 &&
                grandmother["fullSiblings"].map((sib) => {
                  return (
                    <RelationCard
                      key={sib.reactId}
                      reactKey={sib.reactId}
                      entity={sib}
                      multiBirthSelection={[...grandmother["fullSiblings"], grandmother]}
                      isMobile={isMobile}
                    />
                  )
                })
              }
              <Typography
                variant="h5"
                textAlign={"left"}
                mt={5}
                className={"blue-label"}>
                Your {sideLabel.toLowerCase()} grandmother's parents
              </Typography>
              <RelationCard
                key={grandmotherMother.reactId}
                reactKey={grandmotherMother.reactId}
                entity={grandmotherMother}
                isMobile={isMobile} />
              <RelationCard
                key={grandmotherFather.reactId}
                reactKey={grandmotherFather.reactId}
                entity={grandmotherFather}
                isMobile={isMobile} />
            </>
          }
          {
            <>
              <Typography
                variant="h5"
                textAlign={"left"} mt={5}
                className={"blue-label"}>
                Your {sideLabel.toLowerCase()} grandfather
              </Typography>
              <RelationCard
                key={grandfather.reactId}
                reactKey={grandfather.reactId}
                entity={grandfather}
                multiBirthSelection={grandfather["fullSiblings"]}
                isMobile={isMobile} />
              <Grid item xs={11} lg={8} ml="auto" mr="auto">
                <Typography
                  variant="h5"
                  textAlign={"left"} mt={5}
                  className={"blue-label"}>
                  {grandfather["fullSiblings"]?.length >= 1
                    ? `Your ${sideLabel.toLowerCase()} grandfather's ${grandfather["fullSiblings"]?.length === 1 ? "sibling" : "siblings"}`
                    : `Add your ${sideLabel.toLowerCase()} grandfather's siblings`
                  }
                </Typography>
              </Grid>
              <AddRelationButton
                label="Add Great Aunt/Great Uncle"
                side={sideLabel}
                types={["Great Aunt", "Great Uncle"]}
                parentIds={{
                  motherPrId: grandfatherMother["patientAndRelationsId"],
                  fatherPrId: grandfatherFather["patientAndRelationsId"],
                }} />
              {
                grandfather["fullSiblings"]?.length > 0 &&
                grandfather["fullSiblings"].map((sib) => {
                  return (
                    <RelationCard
                      key={sib.reactId}
                      reactKey={sib.reactId}
                      entity={sib}
                      multiBirthSelection={[...grandfather["fullSiblings"], grandfather]}
                      isMobile={isMobile}
                    />
                  )
                })
              }
            </>
          }
        </>
      }
    </>
  )
}

export default RenderParental;