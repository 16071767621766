import {createContext, useState} from "react";
import {randomId} from "../../../Helpers/utils";
import {useDispatch} from "react-redux";
import {relationsManifestationDelete} from "../../../Redux/relations/relationSlice";

export const RelationAncestry = createContext({});

export const AncestryProvider = ({children}) => {
  const [ancestry, setAncestry] = useState([]);
  const dispatch = useDispatch();

  // this function takes in the reactId of the manifestation, and the reactId of the relation
  // it then dispatches the redux action to delete the manifestation from the relation
  const deleteAncestry = (id, relationReactId) => {
    const {prmId, existedInDb} = ancestry.filter((mani) => mani["reactId"] === id)[0];
    setAncestry(ancestry.filter((ancestry) => ancestry["reactId"] !== id));
    if (prmId) {
      dispatch(relationsManifestationDelete(relationReactId, prmId, id, existedInDb));
    }
    if (!existedInDb) {
      dispatch(relationsManifestationDelete(relationReactId, prmId, id, existedInDb));
    }
  }

  const addAncestry = (patientRelationId) => {
    setAncestry((ancestry) => [
      ...ancestry,
      {
        patientRelationId,
        manifestationId: "",
        reactId: randomId() + randomId(),
        quantity: 1,
        existedInDb: false,
      }]);
  }

  return (
    <RelationAncestry.Provider value={{
      ancestry,
      setAncestry,
      addAncestry,
      deleteAncestry
    }}>
      {children}
    </RelationAncestry.Provider>
  )
}