import React from "react";
import {relationsAndRelations, relationChildrenIDs,} from "../Helpers/relationsMap";


const DualCounter = (props) => {
  const {relationId, side, setRelatives, disableCount, parentIds, theirId, parentKey} = props;
  let exclude = false;

  const myKey = parentKey ? parentKey + '-' + relationId : relationId

  const checkIfParents = (relationId) => {
    if (
      relationId === "2-8"
      || relationId === "2-9"
      || relationId === "3-8"
      || relationId === "3-9"
      || relationId === "2"
      || relationId === "3") {
      exclude = true;
      return 1;
    }
    return 0;
  }

  const [numRels, setNumRels] = React.useState(checkIfParents(relationId));

  const handleRelativeCount = (e) => {
    if (e.target.name === "subtract-relative") {
      if (numRels > 0) {
        setNumRels(numRels - 1);
      }
    }
    if (e.target.name === "add-relative") {
      setNumRels(numRels + 1);
    }
  }

  React.useEffect(() => {
    setRelatives(prevState => ({
      ...prevState,
      [myKey]: {
        side: side ? side : null,
        relationId: exclude ? parseInt(relationId.split("-")[1]) : relationId,
        numToAdd: numRels > 0 ? numRels : 0,
        exclude: exclude,
        parentKey: parentKey,
        myKey: myKey,//without index
        ...parentIds,
        ...theirId,
      }
    }))
  }, [numRels]);

  const showAddChildren = !!relationChildrenIDs[relationId]

  const addChildren = !showAddChildren
    ? ""
    : [...Array(numRels)].map((number, index) =>
      <div key={index} className="addChildrenBox">
        <label className="blue-label">{relationsAndRelations[relationId].label} {index + 1}'s Children</label>
        <DualCounter
          relationId={relationChildrenIDs[relationId].son}
          side={side}
          setRelatives={setRelatives}
          parentKey={myKey + "_" + index} />
        <DualCounter
          relationId={relationChildrenIDs[relationId].daughter}
          side={side}
          setRelatives={setRelatives}
          parentKey={myKey + "_" + index} />
      </div>);

  return (
    <>
      <div className={"family-tree-counter"}>
        <span className="fixed-width-span">{relationsAndRelations[relationId].counterLabel}</span>
        <div className={"counter-holder-left"}>
          <button className={`minus-button ${(numRels === 0 || disableCount) ? "minus-disabled" : ""}`}
                  name="subtract-relative"
                  onClick={(e) => {
                    e.preventDefault();
                    if (numRels === 0 || !disableCount) {
                      handleRelativeCount(e)
                    }
                  }}>
            -
          </button>
          <div className={"count-holder"}>{!exclude ? numRels : 1}</div>
          <button className={`addition-button ${(disableCount) ? "addition-disabled" : ""}`}
                  name="add-relative"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!disableCount) {
                      handleRelativeCount(e)
                    }
                  }}>
            +
          </button>
        </div>
      </div>
      {showAddChildren &&
        <div>
          {addChildren}
        </div>
      }
    </>
  );
}

export default DualCounter;