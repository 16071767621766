import React from "react";
import {GeneProvider} from "./TreeList_GeneContext";
import {RelationGeneForm} from "../FamilyTreeComponents/treeList_GeneForm";

export const GeneHistory = (props) => {
  const {relation, relationReactId, patientRelationId, side, gender, existingKgmMani, isDeceased} = props;
  return (
    <GeneProvider>
      <RelationGeneForm relation={relation}
                        relationReactId={relationReactId}
                        patientRelationId={patientRelationId}
                        side={side}
                        gender={gender}
                        existingKgmMani={existingKgmMani}
                        isDeceased={isDeceased} />
    </GeneProvider>
  );
}