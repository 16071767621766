import React from "react";
import {TreeListProvider} from "./Context/TreeList_BuildListContext";
import TreeList_BuildList from "./TreeList_BuildList";

const TreeList_BuildListWrapper = () => {
  return (
    <TreeListProvider>
      <TreeList_BuildList />
    </TreeListProvider>
  )
}

export default TreeList_BuildListWrapper;