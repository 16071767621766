// noinspection DuplicatedCode

import React, {useContext} from "react";
import {Helmet} from "react-helmet";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";
import {CancerHist} from "./Context/90_Cancer";
import CancerHistoryRow from "./QuestionnaireComponents/92_CancerHistoryRow";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";

import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useModularReduxData} from "../../Hooks/useModularFormDataHandler";
import useReduxFormData from "../../Hooks/useReduxFormData";
import useReduxModularFormData from "../../Hooks/useReduxModularFormData";
import {useValidateToken} from "../../Hooks/useValidateToken";

import {Button, Grid, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const CancerHistoryForm = () => {
  useValidateToken(true, "health");
  const [, , , , manifestationsState, addManifestations] = useReduxFormData();
  const {
    ids: manifestationIds,
    entities: manifestationsEntities,
    manifestationsDbPostLoading,
    manifestationsDirty,
  } = manifestationsState

  const [dispatch, multiManifestationsState, addModularManifestations, , usersGender] = useReduxModularFormData();
  const {
    ids: reactIds,
    entities: modularEntities,
    multiManiFetchLoading,
    multiManiRelationId,
    multiManiDirty,
    multiManiWasFetched,
    multiManiDbPostLoading,
  } = multiManifestationsState;

  const cancerHist = useContext(CancerHist);
  const {cancers, setCancers, addCancerHist} = cancerHist;

  const {handleCancerTypeChange, handleCancerColDataChange, handleCancerResultsChange} = useModularReduxData();
  const {handleRadioChanged} = useReduxDataHandler();
  const {handleFormNavigation} = useFormNavigation();

  const [scrollCancerHist, setScrollCancerHist] = React.useState(0);

  let arrayOfCancers = [];

  React.useEffect(() => {
    if (reactIds.length > 0) {
      reactIds.forEach((id) => {
        let toCont = false;
        if (modularEntities[id].manifestationLookupCategory !== 1749) {
          toCont = true;
        }
        if (!toCont) {
          arrayOfCancers.push(modularEntities[id]);
        }
      })
      setCancers(arrayOfCancers)
    }
  }, [multiManiFetchLoading])

  React.useLayoutEffect(() => {
    if (cancers.length > 1) {
      document
        .getElementById(`cancer-${cancers.at(-1).reactId}`)
        ?.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
    }
  }, [scrollCancerHist]);

  const validateInputs = () => {return true;}
  return (
    (multiManiFetchLoading)
      ? undefined
      : (<>
          <Helmet>
            <title>Cancer History</title>
          </Helmet>
          <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
            <Grid item>
              <Typography textAlign={"center"} variant="h2" mt={5}>
                Let's talk about <b className="blue-emphasis">cancer</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form className={"questionnaire-content"}
                    onSubmit={async (e) => {
                      if (validateInputs()) {
                        if (multiManiDirty) {
                          e.preventDefault();
                          await addModularManifestations(Object.values(modularEntities).filter((m) => (m.edited === true)))
                            .unwrap()
                            .then(() => {
                              if (manifestationsDirty) {
                                addManifestations(Object.values(manifestationsEntities).filter((m) => (m.edited === true))).unwrap();
                              }
                            })
                            .then(() => dispatch(fetchManifestations(multiManiRelationId)))
                            .then(() => handleFormNavigation(e))
                          return;
                        }
                        if (manifestationsDirty) {
                          e.preventDefault();
                          await addManifestations(Object.values(manifestationsEntities).filter((m) => (m.edited === true)))
                            .unwrap()
                            .then(() => {
                              if (multiManiDirty) {
                                addModularManifestations(Object.values(modularEntities).filter((m) => (m.edited === true))).unwrap();
                              }
                            })
                            .then(() => dispatch(fetchManifestations(multiManiRelationId)))
                            .then(() => handleFormNavigation(e))
                          return;
                        }
                        e.preventDefault();
                        handleFormNavigation(e);
                      }
                    }}>
                <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                      className="questionnaire-inner-content">
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="hadCancer">Do you have, or have you ever had any cancer?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             aria-labelledby={"yes-cancer"}
                             value={4524}
                             name={"hadCancer"}
                             opp-manifestation={4525}
                             defaultChecked={manifestationIds.includes(4524) || manifestationIds.includes("4524")}
                             onChange={(e) => {
                               handleRadioChanged(e, multiManiRelationId)
                             }} />
                      <label id={"yes-cancer"}>&nbsp;Yes&nbsp;</label>
                      <input type="radio"
                             aria-labelledby={"no-cancer"}
                             value={4525}
                             name={"hadCancer"}
                             opp-manifestation={4524}
                             defaultChecked={manifestationIds.includes(4525) || manifestationIds.includes("4525")}
                             onChange={(e) => {
                               handleRadioChanged(e, multiManiRelationId)
                             }}
                      /><label id={"no-cancer"}>&nbsp;No&nbsp;</label>
                    </Grid>
                  </Grid>
                  {(manifestationIds.includes(4524) || manifestationIds.includes("4524")) &&
                    <Typography variant={"subtitle1"} textAlign={"center"} ml={"2rem"} mr={"2rem"}
                                className="extra-label-orange">
                      Only add location where your cancer began, not where it metastasized (spread in the body).
                    </Typography>
                  }
                  <Grid container item justifyContent={"center"} xs={12} sm={11} md={8}>
                    {
                      ((!multiManiFetchLoading && multiManiWasFetched && cancers.length > 0) === true) ? cancers.map((row) => {
                          return (
                            modularEntities[row.reactId] !== undefined &&
                            <CancerHistoryRow key={row.reactId}
                                              reactId={row.reactId}
                                              gender={usersGender}
                                              existingCancer={modularEntities[row.reactId]}
                                              typeChangeHandler={handleCancerTypeChange}
                                              resultChangeHandler={handleCancerResultsChange}
                                              colChangeHandler={handleCancerColDataChange}
                            />)
                        })
                        : undefined
                    }
                  </Grid>
                  {(manifestationIds.includes(4524) || manifestationIds.includes("4524")) &&
                    <Grid container item xs={12} justifyContent={"center"}>
                      <Button variant={"personal-add"}
                              onClick={(e) => {
                                e.preventDefault();
                                addCancerHist();
                                setScrollCancerHist(scrollCancerHist + 1);
                              }}><AddCircleOutlineIcon />&nbsp;Add {cancers.length > 0 ? "another" : "a"} cancer
                      </Button>
                    </Grid>
                  }
                  <BackContinueButtons loading={multiManiDbPostLoading || manifestationsDbPostLoading} />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </>
      )
  )
};
