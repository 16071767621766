// noinspection JSUnresolvedVariable

import {createSlice, createAsyncThunk, createEntityAdapter} from "@reduxjs/toolkit";
import {validateToken} from "../users/userSlice";
import axios from "axios";
import store from "../store/store";

let GLOBAL = require("../../Helpers/globals");
const env = typeof window === 'undefined' ? process.env : window._env_;

const relationsAdapter = createEntityAdapter({
  selectId: relation => relation.reactId,
});

const initialState = relationsAdapter.getInitialState({
  relationsUserId: null,
  selfRelationId: null,
  relationsNewestMultiBirthId: 1,

  selfRelations: null,
  maternalRelations: null,
  paternalRelations: null,

  motherPrId: null,

  fatherPrId: null,

  momGrandmaId: null,
  momGrandpaId: null,

  dadGrandmaId: null,
  dadGrandpaId: null,

  momGrandmaMomId: null,
  momGrandmaDadId: null,
  momGrandpaMomId: null,
  momGrandpaDadId: null,

  dadGrandmaMomId: null,
  dadGrandmaDadId: null,
  dadGrandpaMomId: null,
  dadGrandpaDadId: null,

  motherRelation: null,
  fatherRelation: null,

  relationsDataError: null,
  relationsIsLoading: false,
  relationsWereFetched: false,
  relationsStatus: "idle",
  relationsAxiosConfig: null,
  relationsDbPosting: false,

  relationsCardIsExpanded: false,
  relationsCardExpandedCount: 0,

  relationsFilteredBy: "",
});

export const fetchRelations = createAsyncThunk("relations/fetchRelations", async () => {
  const idForFetch = store.getState().users.usersPatientId;
  const response = await
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/relations/${idForFetch}`,
        store.getState().relations.relationsAxiosConfig)
      .catch((err) => {throw err});
  return response.data;
});

export const addRelationsToDb =
  createAsyncThunk("relations/addRelations", async (relationsArr) => {
    const axiosConfig = store.getState().relations.relationsAxiosConfig;
    const response = await
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/relations/add`,
          relationsArr, axiosConfig)
        .catch((err) => {throw err});
    return response.data;
  });

export const editRelationsInDb =
  createAsyncThunk("relations/editRelations", async (relationsArr) => {
    const axiosConfig = store.getState().relations.relationsAxiosConfig;
    const response = await
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/relations/edit`,
          relationsArr, axiosConfig)
        .catch((err) => {throw err});
    return response.data;
  });

const relationsSlice = createSlice({
  name: "relations",
  initialState,
  reducers: {
    editRelation: {
      reducer(state, action) {
        const {relationObj, reactId} = action.payload;
        if (state.entities[reactId]) {
          relationsAdapter.updateOne(state, {
            id: reactId, changes: {
              ...state.entities[reactId],
              ...relationObj,
            }
          });
        }
      },
      prepare(relationObj, reactId) {
        return {
          payload: {
            relationObj,
            reactId,
          },
        };
      },
    },
    markRelationDeleted: {
      reducer(state, action) {
        const {reactId} = action.payload;
        if (state.ids.includes(reactId) && state.entities[reactId].patientAndRelationsId) {
          relationsAdapter.updateOne(state, {
            id: reactId,
            changes: {
              markForDeletion: true,
            }
          });
        }
      },
      prepare(reactId) {
        return {
          payload: {
            reactId,
          },
        };
      }
    },
    relationsManifestationDelete: {
      reducer(state, action) {
        const {relationReactId, prmId, maniReactId, existedInDb} = action.payload;
        if (state.ids.includes(relationReactId)) {
          state.entities[relationReactId].relationManis.forEach((mani, index) => {
            if (prmId && mani.prmId === prmId) {
              state.entities[relationReactId].relationManis[index]["markForDeletion"] = true;
              return;
            }
            if ((!prmId && !existedInDb) && mani.reactId === maniReactId) {
              state.entities[relationReactId].relationManis.splice(index, 1);
            }
          });
        }
      },
      prepare(relationReactId, prmId, maniReactId, existedInDb = true) {
        return {
          payload: {
            relationReactId,
            prmId,
            maniReactId,
            existedInDb,
          },
        };
      },
    },
    relationsMultiManiAddEdit: {
      reducer(state, action) {
        const {relationReactId, maniObj, existedInDb} = action.payload;
        let existsInRedux = false;

        state.entities[relationReactId].relationManis.forEach((mani) => {
          if (mani.reactId === maniObj.reactId) {
            existsInRedux = true;
          }
        });

        if (existedInDb || existsInRedux) {
          state.entities[relationReactId]["edited"] = true;
          state.entities[relationReactId].relationManis.forEach((mani, index) => {
            if (mani.reactId === maniObj.reactId) {
              state.entities[relationReactId].relationManis[index] = maniObj;
            }
          });
        }

        if (!existedInDb && !existsInRedux) {
          state.entities[relationReactId]["edited"] = true;
          state.entities[relationReactId].relationManis.push(maniObj);
        }

      },
      prepare(relationReactId, maniObj, manifestationId) {
        const existedInDb = !!maniObj.existedInDb; // convert undefined to false cuz javascript 🤷🏼
        manifestationId = parseInt(manifestationId);
        return {
          payload: {
            relationReactId,
            maniObj: {...maniObj, manifestationId, markForDeletion: false},
            existedInDb,
          },
        }
      }
    },
    relationsMultiManiEdit: {
      reducer(state, action) {
        const {
          relationReactId,
          manifestationReactId,
          manifestationOptionId,
          manifestationOptionValueId
        } = action.payload;
        let maniExistsHasOptVals = false;
        let manifestationIndex = 0;

        if (state.ids.includes(relationReactId) && (state.entities[relationReactId].relationManis && state.entities[relationReactId].relationManis.length > 0)) {
          manifestationIndex = state.entities[relationReactId].relationManis.findIndex((mani) => {
            return mani.reactId === manifestationReactId;
          });
          if (state.entities[relationReactId].relationManis[manifestationIndex].prmOptVals
            && state.entities[relationReactId].relationManis[manifestationIndex].prmOptVals.length > 0) {
            maniExistsHasOptVals = true;
          }
        }

        if (maniExistsHasOptVals) {
          let newOpt = false;
          let existingOptIndex = undefined;

          existingOptIndex = state.entities[relationReactId].relationManis[manifestationIndex].prmOptVals.findIndex((optVal) => optVal["manifestationOptionId"] === manifestationOptionId);

          if (existingOptIndex === -1) {
            newOpt = true;
          }

          if (existingOptIndex !== -1) {
            state.entities[relationReactId].relationManis[manifestationIndex].prmOptVals[existingOptIndex]["manifestationOptionValueId"] = manifestationOptionValueId;
            return;
          }

          if (newOpt) {
            state.entities[relationReactId].relationManis[manifestationIndex].prmOptVals.push({
              manifestationOptionId,
              manifestationOptionValueId,
              markForDeletion: false,
            });
            return;
          }
          return;
        }
        state.entities[relationReactId]
          .relationManis[manifestationIndex]
          .prmOptVals =
          [{
            manifestationOptionId,
            manifestationOptionValueId,
            markForDeletion: false,
          }];
      },
      prepare(relationReactId, manifestationReactId, optId, optValId) {
        return {
          payload: {
            relationReactId,
            manifestationReactId,
            manifestationOptionId: parseInt(optId),
            manifestationOptionValueId: parseInt(optValId),
          },
        }
      },
    },
    relationsMultiManiColData: {
      reducer(state, action) {
        const {relationReactId, manifestationReactId, colName, colValue} = action.payload;
        const indexOfMani = state.entities[relationReactId].relationManis.findIndex(item => item.reactId === manifestationReactId);
        if (state.ids.includes(relationReactId) && (state.entities[relationReactId].relationManis && state.entities[relationReactId].relationManis.length > 0)) {
          state.entities[relationReactId].relationManis[indexOfMani] = {
            ...state.entities[relationReactId].relationManis[indexOfMani],
            ...{[colName]: colValue}
          }
        }
      },
      prepare(relationReactId, manifestationReactId, colName, colValue) {
        return {
          payload: {
            relationReactId,
            manifestationReactId,
            colName,
            colValue
          }
        }
      }
    },
    relationsGeneInitialAdd: {
      reducer(state, action) {
        const {patientRelationId, relationReactId, manifestationReactId, geneOptValReactId} = action.payload;
        if (state.ids.includes(relationReactId)) {
          state.entities[relationReactId].relationManis.push({
            reactId: manifestationReactId,
            patientRelationId,
            manifestationId: 161,
            quantity: 1,
            prmOptVals: [
              {
                reactId: geneOptValReactId,
              }
            ],
          })
        }
      },
      prepare(patientRelationId, relationReactId, manifestationReactId, geneOptValReactId) {
        return {
          payload: {
            patientRelationId,
            relationReactId,
            manifestationReactId,
            geneOptValReactId
          }
        }
      }
    },
    relationsGeneticsAddEdit: {
      reducer(state, action) {
        const {relationReactId, manifestationReactId, geneOptValReactId, optId, optValId} = action.payload;

        if (state.ids.includes(relationReactId)) {
          const kgmManiIndex = state.entities[relationReactId].relationManis.findIndex(mani => mani.reactId === manifestationReactId)

          if (kgmManiIndex !== -1) {
            const geneOptValIndex = state.entities[relationReactId].relationManis[kgmManiIndex].prmOptVals.findIndex(optVal => optVal.reactId === geneOptValReactId)
            if (geneOptValIndex !== -1) {
              state.entities[relationReactId]
                .relationManis[kgmManiIndex]
                .prmOptVals[geneOptValIndex] = {
                ...state.entities[relationReactId].relationManis[kgmManiIndex].prmOptVals[geneOptValIndex],
                manifestationOptionId: optId,
                manifestationOptionValueId: optValId
              }
            } else {
              state.entities[relationReactId].relationManis[kgmManiIndex]
                .prmOptVals.push({
                reactId: geneOptValReactId,
                manifestationOptionId: optId,
                manifestationOptionValueId: optValId
              })
            }
          }
        }
      },
      prepare(relationReactId, manifestationReactId, geneOptValReactId, optId, optValId) {
        const isGene = parseInt(optId) === 38;

        return {
          payload: {
            relationReactId,
            manifestationReactId,
            geneOptValReactId,
            isGene,
            optId,
            optValId,
          }
        }
      },
    },
    relationsGeneDelete: {
      reducer(state, action) {
        const {relationReactId, kgmManiReactId: manifestationReactId, geneOptValReactId} = action.payload;
        if (state.ids.includes(relationReactId)) {
          const indexOfMani = state.entities[relationReactId].relationManis.findIndex(item => item.reactId === manifestationReactId);
          if (indexOfMani !== -1) {
            const indexOfOptVal = state.entities[relationReactId].relationManis[indexOfMani].prmOptVals.findIndex(item => item.reactId === geneOptValReactId);
            state.entities[relationReactId].relationManis[indexOfMani].prmOptVals[indexOfOptVal].prmovId
              ? state.entities[relationReactId].relationManis[indexOfMani].prmOptVals[indexOfOptVal].markForDeletion = true
              : state.entities[relationReactId].relationManis[indexOfMani].prmOptVals.splice(indexOfOptVal, 1);
          }
        }
      },
      prepare(relationReactId, kgmManiReactId, geneOptValReactId) {
        return {
          payload: {
            relationReactId,
            kgmManiReactId,
            geneOptValReactId,
          },
        }
      },
    },
    disableContinueButton: {
      reducer(state, action) {
        if (action.payload) {
          state.relationsCardExpandedCount++;
          state.relationsCardIsExpanded = true;
        }
        if (!action.payload) {
          state.relationsCardExpandedCount--;
          state.relationsCardExpandedCount === 0
            ? state.relationsCardIsExpanded = false
            : state.relationsCardIsExpanded = true
        }
      },
      prepare(disable) {
        return {
          payload: disable,
        }
      }
    },
    resetContinue: {
      reducer(state) {
        state.relationsCardExpandedCount = 0;
        state.relationsCardIsExpanded = false;
      }
    },
    filterRelations: {
      reducer(state, action) {
        state.relationsFilteredBy = action.payload;
      },
      prepare(filterBy) {
        return {
          payload: filterBy,
        }
      }
    },
    linkMultiBirth: {
      reducer(state, action) {
        // take in an object with the list of relations reactIds and the multibirth type
        // for each relation in the list, set the multibirth type
        const {hasRemoval, hasAddition, multiBirthType, multiBirthId, addToBirth, removeFromBirth} = action.payload;
        if (hasRemoval) {
          removeFromBirth.forEach(reactId => {
            state.entities[reactId].multiBirthType = null;
            state.entities[reactId].multiBirthId = null;
            state.entities[reactId].edited = true;
          })
        }
        if (hasAddition) {
          addToBirth.forEach(reactId => {
            const noChanges = (state.entities[reactId].multiBirthId === multiBirthId && state.entities[reactId].multiBirthType === multiBirthType);
            if (noChanges) {
              return;
            }
            state.entities[reactId].multiBirthType = multiBirthType;
            state.entities[reactId].multiBirthId = multiBirthId;
            state.entities[reactId].edited = true;
          })
        }
      },
      prepare(multiBirthState) {

        const hasRemoval = multiBirthState.removeMulti.length > 0;
        const hasAddition = multiBirthState.multiSiblings.length > 0;

        return {
          payload: {
            hasRemoval,
            hasAddition,
            addToBirth: hasAddition ? multiBirthState.multiSiblings : null,
            removeFromBirth: hasRemoval ? multiBirthState.removeMulti : null,
            multiBirthType: multiBirthState.type,
            multiBirthId: multiBirthState.multiBirthId,
          }
        }
      }
      ,
    },
  },
  extraReducers:
    builder => {
      builder
        .addCase(validateToken.fulfilled, (state, action) => {
          state.relationsUserId = action.payload["patientId"];
          state.selfRelationId = action.payload["selfRelationId"];
          if (!state.relationsAxiosConfig) {
            state.relationsAxiosConfig = {headers: {Authorization: `Bearer ${action.payload.token}`}};
          }
        })
        .addCase(fetchRelations.pending, (state) => {
          state.relationsIsLoading = true;
          state.relationsWereFetched = false;
          state.relationsStatus = "fetching relations";
        })
        .addCase(fetchRelations.fulfilled, (state, action) => {
          action.payload.relationsList.forEach((relation, index) => {
            relation.markForDeletion = false;
          })

          const sorted = action.payload.sortedRelations;

          state.selfRelations = sorted.self;
          state.maternalRelations = sorted.maternal;
          state.paternalRelations = sorted.paternal;

          state.motherRelation = sorted.maternal;
          state.fatherRelation = sorted.paternal;

          state.motherPrId = sorted.maternal.patientAndRelationsId;

          state.momGrandmaId = sorted.maternal.ancestors[0].patientAndRelationsId;

          state.momGrandmaMomId = sorted.maternal.ancestors[0].ancestors[0].patientAndRelationsId;
          state.momGrandmaDadId = sorted.maternal.ancestors[0].ancestors[1].patientAndRelationsId;

          state.momGrandpaId = sorted.maternal.ancestors[1].patientAndRelationsId;

          state.momGrandpaMomId = sorted.maternal.ancestors[1].ancestors[0].patientAndRelationsId;
          state.momGrandpaDadId = sorted.maternal.ancestors[1].ancestors[1].patientAndRelationsId;

          state.fatherPrId = sorted.paternal.patientAndRelationsId;

          state.dadGrandmaId = sorted.paternal.ancestors[0].patientAndRelationsId;

          state.dadGrandmaMomId = sorted.paternal.ancestors[0].ancestors[0].patientAndRelationsId;
          state.dadGrandmaDadId = sorted.paternal.ancestors[0].ancestors[1].patientAndRelationsId;

          state.dadGrandpaId = sorted.paternal.ancestors[1].patientAndRelationsId;

          state.dadGrandpaMomId = sorted.paternal.ancestors[1].ancestors[0].patientAndRelationsId;
          state.dadGrandpaDadId = sorted.paternal.ancestors[1].ancestors[1].patientAndRelationsId;

          action.payload.relationsList.forEach((relation, index) => {
            if (relation.multiBirthId !== null) {
              if (relation.multiBirthId > state.relationsNewestMultiBirthId) {
                state.relationsNewestMultiBirthId = relation.multiBirthId;
              }
            }
          });
          relationsAdapter.setAll(state, action.payload.relationsList);

          state.relationsIsLoading = false;
          state.relationsStatus = "success";
          state.relationsWereFetched = true;
          state.relationsCardIsExpanded = false;
          state.relationsCardExpandedCount = 0;
        })
        .addCase(fetchRelations.rejected, (state) => {
          state.relationsIsLoading = false;
          state.relationsWereFetched = false;
          state.relationsStatus = "failed";
          state.relationsDataError = "error fetching relations";
        })
        .addCase(addRelationsToDb.pending, (state) => {
          state.relationsStatus = "adding relations";
          state.relationsDbPosting = true;
        })
        .addCase(addRelationsToDb.fulfilled, (state) => {
          state.relationsStatus = "success";
          state.relationsDbPosting = false;
        })
        .addCase(addRelationsToDb.rejected, (state) => {
          state.relationsStatus = "failed";
          state.relationsDbPosting = false;
          state.relationsDataError = "error adding relations";
        })
        .addCase(editRelationsInDb.pending, (state) => {
          state.relationsStatus = "updating relations";
          state.relationsDbPosting = true;
        })
        .addCase(editRelationsInDb.fulfilled, (state, action) => {
          state.relationsStatus = "success";
          state.relationsDbPosting = false;
        })
        .addCase(editRelationsInDb.rejected, (state) => {
          state.relationsStatus = "failed";
          state.relationsDbPosting = false;
          state.relationsDataError = "error updating relations";
        })
    },
})

export const {
  editRelation,
  relationsManifestationDelete,
  relationsMultiManiAddEdit,
  relationsMultiManiColData,
  relationsGeneInitialAdd,
  relationsGeneticsAddEdit,
  relationsMultiManiEdit,
  relationsGeneDelete,
  disableContinueButton,
  resetContinue,
  filterRelations,
  linkMultiBirth,
} = relationsSlice.actions;

export default relationsSlice.reducer;