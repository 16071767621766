// noinspection JSUnresolvedVariable

import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {randomId} from "../../Helpers/utils";
import {validateToken} from "../users/userSlice";
import {fetchManifestations} from "./manifestationSlice";
import {apiSlice} from "../api/apiSlice";

const multiManifestationsAdapter = createEntityAdapter({
  selectId: (multiManifestation) => {
    return multiManifestation.reactId;
  }
});

const initialState = multiManifestationsAdapter.getInitialState({
  multiManiPatientId: null,
  multiManiFetchLoading: false,
  multiManiButtonDisabled: false,
  multiManiRelationId: null,
  multiManiStatus: "idle",
  multiManiDirty: false,
  multiManiIsModularForm: false,
  multiManiWasFetched: false,
  multiManiDbPostLoading: false,
  multiManiNoneExist: false,
  multiManiAxiosConfig: null,
});

const multiManifestationSlice = createSlice({
  name: "multiManifestations",
  initialState,
  reducers: {
    manifestationMultiManiInitialAddWithOpts: {
      reducer(state, action) {
        const {reactId, patientRelationId} = action.payload;
        multiManifestationsAdapter.addOne(state,
          {
            reactId: reactId,
            patientRelationId,
            quantity: 1,
            edited: true,
            prmOptVals: [],
          });
      },
      prepare(patientRelationId) {
        return {
          payload: {
            reactId: randomId() + randomId(),
            patientRelationId: patientRelationId,
          },
        }
      }
    },
    manifestationsMultiManiDelete: {
      reducer(state, action) {
        const {reactId} = action.payload;
        if (state.entities[reactId].prmId) {
          state.multiManiDirty = true;
          multiManifestationsAdapter.updateOne(state, {
            id: reactId,
            changes: {
              markForDeletion: true,
              edited: true,
            }
          });
        }
        if (!state.entities[reactId].prmId) {
          multiManifestationsAdapter.removeOne(state, reactId);
        }
      },
      prepare(reactId) {
        return {
          payload: {
            reactId,
          },
        }
      },
    },
    manifestationMultiManiEdit: {
      reducer(state, action) {
        const {reactId, selectedMani, manifestationOptionId, manifestationOptionValueId} = action.payload;
        let exists = false;

        if (state.ids.includes(reactId) && ((state.entities[reactId].prmOptVals && state.entities[reactId].prmOptVals.length > 0))) {
          exists = true;
        }

        if (exists) {
          let newOpt = false;
          let existingOptIndex = undefined;
          const manifestationId = selectedMani ? selectedMani : state.entities[reactId].manifestationId;
          state.multiManiDirty = true;

          existingOptIndex = state.entities[reactId].prmOptVals.findIndex((optVal) => optVal["manifestationOptionId"] === manifestationOptionId)

          if (existingOptIndex === -1) {
            newOpt = true;
          }
          if (existingOptIndex !== -1) {
            state.multiManiDirty = true;
            state.entities[reactId].prmOptVals[existingOptIndex].manifestationOptionValueId = manifestationOptionValueId;
            multiManifestationsAdapter.updateOne(state, {
              id: reactId,
              changes: {
                manifestationId,
                ...state.entities[reactId],
                edited: true,
              }
            });
            return;
          }

          if (newOpt) {
            const manifestationId = selectedMani ? selectedMani : state.entities[reactId].manifestationId;
            state.multiManiDirty = true;

            let onlyHasOneOpt = false;

            if (state.entities[reactId].manifestationLookupCategory === 1970) {
              onlyHasOneOpt = true;
            }

            if (onlyHasOneOpt) {
              multiManifestationsAdapter.updateOne(state, {
                id: reactId,
                changes: {
                  manifestationId,
                  edited: true,
                  prmOptVals: [
                    {
                      ...state.entities[reactId].prmOptVals[0],
                      markForDeletion: true,
                    },
                    {
                      manifestationOptionId,
                      manifestationOptionValueId
                    }
                  ]
                }
              })
              return;
            }

            multiManifestationsAdapter.updateOne(state, {
              id: reactId, changes: {
                manifestationId: manifestationId,
                edited: true,
                prmOptVals: [
                  ...state.entities[reactId].prmOptVals,
                  {
                    manifestationOptionId: manifestationOptionId,
                    manifestationOptionValueId: manifestationOptionValueId,
                  }
                ]
              }
            })
            return;
          }

          if (!newOpt) {
            multiManifestationsAdapter.updateOne(state, {
              id: reactId,
              changes: {
                manifestationId: manifestationId,
                edited: true,
                prmOptVals: [
                  ...state.entities[reactId].prmOptVals[0],
                  {
                    manifestationOptionId,
                    manifestationOptionValueId
                  },
                ]
              }
            })
            return;
          }
          return;
        }

        if (state.ids.includes(reactId) && manifestationOptionId) {
          state.multiManiDirty = true;
          multiManifestationsAdapter.updateOne(state, {
            id: reactId,
            changes: {
              edited: true,
              prmOptVals: [
                {
                  manifestationOptionId,
                  manifestationOptionValueId
                }
              ],
            },
          });
        }
        if (state.ids.includes(reactId) && !manifestationOptionId) {
          state.multiManiDirty = true;
          multiManifestationsAdapter.updateOne(state, {
            id: reactId,
            changes: {
              edited: true,
              manifestationId: selectedMani,
            },
          });
        }
      },
      prepare(selectedMani, reactId, maniOptId, maniOptValId) {
        // reducer will receive the manifestation ID for the biopsy
        // there needs to be a result which will be a PrmOptVal
        // the PrmOptVal contains the ManifestationOption ID and ManifestationOptionValue ID
        // The tracking entity needs to be able to handle changing options for each unique one
        return {
          payload: {
            selectedMani: parseInt(selectedMani),
            reactId: reactId,
            manifestationOptionId: parseInt(maniOptId),
            manifestationOptionValueId: parseInt(maniOptValId),
          },
        }
      },
    },
    manifestationMultiManiInitialAddNoOpts: {
      reducer(state, action) {
        const {reactId, patientRelationId, manifestationId} = action.payload;
        state.multiManiDirty = true;
        multiManifestationsAdapter.addOne(state,
          {
            reactId: reactId,
            manifestationId: manifestationId ? manifestationId : null,
            patientRelationId,
            quantity: 1,
            edited: true,
          });
        state.multiManiWasFetched = true;
      },
      prepare(patientRelationId, manifestationId) {
        return {
          payload: {
            reactId: randomId() + randomId(),
            patientRelationId: patientRelationId,
            manifestationId,
          },
        }
      }
    },
    manifestationsMultiManiColData: {
      reducer(state, action) {
        const {reactId, colName, colValue} = action.payload;

        if (state.ids.includes(reactId)) {
          state.multiManiDirty = true;
          multiManifestationsAdapter.updateOne(state, {
            id: reactId,
            changes: {
              edited: true,
              [colName]: colValue,
            }
          });
        }
      },
      prepare(reactId, colName, colValue) {
        return {
          payload: {
            reactId,
            colName,
            colValue,
          },
        }
      }
    },
    manifestationsMultiManiEditNoOpts: {
      reducer(state, action) {
        const {reactId, selectedMani} = action.payload;
        state.multiManiDirty = true;
        multiManifestationsAdapter.updateOne(state, {
          id: reactId,
          changes: {
            edited: true,
            manifestationId: selectedMani,
          },
        });
      },
      prepare(reactId, maniId) {
        return {
          payload: {
            reactId,
            selectedMani: maniId,
          }
        }
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        validateToken.fulfilled,
        (state, action) => {
          state.multiManiPatientId = action.payload.patientId;
          state.multiManiRelationId = action.payload.selfRelationId;
          if (!state.multiManiAxiosConfig) {
            state.multiManiAxiosConfig = {headers: {Authorization: `Bearer ${action.payload.token}`}};
          }
        })
      .addCase(
        fetchManifestations.pending,
        (state, action) => {
          state.multiManiStatus = "loading";
          state.multiManiWasFetched = false;
          state.multiManiFetchLoading = true;
        }
      )
      .addCase(
        fetchManifestations.fulfilled,
        (state, action) => {
          const manis = action.payload;
          const multiManis = manis.filter((man) => {
            const {manifestationLookupCategory: lookupId, manifestationId: maniId} = man;
            if (
              (lookupId === 1749 &&
                maniId !== 4524 &&
                maniId !== 4525) ||
              (lookupId === 1752 &&
                (maniId !== 161 &&
                  maniId !== 367 &&
                  maniId !== 164)) ||
              (lookupId === 1970 &&
                maniId !== 4522 &&
                maniId !== 4523)) {
              return man;
            }
          });
          multiManifestationsAdapter.setAll(state, multiManis);
          state.multiManiStatus = "succeeded";
          state.multiManiWasFetched = true;
          state.multiManiFetchLoading = false;
        }
      )
      .addMatcher(
        apiSlice.endpoints.updateModularManifestations.matchPending,
        (state) => {
          state.multiManiStatus = "posting";
          state.multiManiWasFetched = false;
          state.multiManiDbPostLoading = true;
        }
      )
      .addMatcher(
        apiSlice.endpoints.updateModularManifestations.matchFulfilled,
        (state) => {
          state.multiManiStatus = "succeeded";
          state.multiManiDbPostLoading = false;
          state.multiManiDirty = false;
        }
      )
  },
});

export const {
  manifestationMultiManiInitialAddWithOpts,
  manifestationMultiManiInitialAddNoOpts,
  manifestationsMultiManiDelete,
  manifestationMultiManiEdit,
  manifestationsMultiManiEditNoOpts,
  manifestationsMultiManiColData,
} = multiManifestationSlice.actions;

export default multiManifestationSlice.reducer;