// noinspection DuplicatedCode

import {Grid, Typography} from "@mui/material";
import React from "react";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {Helmet} from "react-helmet";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {DefaultInputValGetters} from "./Helpers/defaultInputValGetters";
import useReduxFormData from "../../Hooks/useReduxFormData";
import {useValidateToken} from "../../Hooks/useValidateToken";


const LifestyleSmoking = () => {
  useValidateToken(true, "basic");
  const [dispatch, usersState, , , manifestationsState, addManifestations] = useReduxFormData();
  const {
    ids: maniIds,
    manifestationsDirty,
    manifestationsDbPostLoading,
    entities,
    manifestationsFetchLoading
  } = manifestationsState;
  const {usersSelfRelationId} = usersState;
  const {handleFormNavigation} = useFormNavigation();

  const {
    handleInputChange,
    handleOptValInputChange,
    handleRadioChanged,
  } = useReduxDataHandler();

  const {getDefaultNormalFieldValue, checkForMoreValues} = DefaultInputValGetters();

  const validateInputs = () => {return true};

  return (
    manifestationsFetchLoading
      ? undefined
      : (<>
          <Helmet>
            <title>Smoking</title>
          </Helmet>
          <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
            <Grid item xs={8}>
              <Typography textAlign={"center"} variant="h2" mt={5}>&nbsp;</Typography>
            </Grid>
            <Grid item xs={12}>
              <form className={"questionnaire-content"}
                    onSubmit={async (e) => {
                      e.preventDefault()
                      if (validateInputs()) {
                        if (manifestationsDirty) {
                          await addManifestations(Object.values(entities).filter((m) => m.edited === true))
                            .unwrap()
                            .then(() => {dispatch(fetchManifestations(usersSelfRelationId))})
                            .then(() => handleFormNavigation(e))
                        }
                        handleFormNavigation(e)
                      }
                    }}>
                <Grid spacing={2} container justifyContent={"center"} item xs={12} ml={"auto"} mr={"auto"}
                      className="questionnaire-inner-content">
                  <Grid container item flexDirection={"column"} justifyContent={"center"} xs={8}>
                    <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                      <Grid item>
                        <label htmlFor="currSmokerStatus">Are you currently smoking?</label>
                      </Grid>
                      <Grid item>
                        <input type="radio"
                               value={256}
                               name={"currSmokerStatus"}
                               related-manis={"256,257,258,4518"}
                               defaultChecked={(maniIds.includes(256) || maniIds.includes("256"))}
                               onChange={(e) => {
                                 handleInputChange(e, manifestationsState);
                               }} /><span>&nbsp;Yes&nbsp;</span>
                        <input type="radio"
                               value={4518}
                               maniid={256}
                               name={"currSmokerStatus"}
                               related-manis={"256,257,258,4518"}
                               defaultChecked={(maniIds.includes(4518) || maniIds.includes("4518"))}
                               onChange={(e) => {
                                 handleInputChange(e, manifestationsState);
                               }}
                        /><span>&nbsp;No&nbsp;</span>
                      </Grid>
                    </Grid>
                    {
                      (!maniIds.includes(256) && !maniIds.includes("256")) &&
                      <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"} mt={5}>
                        <Grid item>
                          <label htmlFor="pastSmokerStatus">Do you have a history of smoking?</label>
                        </Grid>
                        <Grid item>
                          <input type="radio"
                                 value={257}
                                 related-manis={"256,257,258"}
                                 name={"pastSmokerStatus"}
                                 defaultChecked={(maniIds.includes(257) || maniIds.includes("257"))}
                                 onChange={(e) => {
                                   handleInputChange(e, manifestationsState);
                                 }}
                          /><span>&nbsp;Yes&nbsp;</span>
                          <input type="radio"
                                 value={258}
                                 related-manis={"256,257,258"}
                                 name={"pastSmokerStatus"}
                                 defaultChecked={(maniIds.includes(258) || maniIds.includes("258"))}
                                 onChange={(e) => {
                                   handleInputChange(e, manifestationsState);
                                 }}
                          /><span>&nbsp;No&nbsp;</span>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                  {
                    (maniIds.includes(257) || maniIds.includes("257")) &&
                    <Grid container item justifyContent={"center"} xs={12}>
                      <Grid container item justifyContent={"center"} xs={12}>
                        <label htmlFor="pastSmokerYears">How many years?</label>
                      </Grid>
                      <select className="dropdown-input"
                              name="pastSmokerYears"
                              col-for={257}
                              col-name={"ageOfDiagnosis"}
                              defaultValue={entities[257].ageOfDiagnosis || null}
                              onChange={(e) => handleInputChange(e, manifestationsState, true)}>
                        <option value={null}>Select an option</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={13}>13</option>
                        <option value={14}>14</option>
                        <option value={15}>15</option>
                        <option value={16}>16</option>
                        <option value={17}>17</option>
                        <option value={18}>18</option>
                        <option value={19}>19</option>
                        <option value={20}>20+</option>
                      </select>
                    </Grid>
                  }
                  {
                    (maniIds.includes(256) || maniIds.includes("256")) &&
                    <Grid columnSpacing={2} container item flexDirection={"row"} justifyContent={"space-between"} xs={8}>
                      <Grid container item justifyContent={"center"} flexDirection={"column"} xs={12} sm={6} md={4}>
                        <label htmlFor="smoking-years">How many years have you smoked?</label><br />
                        <select className="dropdown-input"
                                name="smoking-years"
                                col-for={256}
                                col-name={"ageOfDiagnosis"}
                                defaultValue={entities[256].ageOfDiagnosis || null}
                                onChange={(e) => {
                                  handleInputChange(e, manifestationsState, true);
                                }}>
                          <option value={null}>Select an option</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                          <option value={11}>11</option>
                          <option value={12}>12</option>
                          <option value={13}>13</option>
                          <option value={14}>14</option>
                          <option value={15}>15</option>
                          <option value={16}>16</option>
                          <option value={17}>17</option>
                          <option value={18}>18</option>
                          <option value={19}>19</option>
                          <option value={20}>20+</option>
                        </select>
                      </Grid>
                      <Grid container item justifyContent={"center"} flexDirection={"column"} xs={12} sm={6} md={4}>
                        <label htmlFor="smoking-type">What type of tobacco?</label><br />
                        <select className="dropdown-input"
                                name="smoking-type"
                                manioptid={75}
                                opts-for={256}
                                defaultValue={getDefaultNormalFieldValue(entities, 256, 75)}
                                onChange={(e) => {
                                  handleOptValInputChange(e, false, false, manifestationsState);
                                }}>
                          <option value={null}>Select an option</option>
                          <option value={601}>Cigarettes</option>
                          <option value={602}>Traditional Tobacco</option>
                          <option value={603}>Vape</option>
                          <option value={604}>Other</option>
                        </select>
                      </Grid>
                      {
                        checkForMoreValues(entities, 256, 75, 601) &&
                        <Grid container item justifyContent={"center"} flexDirection={"column"} xs={12} sm={6} md={4}>
                          <label htmlFor="smoking-amount">How many cigarettes per day?</label><br />
                          <select className="dropdown-input"
                                  name="smoking-amount"
                                  manioptid={17}
                                  opts-for={256}
                                  defaultValue={getDefaultNormalFieldValue(entities, 256, 17)}
                                  onChange={(e) => {
                                    handleOptValInputChange(e, false, false, manifestationsState);
                                  }}>
                            <option value={null}>Select an option</option>
                            <option value={424}>1</option>
                            <option value={425}>2</option>
                            <option value={426}>3</option>
                            <option value={427}>4</option>
                            <option value={428}>5</option>
                            <option value={429}>6</option>
                            <option value={430}>7</option>
                            <option value={431}>8</option>
                            <option value={432}>9</option>
                            <option value={433}>10</option>
                            <option value={434}>11</option>
                            <option value={435}>12</option>
                            <option value={436}>13</option>
                            <option value={437}>14</option>
                            <option value={438}>15</option>
                            <option value={439}>16</option>
                            <option value={440}>17</option>
                            <option value={441}>18</option>
                            <option value={442}>19</option>
                            <option value={443}>20+</option>
                          </select>
                        </Grid>
                      }
                    </Grid>
                  }
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"} xs={8} mt={5}>
                    <Grid item>
                      <label htmlFor="secondhandSmoke">Have you ever been exposed to secondhand smoke?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={260}
                             opp-manifestation={4460}
                             name={"secondhandSmoke"}
                             defaultChecked={(maniIds.includes(260) || maniIds.includes("260"))}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4460}
                             opp-manifestation={260}
                             name={"secondhandSmoke"}
                             defaultChecked={(maniIds.includes(4460) || maniIds.includes("4460"))}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(260) || maniIds.includes("260")) &&
                    <Grid container item justifyContent={"center"} xs={12}>
                      <Grid container item justifyContent={"center"} xs={12}>
                        <label htmlFor="pastSmokerYears">How many years?</label>
                      </Grid>
                      <select className="dropdown-input"
                              name="pastSmokerYears"
                              col-for={260}
                              col-name={"ageOfDiagnosis"}
                              defaultValue={entities[260].ageOfDiagnosis || null}
                              onChange={(e) => handleInputChange(e, manifestationsState, true)}>
                        <option value={null}>Select an option</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={13}>13</option>
                        <option value={14}>14</option>
                        <option value={15}>15</option>
                        <option value={16}>16</option>
                        <option value={17}>17</option>
                        <option value={18}>18</option>
                        <option value={19}>19</option>
                        <option value={20}>20+</option>
                      </select>
                    </Grid>
                  }
                  <BackContinueButtons loading={manifestationsDbPostLoading} />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </>
      )
  )
}

export default LifestyleSmoking;