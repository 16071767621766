import {createContext, useState} from "react";
import {randomId} from "../../../Helpers/utils";
import {useDispatch} from "react-redux";
import {relationsManifestationDelete} from "../../../Redux/relations/relationSlice";

export const RelationCancer = createContext({});

export const CancerProvider = ({children}) => {
  const [cancer, setCancer] = useState([]);
  const [patientRelationId, setPatientRelationId] = useState(null);
  const dispatch = useDispatch();

  const deleteCancerHist = (id, relationReactId) => {
    const {prmId, existedInDb} = cancer.filter((mani) => mani["reactId"] === id)[0];
    setCancer(cancer.filter((cancer) => cancer["reactId"] !== id));
    if (prmId) {
      dispatch(relationsManifestationDelete(relationReactId, prmId, id, existedInDb));
    }
    if (!existedInDb) {
      dispatch(relationsManifestationDelete(relationReactId, prmId, id, existedInDb));
    }
  }

  const addCancerHist = (patientRelationId) => {
    setCancer((cancer) => [
      ...cancer,
      {
        patientRelationId,
        reactId: randomId() + randomId(),
        quantity: 1,
        existedInDb: false,
        prmOptVals: [],
      }]);
  }

  return (
    <RelationCancer.Provider value={{
      cancer,
      setCancer,
      patientRelationId,
      setPatientRelationId,
      addCancerHist,
      deleteCancerHist
    }}>
      {children}
    </RelationCancer.Provider>)
}