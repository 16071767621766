import {
  Accordion, AccordionDetails, AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import React from "react";
import {getRelationCardLabel} from "../Helpers/relationsMap";
import {useDispatch} from "react-redux";
import {linkMultiBirth} from "../../../Redux/relations/relationSlice";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MultiBirthOptions = (props) => {
  const {reactKey, multiBirthType, multiBirthId, multiBirthSelection, relationsNewestMultiBirthId} = props;
  const dispatch = useDispatch();

  const [multiBirthState, setMultiBirthState] = React.useState(
    {
      multiSiblings: [],
      removeMulti: [],
      type: multiBirthType,
      multiBirthId: multiBirthId,
      isMultiBirth: (multiBirthType !== null),
    }
  );

  const handleMultiBirthChange = (e) => {
    const {checked, value} = e.target;
    if (!checked && multiBirthState.multiSiblings.includes(value)) {
      let currSelection = multiBirthState.multiSiblings;
      let currRemove = multiBirthState.removeMulti;
      currSelection.splice(currSelection.indexOf(value), 1);
      currRemove.push(value);
      setMultiBirthState({
        ...multiBirthState,
        multiSiblings: currSelection,
        removeMulti: currRemove,
      })
    }
    if (checked && !multiBirthState.multiSiblings.includes(value)) {
      let currSelection = multiBirthState.multiSiblings;
      currSelection.push(value);
      setMultiBirthState({
        ...multiBirthState,
        multiSiblings: currSelection,
      })
    }
    document.getElementsByName("sibling-checkbox").forEach((checkbox) => {
      checkbox.setCustomValidity("");
    })
  }

  const handleSelectMultiBirthTypeChange = (e) => {
    multiBirthState.multiSiblings.forEach((sib) => {
      sib.multiBirthType = e.target.value;
    })
  }

  React.useEffect(() => {
    if (multiBirthType && multiBirthSelection && multiBirthSelection.length > 0) {
      let newSelection = [...multiBirthState.multiSiblings, reactKey];
      multiBirthSelection.forEach((rel) => {
        if ((rel.multiBirthId === multiBirthId) &&
          rel.reactId !== reactKey &&
          !newSelection.includes(rel.reactId)) {
          newSelection.push(rel.reactId);
          setMultiBirthState({
            ...multiBirthState,
            multiSiblings: newSelection,
          })
        }
      })
    }
  }, []);

  React.useEffect(() => {
    dispatch(linkMultiBirth(multiBirthState));
  }, [multiBirthState])

  return (
    multiBirthSelection &&
    multiBirthSelection.length >= 1
      ? <>
        <div className="modal-line-break">
          <hr />
          <Grid container>
            <Grid item xs={7}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={multiBirthState.isMultiBirth}
                    onChange={(e) => {
                      setMultiBirthState({
                        ...multiBirthState,
                        isMultiBirth: e.target.checked,
                        type: e.target.checked ? multiBirthState.type : null,
                        multiSiblings: e.target.checked ? [reactKey] : [],
                        removeMulti: e.target.checked ? [] : multiBirthState.multiSiblings,
                        multiBirthId: e.target.checked
                          ? multiBirthId
                            ? multiBirthId
                            : relationsNewestMultiBirthId + 1
                          : null,
                      })
                    }}
                  />}
                label={"Was this relation part of a multibirth (twins, triplets, etc.)?"}
                labelPlacement="start"
              />
            </Grid>
            {
              multiBirthState.multiSiblings &&
              multiBirthState.multiSiblings.length > 0 &&
              multiBirthState.isMultiBirth &&
              <Grid item xs={"auto"} ml={2}>
                <FormControl sx={{minWidth: 200}}>
                  <InputLabel htmlFor={"multibirth-type"}>Type of Multibirth</InputLabel>
                  <Select
                    value={multiBirthState.type || ""}
                    id={"multibirth-type"}
                    label="Type of Multibirth"
                    required
                    onChange={(e) => {
                      setMultiBirthState({
                        ...multiBirthState,
                        type: e.target.value,
                      })
                    }}>
                    <MenuItem value={"Identical"}>Identical</MenuItem>
                    <MenuItem value={"Not_Identical"}>Not Identical</MenuItem>
                    <MenuItem value={"Unknown"}>I don't know</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            }
            <Grid container item xs={12} spacing={2}>
              {
                multiBirthState.isMultiBirth &&
                <Grid item xs={12} ml={2} mt={2}>Who else was part of this multibirth?</Grid>
              }
              <Grid container ml={4}>
                {
                  multiBirthState.isMultiBirth &&
                  multiBirthSelection.map((sibling, index) => {
                    if (sibling.reactId !== reactKey &&
                      (sibling.multiBirthId === multiBirthId ||
                        sibling.multiBirthId === null)) {
                      return (<Grid item xs={"auto"} key={index}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="sibling-checkbox"
                                checked={multiBirthState.multiSiblings.includes(sibling.reactId)}
                                value={sibling.reactId}
                                required={multiBirthState.multiSiblings.length <= 1}
                                onInvalid={(e) => {
                                  e.target.setCustomValidity("Please select at least one sibling");
                                }}
                                onChange={(e) => {
                                  handleMultiBirthChange(e);
                                }}
                              />}
                            label={sibling.name ? sibling.name : `Your ${getRelationCardLabel(sibling.relationId).relation}`}
                          />
                        </FormControl>
                      </Grid>)
                    }
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
      : <>
        <div className="modal-line-break">
          <hr />
        </div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{color: "secondary.main"}}>
              How to indicate a multibirth (twins, triplets, etc.)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component={"ul"}>
              <Typography ml={-2} sx={{fontWeight: "bold", color: "rgb(201, 62, 0)"}}>To indicate yourself as part of a
                multibirth:</Typography>
              <Typography component={"li"}>Add your siblings in the section labelled "Add your full siblings"</Typography>
              <Typography component={"li"}>Your own name will appear as an option under the sibling that you wish to
                indicate
                as part of the multibirth.</Typography>
              <br />
              <Typography ml={-2} sx={{fontWeight: "bold", color: "rgb(201, 62, 0)"}}>For your
                parents/grandparents:</Typography>
              <Typography component={"li"}>
                Add their siblings in the relevant section{<br />}
                (example: "Your maternal grandmother's siblings")
              </Typography>
              <Typography component={"li"}>You can then edit any relative in that sibling group and their siblings will
                appear
                as possible relatives in the multibirth.</Typography>
              <br />
              <Typography ml={-2} sx={{fontWeight: "bold", color: "rgb(201, 62, 0)"}}>For any other relation:</Typography>
              <Typography component={"li"}>
                Add multiple relatives as children to the relative who had a multibirth{<br />}
                (example: Add multiple cousins to your aunt or uncle)
              </Typography>
              <Typography component={"li"}>You can then edit any relative in that sibling group and their siblings will
                appear as possible relatives in the multibirth.</Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>


      </>);
}

export default MultiBirthOptions;