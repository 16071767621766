import {Grid} from "@mui/material";
import React from "react";
import {connect} from "react-redux";

const Footer = (props) => {

  let {inHealthDiary} = props;
  return (
    <Grid container
          id="footer"
          style={{display: inHealthDiary ? "none" : "block", padding: "10px"}}>
      <Grid item xs={12} sx={{textAlign: "center"}}>
        Copyright INHE<strong>RET</strong> 2023
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    inHealthDiary: state.users.inHealthDiary
  }
}

export default connect(mapStateToProps)(Footer);