export const filterRelationsWithNoData = (relationsObj, patientId) => {
  const relationsArray = Object.entries(relationsObj);
  const filteredRelationsArray = relationsArray.filter((key) => ((key[1].numToAdd !== 0) && !(key[1].exclude)))
  filteredRelationsArray.forEach((item) => {
    item[1].patientId = patientId;
  })
  return Object.fromEntries(filteredRelationsArray);
}

export const filterRelationsIntoArray = (relationsObj, userId) => {
  const arrayForPost = [];

  Object.keys(relationsObj).forEach((key) => {
    let numRels = relationsObj[key].numToAdd;
    for (let i = 0; i < numRels; i++) {
      let rel = {
        patientRelationId: relationsObj[key].id,
        patientId: userId,
        relationId: relationsObj[key].relationId,
        side: relationsObj[key].side,
        motherPrId: relationsObj[key].motherPrId ? relationsObj[key].motherPrId : null,
        fatherPrId: relationsObj[key].fatherPrId ? relationsObj[key].fatherPrId : null,
        deceased: false,
        myKey: relationsObj[key].myKey + "_" + i,
        parentKey: relationsObj[key].parentKey
      }
      arrayForPost.push(rel)
    }
  });
  return arrayForPost;
}