import dayjs from "dayjs";
import {useDispatch} from "react-redux";

export const useModalFormChangeHandlers = () => {
  const dispatch = useDispatch();

  // handles age at death & current age
  const handleAgeChange = (e, reactId, relationState, setState) => {
    setState({
      ...relationState,
      [e.target.name]: parseInt(e.target.value),
    });
  }

  const handleMuiDateChange = (e, reactId, relationState, setState) => {
    let newAge = dayjs().diff(e, "year");
    setState({
      ...relationState,
      ["dob"]: e.toJSON(),
      ageCurrent: newAge,
    });
  }

  const handleNameChange = (e, reactId, relationState, setState) => {
    setState({
      ...relationState,
      name: e.target.value,
    });
  }

  const handleDeceased = (e, reactId, relationState, setState) => {
    setState({
      ...relationState,
      [e.target.name]: e.target.checked
    });
  }

  const handleCauseOfDeath = (e, reactId, relationState, setState) => {
    setState({
      ...relationState,
      [e.target.name]: e.target.value,
    });
  }

  return {
    handleAgeChange,
    handleMuiDateChange,
    handleNameChange,
    handleDeceased,
    handleCauseOfDeath,
  }
}