export function optValGetters() {
  // there. I've done it. the worst possible way to implement this.
  const getFreeTextOptVals = (maniId, optId, inputValue) => {
    switch (maniId) {
      case 283:
        return getMenarcheAgeValueId(inputValue);
      case 284:
        return checkOptVal(optId, inputValue);
      case 294:
        return getMenopauseAgeValueId(inputValue);
      default:
        return undefined;
    }
  }

  const checkOptVal = (optId, inputValue) => {
    switch (optId) {
      case 27:
        return getPregnancyBirthsValueId(inputValue);
      case 41:
        return getPregnancyAgeValueId(inputValue);
      default:
        return undefined;
    }
  }

  const getMenarcheAgeValueId = (input) => {
    if (input < 12) {
      return 116;
    }
    if (input >= 18) {
      return 123;
    }
    switch (input) {
      case 12:
        return 117;
      case 13:
        return 118;
      case 14:
        return 119;
      case 15:
        return 120;
      case 16:
        return 121;
      case 17:
        return 122;
      default:
        return undefined;
    }
  }

  const getPregnancyAgeValueId = (input) => {
    if (input < 12) {
      return 219;
    }
    if (input >= 45) {
      return 229;
    }
    switch (input) {
      case 12:
        return 220;
      case 13:
        return 221;
      case 14:
        return 222;
      case 15:
        return 223;
      case 16:
        return 224;
      case 17:
        return 248;
      case 18:
        return 249;
      case 19:
        return 250;
      case 20:
        return 251;
      case 21:
        return 252;
      case 22:
        return 253;
      case 23:
        return 242;
      case 24:
        return 243;
      case 25:
        return 244;
      case 26:
        return 245;
      case 27:
        return 246;
      case 28:
        return 247;
      case 29:
        return 236;
      case 30:
        return 237;
      case 31:
        return 238;
      case 32:
        return 239;
      case 33:
        return 240;
      case 34:
        return 241;
      case 35:
        return 230;
      case 36:
        return 231;
      case 37:
        return 232;
      case 38:
        return 233;
      case 39:
        return 234;
      case 40:
        return 235;
      case 41:
        return 225;
      case 42:
        return 226;
      case 43:
        return 227;
      case 44:
        return 228;
      default:
        return undefined;
    }
  }

  const getMenopauseAgeValueId = (input) => {
    if (input < 30) {
      return 254;
    }
    if (input > 60) {
      return 262;
    }
    switch (input) {
      case 30:
        return 255;
      case 31:
        return 256;
      case 32:
        return 257;
      case 33:
        return 258;
      case 34:
        return 259;
      case 35:
        return 281;
      case 36:
        return 282;
      case 37:
        return 283;
      case 38:
        return 284;
      case 39:
        return 285;
      case 40:
        return 286;
      case 41:
        return 275;
      case 42:
        return 276;
      case 43:
        return 277;
      case 44:
        return 278;
      case 45:
        return 279;
      case 46:
        return 280;
      case 47:
        return 269;
      case 48:
        return 270;
      case 49:
        return 271;
      case 50:
        return 272;
      case 51:
        return 273;
      case 52:
        return 274;
      case 53:
        return 263;
      case 54:
        return 264;
      case 55:
        return 265;
      case 56:
        return 266;
      case 57:
        return 267;
      case 58:
        return 268;
      case 59:
        return 260;
      case 60:
        return 261;
      default:
        return undefined;
    }
  }

  const getPregnancyBirthsValueId = (input) => {
    if (input < 1) {
      return 124;
    }
    if (input >= 20) {
      return 132;
    }
    switch (input) {
      case 1:
        return 125;
      case 2:
        return 126;
      case 3:
        return 127;
      case 4:
        return 128;
      case 5:
        return 129;
      case 6:
        return 139;
      case 7:
        return 140;
      case 8:
        return 141;
      case 9:
        return 142;
      case 10:
        return 143;
      case 11:
        return 144;
      case 12:
        return 133;
      case 13:
        return 134;
      case 14:
        return 135;
      case 15:
        return 136;
      case 16:
        return 137;
      case 17:
        return 138;
      case 18:
        return 130;
      case 19:
        return 131;
      default:
        return undefined;
    }
  }

  // I'm sorry. 
  const getFieldValFromOptVal = (maniId, optId, optValId) => {
    switch (maniId) {
      case 283:
        return getMenarcheAgeValue(optValId);
      case 284:
        return checkOptValValue(optId, optValId);
      case 294:
        return getMenopauseAgeValue(optValId);
      default:
        return undefined;
    }
  }

  const checkOptValValue = (optId, optValId) => {
    switch (optId) {
      case 27:
        return getPregnancyBirthsValue(optValId);
      case 41:
        return getPregnancyAgeValue(optValId);
      default:
        return undefined;
    }
  }

  const getMenarcheAgeValue = (optValId) => {
    switch (optValId) {
      case 116:
        return "<= 11";
      case 117:
        return 12;
      case 118:
        return 13;
      case 119:
        return 14;
      case 120:
        return 15;
      case 121:
        return 16;
      case 122:
        return 17;
      case 123:
        return "18+";
      default:
        return undefined;
    }
  }

  const getPregnancyAgeValue = (optValId) => {
    switch (optValId) {
      case 219:
        return "<= 11";
      case 220:
        return 12;
      case 221:
        return 13;
      case 222:
        return 14;
      case 223:
        return 15;
      case 224:
        return 16;
      case 248:
        return 17;
      case 249:
        return 18;
      case 250:
        return 19;
      case 251:
        return 20;
      case 252:
        return 21;
      case 253:
        return 22;
      case 242:
        return 23;
      case 243:
        return 24;
      case 244:
        return 25;
      case 245:
        return 26;
      case 246:
        return 27;
      case 247:
        return 28;
      case 236:
        return 29;
      case 237:
        return 30;
      case 238:
        return 31;
      case 239:
        return 32;
      case 240:
        return 33;
      case 241:
        return 34;
      case 230:
        return 35;
      case 231:
        return 36;
      case 232:
        return 37;
      case 233:
        return 38;
      case 234:
        return 39;
      case 235:
        return 40;
      case 225:
        return 41;
      case 226:
        return 42;
      case 227:
        return 43;
      case 228:
        return 44;
      default:
        return undefined;
    }
  }

  const getMenopauseAgeValue = (optValId) => {
    switch (optValId) {
      case 254:
        return "<= 29";
      case 255:
        return 30;
      case 256:
        return 31;
      case 257:
        return 32;
      case 258:
        return 33;
      case 259:
        return 34;
      case 260:
        return 59;
      case 261:
        return 60;
      case 262:
        return "60+";
      case 263:
        return 53;
      case 264:
        return 54;
      case 265:
        return 55;
      case 266:
        return 56;
      case 267:
        return 57;
      case 268:
        return 58;
      case 269:
        return 47;
      case 270:
        return 48;
      case 271:
        return 49;
      case 272:
        return 50;
      case 273:
        return 51;
      case 274:
        return 52;
      case 275:
        return 41;
      case 276:
        return 42;
      case 277:
        return 43;
      case 278:
        return 44;
      case 279:
        return 45;
      case 280:
        return 46;
      case 281:
        return 35;
      case 282:
        return 36;
      case 283:
        return 37;
      case 284:
        return 38;
      case 285:
        return 39;
      case 286:
        return 40;
      default:
        return undefined;
    }
  }

  const getPregnancyBirthsValue = (optValId) => {
    switch (optValId) {
      case 124:
        return 0;
      case 125:
        return 1;
      case 126:
        return 2;
      case 127:
        return 3;
      case 128:
        return 4;
      case 129:
        return 5;
      case 130:
        return 18;
      case 131:
        return 19;
      case 132:
        return "20+";
      case 133:
        return 12;
      case 134:
        return 13;
      case 135:
        return 14;
      case 136:
        return 15;
      case 137:
        return 16;
      case 138:
        return 17;
      case 139:
        return 6;
      case 140:
        return 7;
      case 141:
        return 8;
      case 142:
        return 9;
      case 143:
        return 10;
      case 144:
        return 11;
      default:
        return undefined;
    }
  }

  return {
    getFreeTextOptVals,
    getFieldValFromOptVal,
  }
}