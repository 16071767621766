import {useUpdateModularManifestationsMutation,} from "../Redux/api/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import React from "react";

export default function useReduxModularFormData() {
  const dispatch = useDispatch();
  const {usersGender} = useSelector(state => state.users);
  const [addModularManifestations, {isLoading: multiManiApiIsLoading}] = useUpdateModularManifestationsMutation();
  const multiManifestationsState = useSelector(state => state.multiManifestations);
  
  
  return [
    dispatch,
    multiManifestationsState,
    addModularManifestations,
    multiManiApiIsLoading,
    usersGender,
  ]
}