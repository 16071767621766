import React from "react";
import {Card, CardMedia, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import FamilyPicture from "../Resources/Images/NewFoundry Graphics/wholesome-family.png";

const LandingPageFamilyPhotoGrid = () => {
  const theme = useTheme();
  const hideImageText = useMediaQuery(theme.breakpoints.down("md"), {noSsr: true});

  const [imageHeight, setImageHeight] = React.useState(document.getElementById("login-image")?.width / 0.532);
  const [imageWidth, setImageWidth] = React.useState(document.getElementById("login-image")?.width);
  const handleResize = () => {
    const img = document.getElementById("login-image");
    // The image element "height" attribute is simply unusable for some reason.
    // However, the "width" attribute is available?
    // So we're going to calculate the height based on the width/image aspect ratio.
    // E F I C E N T.
    setImageHeight((img?.width / 0.532));
    setImageWidth(img?.width);
  }

  React.useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize, true);
    return () => {
      // unsubscribe on component unmount
      window.removeEventListener("resize", handleResize, true);
    }
  }, []);


  return (<Grid item xs sm md>
    <Card sx={{backgroundColor: "transparent", boxShadow: "unset", borderRadius: "unset"}}>
      <CardMedia component="img"
                 src={FamilyPicture}
                 alt="Family"
                 id={"login-image"}
                 title={"Family"}
                 sx={{borderRadius: "15px 0 0 15px"}} />
      <Typography component="div"
                  sx={hideImageText ? {} : {
                    position: "absolute",
                    top: 0,
                    width: imageWidth,
                    mt: 5,
                    height: imageHeight,
                    borderRadius: "15px 0 0 15px",
                    background: "linear-gradient(318.04deg, rgba(222, 98, 39, 0.545) 15.86%, rgba(10, 36, 46, 0.361) 49.78%, rgba(27, 100, 129, 0.82) 87.56%)",
                  }} />
      <Typography gutterBottom
                  sx={hideImageText ? {display: "none"} : {
                    position: "absolute",
                    top: "7%",
                    width: imageWidth,
                    marginLeft: "2%",
                    color: "white",
                    fontWeight: 800,
                    fontSize: "2rem",
                  }}>
        Some diseases<br /> can run in your family.
      </Typography>
      <Typography sx={hideImageText ? {display: "none"} : {
        position: "absolute",
        top: "16%",
        marginLeft: "2%",
        mt: 2.5,
        width: `${(imageWidth - 40)}px`,
        color: "white",
        fontWeight: 500,
        minWidth: "415px",
        fontSize: "1.5rem",
      }}>
        Take control of your Family's Future Health Today with INHE<b>RET</b>. <br />
        <b>There is Power in Knowing.</b></Typography>
    </Card>
  </Grid>);
}

export default LandingPageFamilyPhotoGrid;