import {Button, FormLabel, Grid} from "@mui/material";
import {useContext, useEffect} from "react";
import {RelationCancer} from "../Context/TreeList_CancerContext";
import {ReactComponent as AddIcon} from "../../../Resources/Images/Icons/addButtonSmall.svg";
import {RelationCancerFormRow} from "./treeList_CancerFormRow";

export const RelationCancerForm = (props) => {
  const relationCancer = useContext(RelationCancer);
  const {cancer, setCancer, addCancerHist, deleteCancerHist} = relationCancer;
  const {relation, relationReactId, patientRelationId, gender, existing, isDeceased} = props;

  useEffect(() => {
    if (existing.length > 0) {
      setCancer(existing);
    }
  }, [])

  return (
    <>
      <Grid container mt={5}>
        <Grid item xs={12} sm={8}>
          <FormLabel sx={{color: "#000000"}}>
            {isDeceased ? "Was " : "Has "}
            {`your ${relation.toLowerCase()} `}
            {isDeceased ? " ever " : " ever been "}
            diagnosed with any cancer(s)?
          </FormLabel>
        </Grid>
        <Grid item xs={"auto"} ml={1} mt={1}>
          <Button variant={"relation-add"}
                  onClick={(e) => {
                    e.preventDefault();
                    addCancerHist(patientRelationId)
                  }}>
            <AddIcon style={{width: "16px", height: "16px"}} />
            &nbsp;Add cancer
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={"auto"}>
        {
          (cancer.length > 0)
            ? cancer.map((cancer) => {
              return (
                <RelationCancerFormRow key={cancer.reactId}
                                       relationReactId={relationReactId}
                                       relationGender={gender}
                                       existingCancer={cancer}
                                       deleteCancerHist={deleteCancerHist} />
              )
            })
            : undefined
        }
      </Grid>
    </>
  )
}