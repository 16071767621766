// noinspection JSUnresolvedVariable

import {Button, Grid, Typography} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useValidateToken} from "../../Hooks/useValidateToken";
import {Helmet} from "react-helmet";
import {finalFormSubmit} from "../../Redux/users/userSlice";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";

const TreeList_FinalPage = () => {
  useValidateToken(true, "history");
  const {handleFormNavigation} = useFormNavigation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState = useSelector(state => state.users);
  const {entities, usersPatientId} = userState;
  const {patientProviderName} = entities[usersPatientId] ? entities[usersPatientId] : {patientProviderName: ""};

  const handleFinalSubmission = (e) => {
    e.preventDefault();
    //console.log("Submitting form");
    dispatch(finalFormSubmit()).then(() => {
      //console.log("Form submitted");
      navigate("/health-questionnaire/complete");
    })
  }

  let isLoading = false;
  return (
    isLoading
      ? undefined
      : <>
        <Helmet>
          <title>Ready to submit?</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper"
              id="questionnaire-wrapper">
          <Grid item>
            <Typography textAlign={"center"} variant="h2" mt={5}>
              Well done!
            </Typography>
            <Typography textAlign={"center"} variant="h2" mt={5}>
              Ready to submit your <b className="blue-emphasis">health
              diary</b> to {patientProviderName ? patientProviderName : "your provider"}?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className="questionnaire-content family-tree"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (e.nativeEvent.submitter.name === "back") {
                      handleFormNavigation(e);
                    }
                  }}>
              <Grid container justifyContent={"center"}
                    flexDirection={"column"}
                    className="questionnaire-inner-content">
                <Grid item xs={12}>
                  <Typography variant={"body1"} textAlign={"center"}>
                    Please click on the button below to submit, or, click the back button to continue to make changes.
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={5} ml={"auto"} mr={"auto"}>
                  <Button variant={"contained"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleFinalSubmission(e);
                          }}>
                    Submit Health Diary
                  </Button>
                </Grid>
                <BackContinueButtons />
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>
  );
}

export default TreeList_FinalPage