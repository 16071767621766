import {createContext, useState} from "react";
import {randomId} from "../../../Helpers/utils";
import {useDispatch} from "react-redux";
import {relationsManifestationDelete} from "../../../Redux/relations/relationSlice";

export const RelationOther = createContext({});

export const OtherProvider = ({children}) => {
  const [other, setOther] = useState([]);
  const [patientRelationId, setPatientRelationId] = useState(null);
  const dispatch = useDispatch();

  const deleteOtherHist = (id, relationReactId) => {
    const {prmId, existedInDb} = other.filter((mani) => mani["reactId"] === id)[0];
    setOther(other.filter((other) => other.reactId !== id));
    if (prmId) {
      dispatch(relationsManifestationDelete(relationReactId, prmId, id, existedInDb));
    }
    if (!existedInDb) {
      dispatch(relationsManifestationDelete(relationReactId, prmId, id, existedInDb));
    }
  }

  const addOtherHist = (patientRelationId) => {
    setOther((other) => [
      ...other,
      {
        patientRelationId,
        reactId: randomId() + randomId(),
        quantity: 1,
        existedInDb: false,
      }
    ])
  }

  return (
    <RelationOther.Provider value={{
      other,
      setOther,
      patientRelationId,
      setPatientRelationId,
      addOtherHist,
      deleteOtherHist
    }}>
      {children}
    </RelationOther.Provider>
  );
}