import React from "react";
import {useParams} from "react-router-dom";
import RootBoundary from "../../../errors/ErrorBoundary";

import FirstPageNewPatient from "../00_FirstPageNewPatient";
import OtherHealthHistory from "../100_OtherHealthHistoryWrapper";
import BasicInfo from "../10_BasicInfo";
import ResultsPage from "../110_ResultsPage";
import BasicAncestry from "../12_BasicAncestry";
import LifestyleHtWt from "../13_Lifestyle_HtWt";
import LifestyleSmoking from "../15_Lifestyle_Smoking";
import LifeStyleAlcohol from "../17_Lifestyle_Alcohol";
import ReproductiveHistory from "../20_ReproductiveHistory";
import ReproductiveHistoryHrt from "../21_ReproductiveHistoryHrt";
import Genetics from "../30_Genetics";
import BreastCancerScreenings from "../40_BreastCancerScreenings";
import CancerScreeningsAll from "../50_CancerScreeningsAll";
import CancerScreeningsAllCont from "../51_CancerScreeningsAllCont";
import MaleCancerScreenings from "../60_MaleCancerScreenings";
import FemaleCancerScreenings from "../70_FemaleCancerScreenings";
import BiopsyHistory from "../80_BiopsyHistoryWrapper";
import CancerHistory from "../90_CancerHistoryWrapper";
import {Questionnaire_Submitted} from "../Questionnaire_Submitted";
import TreeList_BuildListWrapper from "../TreeList_BuildListWrapper";
import TreeList_FathersRelations from "../TreeList_FathersRelations";
import TreeList_FinalPage from "../TreeList_FinalPage";
import TreeList_MothersRelations from "../TreeList_MothersRelations";

import TreeList_SelfRelations from "../TreeList_SelfRelations";


export const stepOrder = [
  {
    path: "",
    element: <FirstPageNewPatient />,
    errorElement: <RootBoundary />,
  },
  {
    path: "basic-info",
    element: <BasicInfo />,
    errorElement: <RootBoundary />,
  },
  {
    path: "basic-ancestry",
    element: <BasicAncestry />,
    errorElement: <RootBoundary />,
  },
  {
    path: "lifestyle-htwt",
    element: <LifestyleHtWt />,
    errorElement: <RootBoundary />,
  },
  {
    path: "lifestyle-smoking",
    element: <LifestyleSmoking />,
    errorElement: <RootBoundary />,
  },
  {
    path: "lifestyle-alcohol",
    element: <LifeStyleAlcohol />,
    errorElement: <RootBoundary />,
  },
  {
    path: "reproductive-history",
    element: <ReproductiveHistory />,
    sex: "Female",
    errorElement: <RootBoundary />,
  },
  {
    path: "reproductive-history-hrt",
    element: <ReproductiveHistoryHrt />,
    sex: "Female",
    errorElement: <RootBoundary />,
  },
  {
    path: "genetics",
    element: <Genetics />,
    errorElement: <RootBoundary />,
  },
  {
    path: "bc-screenings",
    element: <BreastCancerScreenings />,
    errorElement: <RootBoundary />,
  },
  {
    path: "cancer-screenings",
    element: <CancerScreeningsAll />,
    errorElement: <RootBoundary />,
  },
  {
    path: "cancer-screenings-continued",
    element: <CancerScreeningsAllCont />,
    errorElement: <RootBoundary />,
  },
  {
    path: "cancer-screenings-male",
    element: <MaleCancerScreenings />,
    sex: "Male",
    errorElement: <RootBoundary />,
  },
  {
    path: "cancer-screenings-female",
    element: <FemaleCancerScreenings />,
    sex: "Female",
    errorElement: <RootBoundary />,
  },
  {
    path: "biopsy-history",
    element: <BiopsyHistory />,
    errorElement: <RootBoundary />,
  },
  {
    path: "cancer-history",
    element: <CancerHistory />,
    errorElement: <RootBoundary />,
  },
  {
    path: "other-history",
    element: <OtherHealthHistory />,
    errorElement: <RootBoundary />,
  },
  {
    path: "finished-questionnaire",
    element: <ResultsPage />,
    errorElement: <RootBoundary />,
  },
  {
    path: "self-relations",
    element: <TreeList_SelfRelations />,
    errorElement: <RootBoundary />,
  },
  {
    path: "mother-relations",
    element: <TreeList_MothersRelations />,
    errorElement: <RootBoundary />,
  },
  {
    path: "father-relations",
    element: <TreeList_FathersRelations />,
    errorElement: <RootBoundary />,
  },
  {
    path: "relations-histories",
    element: <TreeList_BuildListWrapper />,
    errorElement: <RootBoundary />,
  },
  {
    path: "submission-page",
    element: <TreeList_FinalPage />,
    errorElement: <RootBoundary />,
  },
  {
    path: "complete",
    element: <Questionnaire_Submitted />,
    errorElement: <RootBoundary />,
  }
]

//const pathOrder = stepOrder.map((order) => order.path)

export const useCurrentPosition = (userSex) => {
  const filteredStepOrder = stepOrder.filter(item => !item.sex || !userSex || item.sex === userSex)

  const urlSlug = useParams()["*"]?.toLowerCase();
  let index = 0;//urlSlug ? pathOrder.indexOf(urlSlug) || 0 : 0;
  for (let i = 0; i < filteredStepOrder.length; i++) {
    if (filteredStepOrder[i].path === urlSlug) {
      index = i;
      break;
    }
  }
  const slug = filteredStepOrder[index];
  const previous = filteredStepOrder[index - 1];
  const next = filteredStepOrder[index + 1];

  return {
    slug,
    index,
    isFirst: previous === undefined,
    isLast: next === undefined,
    previous,
    next
  };
}