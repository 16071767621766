import {Grid, InputAdornment, InputLabel, TextField, Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import React from "react";
import {useValidateToken} from "../../Hooks/useValidateToken";

import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {updateUserState} from "../../Redux/users/userSlice";
import useReduxFormData from "../../Hooks/useReduxFormData";

const LifestyleHtWt = () => {
  // TODO - refactor this to use a redux data handler and not local state
  useValidateToken(true, "basic");
  const [dispatch, usersState, updateUser, userApiIsLoading] = useReduxFormData();
  const [loadNum, setLoadNum] = React.useState(0);
  const {entities, usersPatientId, usersIsDirty, usersFetchLoading, usersHasToken} = usersState;
  const {handleFormNavigation} = useFormNavigation();


  const getInitialFormState = (id) => {
    let {
      height,
      heightFt,
      heightIn,
      weight,
    } = entities[id];

    return {
      height: height ? height : 0,
      weight: weight ? weight : 0,
      heightFt: heightFt ? heightFt : 0,
      heightIn: heightIn ? heightIn : 0,
    }
  }

  const {inputs, handleBasicInputChange} = useReduxDataHandler(getInitialFormState(usersPatientId));
  React.useEffect(() => {
    setLoadNum(loadNum + 1);
    dispatch(updateUserState(inputs))
  }, [inputs])


  const validateInputs = () => {
    return true;
  }

  return (
    usersFetchLoading
      ? undefined
      : (<>
          <Helmet>
            <title>Lifestyle</title>
          </Helmet>
          <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
            <Grid item xs={8}>
              <Typography textAlign={"center"} variant="h2" mt={5}>&nbsp;</Typography>
            </Grid>
            <Grid item xs={12}>
              <form className={"questionnaire-content"}
                    onSubmit={async (e) => {
                      e.preventDefault();
                      if (validateInputs()) {
                        if (usersIsDirty && loadNum > 1) {
                          let userMod = {
                            ...entities[usersPatientId],
                            token: usersHasToken,
                            usersPatientId: usersPatientId
                          };
                          await updateUser(userMod)
                            .unwrap()
                            .then(() => handleFormNavigation(e))
                        }
                        handleFormNavigation(e)
                      }
                    }}>
                <Grid spacing={2} container justifyContent={"center"} item xs={12} ml={"auto"} mr={"auto"}
                      className="questionnaire-inner-content">
                  <Grid spacing={1} container item flexDirection={"row"} sx={{maxWidth: "400px"}}>
                    <Grid item xs={6}>
                      <br />
                      <InputLabel>How tall are you?</InputLabel>
                    </Grid>
                    <Grid item xs={3}>
                      <InputLabel htmlFor={"HeightFt"}>
                        Feet
                      </InputLabel>
                      <TextField
                        aria-labelledby={"HeightFt"}
                        name={"heightFt"}
                        id={"HeightFt"}
                        style={{maxWidth: "100px", minWidth: "80px"}}
                        type={"number"}
                        value={(inputs.heightFt && inputs.heightFt) || ""}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          title: "Numbers Only",
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">ft</InputAdornment>,
                        }}
                        onChange={handleBasicInputChange} />
                    </Grid>
                    <Grid item xs={3}>
                      <InputLabel htmlFor={"HeightIn"}>
                        Inches
                      </InputLabel>
                      <TextField
                        aria-labelledby={"HeightIn"}
                        name={"heightIn"}
                        id={"HeightIn"}
                        style={{maxWidth: "120px", minWidth: "100px"}}
                        type={"number"}
                        value={(inputs.heightIn && inputs.heightIn) || 0}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          title: "Numbers Only",
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">in</InputAdornment>,
                        }}
                        onChange={(e) => {
                          handleBasicInputChange(e);
                        }} />
                    </Grid>
                  </Grid>
                  <Grid spacing={1} container item flexDirection={"row"} ml={{xs: 0, sm: 1}} sx={{maxWidth: "300px"}}>
                    <Grid item xs={6}>
                      <InputLabel htmlFor={"Weight"}>
                        How much do you weigh?
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        aria-labelledby={"weight"}
                        name={"weight"}
                        value={(inputs.weight && inputs.weight) || ""}
                        style={{maxWidth: "170px"}}
                        id={"Weight"}
                        type={"number"}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          title: "Numbers Only",
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                        }}
                        onChange={handleBasicInputChange} />
                    </Grid>
                  </Grid>
                  <BackContinueButtons loading={userApiIsLoading} />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </>
      )
  )
};
export default LifestyleHtWt;