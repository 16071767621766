import AddIcon from "@mui/icons-material/Add";
import {Button, Grid} from "@mui/material";
import React from "react";
import {useDispatch} from "react-redux";
import {
  manifestationMultiManiInitialAddNoOpts,
  manifestationsMultiManiDelete
} from "../../../Redux/manifestations/multiManifestationSlice";
import PolypRow from "./53_PolypCountRow";

const PolypCounts = (props) => {
  const {handlePolypOptValChange, handlePolypQtyChange, selfRelationId, existingPolypsManis} = props;
  const dispatch = useDispatch();

  const [outerFormState, setOuterFormState] = React.useState([]);

  React.useEffect(() => {
    setOuterFormState(existingPolypsManis)
  }, [existingPolypsManis])
  const handleAddPolyp = () => {
    const {reactId: newId} = dispatch(manifestationMultiManiInitialAddNoOpts(selfRelationId, 1427)).payload;
    outerFormState.length > 0
      ? setOuterFormState((prevState) => {
        return [
          ...prevState,
          {
            reactId: newId,
            patientRelationId: selfRelationId,
            manifestationId: 1427,
            quantity: "",
            edited: true,
            markForDeletion: false,
            existedInDb: false,
            prmOptVals: [
              {
                manifestationOptionId: "",
                manifestationOptionValueId: "",
              }
            ],
          }
        ]
      })
      : setOuterFormState(() => {
        return [
          {
            reactId: newId,
            patientRelationId: selfRelationId,
            manifestationId: 1427,
            quantity: "",
            edited: true,
            markForDeletion: false,
            existedInDb: false,
            prmOptVals: [
              {
                manifestationOptionId: "",
                manifestationOptionValueId: "",
              }
            ],
          }
        ]
      })
  }

  const handleDeletePolyp = (reactId) => {
    dispatch(manifestationsMultiManiDelete(reactId));
    setOuterFormState((prevState) => {
      return prevState.filter((polypMani) => {
        return polypMani.reactId !== reactId
      })
    })
  }

  return (
    <>
      <Grid container item xs={11} sm={8} md={5} justifyContent={"space-between"} className={"white-card"} ml={"auto"}
            mr={"auto"}>
        {
          outerFormState.length > 0 &&
          outerFormState.map((polypMani, index) => {
            return <PolypRow key={index}
                             polypReactId={polypMani.reactId}
                             handleTypeChange={handlePolypOptValChange}
                             handleQtyChange={handlePolypQtyChange}
                             polypMani={polypMani}
                             handleDeletePolyp={handleDeletePolyp} />
          })
        }
        <Grid item ml={"auto"} mr={"auto"}>
          <Button variant={"relation-add"} onClick={(e) => {
            e.preventDefault();
            handleAddPolyp();
          }}>
            <AddIcon
              style={{width: "16px", height: "16px"}} />&nbsp;Add {outerFormState.length > 0 ? 'another' : 'a'} polyp
            result
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default PolypCounts;