import {optValGetters} from "./optValGetters";

export function DefaultInputValGetters() {
  const {getFieldValFromOptVal} = optValGetters();

  const getDefaultNormalFieldValue = (entities, maniId, maniOptId) => {
    let result = "";
    if (entities[maniId].prmOptVals && entities[maniId].prmOptVals.length > 0) {
      if (entities[maniId].prmOptVals.find((maniOpt) =>
        maniOpt["manifestationOptionId"] === maniOptId)) {
        result = entities[maniId].prmOptVals.find((maniOpt) =>
          maniOpt["manifestationOptionId"] === maniOptId).manifestationOptionValueId;
      }
      return result;
    }
  }

  const getDefaultTerribleFieldValue = (entities, maniId, maniOptId) => {
    let result = "";
    if (entities[maniId].prmOptVals && entities[maniId].prmOptVals.length > 0) {
      if (entities[maniId].prmOptVals.find((maniOpt) =>
        maniOpt["manifestationOptionId"] === maniOptId)) {
        let optValId = entities[maniId].prmOptVals.find((maniOpt) =>
          maniOpt["manifestationOptionId"] === maniOptId).manifestationOptionValueId;
        result = getFieldValFromOptVal(maniId, maniOptId, optValId);
      }
    }
    return result;
  }

  const getDateFieldValue = (entities, maniId, type) => {
    let result = "";

    if (type === "month") {
      if (entities[maniId].manifestationDate && entities[maniId].manifestationDate.length > 10) {
        result = new Date(entities[maniId].manifestationDate).getMonth() + 1;
      }
      if (entities[maniId].manifestationDate && entities[maniId].manifestationDate.length === 10) {
        result = new Date(entities[maniId].manifestationDate + "T00:00:00").getMonth() + 1;
      }
    }

    if (type === "year") {
      if (entities[maniId].manifestationDate && entities[maniId].manifestationDate.length > 10) {
        result = new Date(entities[maniId].manifestationDate).getFullYear();
      }
      if (entities[maniId].manifestationDate && entities[maniId].manifestationDate.length === 10) {
        result = new Date(entities[maniId].manifestationDate + "T00:00:00").getFullYear();
      }
    }

    if (type === "existing") {
      if (entities[maniId].manifestationDate && entities[maniId].manifestationDate.length > 10) {
        let dateState = {};
        dateState["month"] = new Date(entities[maniId].manifestationDate).getMonth() + 1;
        dateState["year"] = new Date(entities[maniId].manifestationDate).getFullYear();

        result = dateState;
      }
      if (entities[maniId].manifestationDate && entities[maniId].manifestationDate.length === 10) {
        let dateState = {};
        dateState["month"] = new Date(entities[maniId].manifestationDate).getMonth() + 1;
        dateState["year"] = new Date(entities[maniId].manifestationDate).getFullYear();

        result = dateState;
      }
      if (!entities[maniId].manifestationDate) {
        result = false;
      }
    }
    return result;
  }

  const checkForMoreValues = (entities, maniId, maniOptId, maniOptValId) => {
    if (!entities[maniId]) {
      return false;
    }
    if (entities[maniId].prmOptVals && entities[maniId].prmOptVals.length > 0) {
      if (entities[maniId].prmOptVals.find((maniOpt) =>
        maniOpt["manifestationOptionId"] === maniOptId)) {
        if (entities[maniId].prmOptVals.find((maniOpt) =>
          maniOpt["manifestationOptionId"] === maniOptId).manifestationOptionValueId === maniOptValId) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  const getModularFieldValue = (entity) => {
    let result = "";
    if (Object.keys(entity).length > 0) {
      if (entity.prmOptVals.length > 0) {
        result = entity.prmOptVals[0].manifestationOptionValueId;
      }
      return result;
    }
    return result;
  }

  const getMultiInputModularFieldValue = (entity, optId) => {
    let result = "";
    if (Object.keys(entity).length > 0) {
      if ((entity.prmOptVals && entity.prmOptVals.length > 0)) {
        if (entity.prmOptVals.findIndex((opt) => opt.manifestationOptionId === optId) === -1) {
          return result;
        }
        result = entity.prmOptVals[entity.prmOptVals
          .findIndex((opt) => opt.manifestationOptionId === optId)]
          .manifestationOptionValueId.toString();
      }
      return result;
    }
    return result;
  }

  return {
    getDefaultNormalFieldValue,
    getDefaultTerribleFieldValue,
    checkForMoreValues,
    getDateFieldValue,
    getModularFieldValue,
    getMultiInputModularFieldValue,
  }
}