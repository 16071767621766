import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import store from "../store/store";

const GLOBAL = require("../../Helpers/globals");

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({baseUrl: `${process.env.REACT_APP_API_BASE_URL}`}),
  endpoints: (builder) => ({
    // we'll finish it later, yeaaa
    fetchManifestations: builder.query({
      query: (relationId) =>
        ({
          url: `/manifestations/${relationId}`,
          method: "GET",
          headers: store.getState().users.usersAxiosConfig.headers,
        }),
      providesTags: ["Manifestations"],
    }),
    updateManifestations: builder.mutation({
      query: (manifestations) => ({
        url: "/manifestations/add",
        method: "POST",
        headers: store.getState().users.usersAxiosConfig.headers,
        body: manifestations,
      }),
      invalidatesTags: ["Manifestations"],
    }),
    updateModularManifestations: builder.mutation({
      query: (manifestations) => ({
        url: "/manifestations/add",
        method: "POST",
        headers: store.getState().users.usersAxiosConfig.headers,
        body: manifestations,
      }),
      invalidatesTags: ["ModularManifestations"],
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: `/users/${user.usersPatientId}`,
        method: "PUT",
        body: user,
        headers: {Authorization: `Bearer ${user.token}`},
      }),
    }),
  })
});

export const {
  useUpdateManifestationsMutation,
  useUpdateModularManifestationsMutation,
  useUpdateUserMutation,
} = apiSlice;