// noinspection DuplicatedCode

import {Grid, Typography} from "@mui/material";
import React from "react";
import useReduxModularFormData from "../../Hooks/useReduxModularFormData";
import {useModularReduxData} from "../../Hooks/useModularFormDataHandler";

import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {Helmet} from "react-helmet";
import {ScreeningBoxMonthYear} from "./QuestionnaireComponents/ScreeningBoxes";
import useReduxFormData from "../../Hooks/useReduxFormData";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";
import {useValidateToken} from "../../Hooks/useValidateToken";
import PolypCounts from "./QuestionnaireComponents/52_PolypCounts";

const CancerScreeningsAll = () => {
  useValidateToken(true, "health");
  const [dispatch, usersState, , , manifestationsState, addManifestations] = useReduxFormData();
  const [, multiManifestationsState, addModularManifestations] = useReduxModularFormData();

  const [polypInfo, setPolypInfo] = React.useState({});

  const {
    ids: modularReactIds,
    entities: modularEntities,
    multiManiFetchLoading,
    multiManiDirty,
    multiManiDbPostLoading,
  } = multiManifestationsState;

  const {
    ids: maniIds,
    manifestationsDirty,
    manifestationsDbPostLoading,
    entities,
    manifestationsFetchLoading,
  } = manifestationsState;

  const {usersSelfRelationId} = usersState;

  const {handleDateColChange, handleRadioChanged} = useReduxDataHandler();

  const {
    handleCancerColDataChange: handlePolypColDataChange,
    handlePolypOptValChange,
  } = useModularReduxData();

  const {handleFormNavigation} = useFormNavigation();

  const validateInputs = () => {return true;}
  let polyps = [];

  React.useEffect(() => {
    if (modularReactIds.length > 0) {
      modularReactIds.forEach((id) => {
        if ((modularEntities[id].manifestationId === 1427)) {
          polyps.push(modularEntities[id]);
        }
      })
      if (polyps.length > 0) {
        setPolypInfo(polyps);
      }
    }
  }, [multiManiFetchLoading])

  return (
    manifestationsFetchLoading
      ? undefined
      : (<>
          <Helmet>
            <title>Cancer Screenings</title>
          </Helmet>
          <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
            <Grid item xs={8}>
              <Typography textAlign={"center"} variant="h2" mt={5}>&nbsp;</Typography>
            </Grid>
            <Grid item xs={12}>
              <form className={"questionnaire-content"}
                    onSubmit={async (e) => {
                      e.preventDefault()
                      if (validateInputs()) {
                        if (manifestationsDirty) {
                          await addManifestations(Object.values(entities).filter((m) => m.edited === true))
                            .unwrap()
                            .then(() => {
                              if (multiManiDirty) {
                                addModularManifestations(Object.values(modularEntities).filter((m) => m.edited === true))
                              }
                            })
                            .then(() => {dispatch(fetchManifestations(usersSelfRelationId))})
                            .then(() => handleFormNavigation(e))
                          return;
                        }
                        if (multiManiDirty) {
                          await addModularManifestations(Object.values(modularEntities).filter((m) => m.edited === true))
                            .unwrap()
                            .then(() => {
                              if (manifestationsDirty) {
                                addManifestations(Object.values(entities).filter((m) => m.edited === true))
                              }
                            })
                            .then(() => {dispatch(fetchManifestations(usersSelfRelationId))})
                            .then(() => handleFormNavigation(e))
                          return;
                        }
                        handleFormNavigation(e)
                      }
                    }}>
                <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                      className="questionnaire-inner-content">
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="hadColonoscopy">Have you ever had a colonoscopy?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={276}
                             name={"hadColonoscopy"}
                             opp-manifestation={4481}
                             defaultChecked={maniIds.includes(276) || maniIds.includes("276")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4481}
                             name={"hadColonoscopy"}
                             opp-manifestation={276}
                             defaultChecked={maniIds.includes(4481) || maniIds.includes("4481")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(276) || maniIds.includes("276"))
                      ? <>
                        <ScreeningBoxMonthYear labelText="When did you have your last colonoscopy?"
                                               inputName="hadColonoscopy"
                                               type={"colonoscopy"}
                                               manifestationsState={manifestationsState}
                                               maniId={276}
                                               handleInputChange={handleDateColChange} />
                        <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                          <Grid item xs={6} md={4}>
                            <label htmlFor="colonoscopyShowedPolyps">
                              Have you ever been told you have polyps?
                            </label>
                          </Grid>
                          <Grid item>
                            <input type="radio"
                                   value={4532}
                                   name={"colonoscopyShowedPolyps"}
                                   opp-manifestation={4531}
                                   defaultChecked={maniIds.includes(4532) || maniIds.includes("4532")}
                                   onChange={(e) => {
                                     handleRadioChanged(e, usersSelfRelationId)
                                   }}
                            /><span>&nbsp;Yes&nbsp;</span>
                            <input type="radio"
                                   value={4531}
                                   name={"colonoscopyShowedPolyps"}
                                   opp-manifestation={4532}
                                   defaultChecked={maniIds.includes(4531) || maniIds.includes("4531")}
                                   onChange={(e) => {
                                     handleRadioChanged(e, usersSelfRelationId)
                                   }}
                            /><span>&nbsp;No&nbsp;</span>
                          </Grid>
                        </Grid>
                      </>
                      : undefined
                  }
                  {
                    (maniIds.includes(4532) || maniIds.includes("4532"))
                      ? <>
                        <PolypCounts handlePolypOptValChange={handlePolypOptValChange}
                                     handlePolypQtyChange={handlePolypColDataChange}
                                     selfRelationId={usersSelfRelationId}
                                     existingPolypsManis={polypInfo} />
                      </>
                      : undefined
                  }
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="ctColonographyOrSigmoidoscopy">
                        Have you ever had a CT Colonography or Flexible Sigmoidoscopy?
                      </label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={4482}
                             name={"ctColonographyOrSigmoidoscopy"}
                             opp-manifestation={4483}
                             defaultChecked={maniIds.includes(4482) || maniIds.includes("4482")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4483}
                             name={"ctColonographyOrSigmoidoscopy"}
                             opp-manifestation={4482}
                             defaultChecked={maniIds.includes(4483) || maniIds.includes("4483")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(4482) || maniIds.includes("4482")) &&
                    <ScreeningBoxMonthYear labelText="When was the last one?"
                                           inputName="ctColonographyOrSigmoidoscopy"
                                           manifestationsState={manifestationsState}
                                           maniId={4482}
                                           handleInputChange={handleDateColChange} />
                  }
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="stoolSample">
                        Have you had a fecal occult (stool sample) blood test?
                      </label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={4484}
                             name={"stoolSample"}
                             opp-manifestation={4485}
                             defaultChecked={maniIds.includes(4484) || maniIds.includes("4484")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4485}
                             name={"stoolSample"}
                             opp-manifestation={4484}
                             defaultChecked={maniIds.includes(4485) || maniIds.includes("4485")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(4484) || maniIds.includes("4484")) &&
                    <ScreeningBoxMonthYear labelText="When was the last one?"
                                           inputName="stoolSample"
                                           manifestationsState={manifestationsState}
                                           maniId={4484}
                                           handleInputChange={handleDateColChange} />
                  }
                  <BackContinueButtons loading={manifestationsDbPostLoading} />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </>
      ))
}

export default CancerScreeningsAll;