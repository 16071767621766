// noinspection DuplicatedCode
import React, {useState} from "react";
import {Helmet} from "react-helmet";
import {useSelector, useDispatch} from "react-redux";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Button, Grid, IconButton, Input, InputAdornment, Typography} from "@mui/material";

import axios from "axios";
import {useNavigate} from "react-router-dom";
import PasswordProgressBar from "../Components/PasswordProgressBar";
import {scorePassword, validatePassReqs, validatePasswordComplexity} from "../Helpers/passwordComplexity";

const GLOBAL = require("../Helpers/globals");

const ExpiredPasswordReset = () => {
  const usersState = useSelector(state => state.users);
  const {firstName, lastName, userEmail, userPwd} = usersState.entities[usersState.ids[0]];
  const {usersAxiosConfig, usersFetchLoading, usersPatientId} = usersState;

  const [success, setSuccess] = useState(false);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [passReqs, setPassReqs] = useState({});
  const [passScore, setPassScore] = useState({text: "Weak", score: 0, color: "#DD6216"});
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validatePassword = async () => {
    let validPass = validate();

    if (!validPass) {
      return false;
    }

    let postData = {
      currentPassword: inputs["currentPass"],
      hashedPassword: userPwd
    }

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/authenticate/validate/pass`, postData, {headers: usersAxiosConfig.headers})
      .then(res => {
        res.data.validPassword ? validPass = true : validPass = false;
      })
      .catch(err => {
        setErrors({...errors, currentPass: "Current password is invalid"});
        validPass = false;
      })

    return validPass;
  }

  const handleChange = (e) => {
    setSuccess(false);
    setErrors({...errors, [e.target.name]: ""});
    setInputs({...inputs, [e.target.name]: e.target.value});
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.nativeEvent.submitter.className === "button-disabled") {
      return;
    }
    if (await validatePassword() === true) {
      let postData = {
        userIdToUpdate: usersPatientId,
        newPassword: inputs["newPass"]
      }
      let changedResult = await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/authenticate/pass/change`, postData, {headers: usersAxiosConfig.headers})
        .then(res => {
          return res.data
        })
        .catch(err => {
          console.error(err);
        })
      const {passwordChanged} = changedResult;
      if (passwordChanged) {
        dispatch({type: "users/updatePasswordExpired", payload: false});
        setInputs({});
        setErrors({});
        setPassReqs({});
        setPassScore({text: "Weak", score: 0, color: "#DD6216"});
        setSuccess(true);
        document.getElementById("currentPass").value = "";
        document.getElementById("newPass").value = "";
        document.getElementById("confirmPass").value = "";
      }
    } else {
      console.error("something invalid");
    }
  }

  const handlePassChange = (e) => {
    setErrors({...errors, [e.target.name]: ""});
    let passStrength = validatePasswordComplexity(e.target.value);
    const {requirements} = validatePassReqs(e.target.value);
    setPassReqs(requirements);
    let status = scorePassword(passStrength);
    setPassScore(status);
    setInputs({...inputs, [e.target.name]: e.target.value});
  }

  const handleShowCurrentPass = (e) => {
    e.preventDefault();
    setShowCurrentPass(!showCurrentPass);
  }
  const handleShowNewPass = (e) => {
    e.preventDefault();
    setShowNewPass(!showNewPass);
  }

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!inputs["currentPass"]) {
      isValid = false;
      errors["currentPass"] = "Please enter your current password";
    }

    if (!inputs["newPass"]) {
      isValid = false;
      errors["newPass"] = "Please enter a new password";
    }

    if (!inputs["confirmPass"]) {
      isValid = false;
      errors["confirmPass"] = "Please confirm your new password";
    }

    if (typeof inputs["newPass"] !== "undefined" && typeof inputs["confirmPass"] !== "undefined") {
      if (inputs["newPass"] !== inputs["confirmPass"]) {
        isValid = false;
        errors["confirmPass"] = "Passwords do not match";
      }
    }

    let {meetsAll} = validatePassReqs(inputs["newPass"]);
    if (!meetsAll) {
      isValid = false;
      errors["newPass"] = "Password does not meet requirements";
    }

    setErrors(errors);
    return isValid;
  }

  React.useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    }
  }, [success])

  return usersFetchLoading ? (
    <div>
      Loading...
    </div>
  ) : (<>
      <Helmet>
        <title>Reset expired password</title>
      </Helmet>
      <Grid container item xs={11} md={9} lg={7} p={"25px"} ml="auto" mr="auto" className="white-card">
        <Grid spacing={2} container item>
          <Grid item xs={12} mt={5} mb={5}>
            <Typography variant={"h5"} textAlign={"center"} className={"blue-text"}>
              Your current password is expired!<br />
              Please enter a new password.
            </Typography>
          </Grid>
          <form className="password"
                onSubmit={handleSubmit}>
            <Grid spacing={2} container item ml="auto" mr="auto">
              <Grid item xs={12}>
                <label>Current Password</label><br />
                <Input name="currentPass"
                       id={"currentPass"}
                       type={`${showCurrentPass ? "text" : "password"}`}
                       className="text-input"
                       placeholder="Password"
                       onChange={handleChange}
                       disableUnderline={true}
                       endAdornment={
                         <InputAdornment position={"end"}>
                           <IconButton onClick={handleShowCurrentPass}>
                             {showCurrentPass ? <Visibility /> : <VisibilityOff />}
                           </IconButton>
                         </InputAdornment>
                       } />
                {errors["currentPass"] && <div className="error-text">{errors["currentPass"]}</div>}
              </Grid>
              <Grid item flexGrow={1} xs={"auto"}>
                <label>New Password</label><br />
                <Input name="newPass"
                       id={"newPass"}
                       type={`${showNewPass ? "text" : "password"}`}
                       className="text-input"
                       placeholder="New Password"
                       onChange={handlePassChange}
                       disableUnderline={true}
                       endAdornment={
                         <InputAdornment position={"end"}>
                           <IconButton onClick={handleShowNewPass}>
                             {showNewPass ? <Visibility /> : <VisibilityOff />}
                           </IconButton>
                         </InputAdornment>
                       }
                />
                {errors["newPass"] && <div className="error-text">{errors["newPass"]}</div>}
              </Grid>
              <Grid item flexGrow={1} xs={"auto"}>
                <label>Confirm New Password</label><br />
                <Input name="confirmPass"
                       id={"confirmPass"}
                       type={`${showNewPass ? "text" : "password"}`}
                       className="text-input"
                       placeholder="Confirm New Password"
                       onChange={handleChange}
                       disableUnderline={true}
                       endAdornment={
                         <InputAdornment position={"end"}>
                           <IconButton onClick={handleShowNewPass}>
                             {showNewPass ? <Visibility /> : <VisibilityOff />}
                           </IconButton>
                         </InputAdornment>
                       }
                />
                {errors["confirmPass"] && <div className="error-text">{errors["confirmPass"]}</div>}
              </Grid>
              <Grid item flexGrow={1} xs={"auto"}>
                <br />
                <Button
                  variant={(inputs["currentPass"] && inputs["newPass"] && inputs["confirmPass"])
                    ? "contained"
                    : "disabled"}
                  type="submit">
                  Change Password
                </Button>
              </Grid>
              <br />

              {(Object.entries(passReqs).length !== 0) && (
                <Grid item xs={12}>
                  {
                    passScore.score > 0 &&
                    <Grid item xs={6}>
                      <PasswordProgressBar passScore={passScore} />
                    </Grid>
                  }
                  <div className="password-reqs">
                    <div className="error-text underline">Your password must contain:</div>
                    {passReqs.hasLowercase
                      ? <div className="pass-req">&#10003; A lowercase character</div>
                      : <div className="pass-req-not">A lowercase character</div>}
                    {passReqs.hasUppercase
                      ? <div className="pass-req">&#10003; An uppercase character</div>
                      : <div className="pass-req-not">An uppercase character</div>}
                    {passReqs.hasNumber
                      ? <div className="pass-req">&#10003; A number</div>
                      : <div className="pass-req-not">A number</div>}
                    {passReqs.hasSpecial
                      ? <div className="pass-req">&#10003; A special character ~ ` ! ? @ # $ % ^ & * , - _</div>
                      : <div className="pass-req-not">A special character ~ ` ! ? @ # $ % ^ & * , - _</div>}
                    {passReqs.hasLength
                      ? <div className="pass-req">&#10003; At least 8 characters</div>
                      : <div className="pass-req-not">At least 8 characters</div>}
                    <div className="error-text underline">Your password cannot contain:</div>
                    {passReqs.hasIllegal
                      ? <div className="pass-req-not">Illegal characters: {`/ \\ ( ) < > [ ]`} </div>
                      : <div className="pass-req"> &#10003; Illegal characters: {`/ \\ ( ) < > [ ]`}</div>}
                  </div>
                </Grid>
              )}
              {success && <div className={"success-text"}>Password changed successfully. Redirecting...</div>}
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  )
};

export default ExpiredPasswordReset;
