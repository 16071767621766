// noinspection DuplicatedCode

import {Grid, Typography} from "@mui/material";
import React from "react";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {Helmet} from "react-helmet";
import GeneticTestAddendum from "./QuestionnaireComponents/32_GeneticTestAddendum";
import useReduxFormData from "../../Hooks/useReduxFormData";
import {useValidateToken} from "../../Hooks/useValidateToken";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";

const Genetics = () => {
  useValidateToken(true, "health");
  const [dispatch, usersState, , , manifestationsState, addManifestations] = useReduxFormData();
  const {
    ids: maniIds,
    manifestationsDirty,
    manifestationsDbPostLoading,
    entities,
    manifestationsFetchLoading
  } = manifestationsState;

  let hasGenes = [];

  if (entities[161] && entities[161].prmOptVals && entities[161].prmOptVals.length > 1) {
    hasGenes = entities[161].prmOptVals.filter((gene) => gene.manifestationOptionId !== 66);
  }

  if(entities[164] && entities[164].prmOptVals && entities[164].prmOptVals.length > 0) {
    hasGenes = entities[164].prmOptVals.filter((gene) => gene.manifestationOptionId !== 66);
  }

  const {usersSelfRelationId} = usersState;
  const {handleFormNavigation} = useFormNavigation();

  const {
    handleRadioChanged,
    handleGeneTextChange,
  } = useReduxDataHandler();

  const validateInputs = () => {return true;}

  return (
    manifestationsFetchLoading
      ? undefined
      : (<>
          <Helmet>
            <title>Genetics</title>
          </Helmet>
          <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
            <Grid item>
              <Typography textAlign={"center"} variant="h2" mt={5}>
                A few more <b className="blue-emphasis">questions</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form className={"questionnaire-content"}
                    onSubmit={async (e) => {
                      e.preventDefault()
                      if (validateInputs()) {
                        if (manifestationsDirty) {
                          await addManifestations(Object.values(entities).filter((m) => m.edited === true))
                            .unwrap()
                            .then(() => {dispatch(fetchManifestations(usersSelfRelationId))})
                            .then(() => handleFormNavigation(e))
                        }
                        handleFormNavigation(e)
                      }
                    }}>
                <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                      className="questionnaire-inner-content">
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="geneticCounseling">Have you had genetic counseling?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={4472}
                             name={"geneticCounseling"}
                             opp-manifestation={4473}
                             defaultChecked={maniIds.includes(4472) || maniIds.includes("4472")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4473}
                             name={"geneticCounseling"}
                             opp-manifestation={4472}
                             defaultChecked={maniIds.includes(4473) || maniIds.includes("4473")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"} mt={3}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor={"geneticTesting"}>Have you had genetic testing?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={4470}
                             name={"geneticTesting"}
                             opp-manifestation={4471}
                             defaultChecked={maniIds.includes(4470) || maniIds.includes("4470")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4471}
                             name={"geneticTesting"}
                             opp-manifestation={4470}
                             defaultChecked={maniIds.includes(4471) || maniIds.includes("4471")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(4470) || maniIds.includes("4470")) &&
                    <>
                      <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"} mt={3}>
                        <Grid item xs={6} md={4}>
                          <label htmlFor={"positiveCancerGene"}>
                            Did your test come back positive for a cancer-causing gene?
                          </label>
                        </Grid>
                        <Grid item>
                          <input type="radio"
                                 value={161}
                                 name={"positiveCancerGene"}
                                 opp-manifestation={4457}
                                 defaultChecked={(maniIds.includes(161) && !maniIds.includes(4457)) || maniIds.includes("161")}
                                 onChange={(e) => {
                                   handleRadioChanged(e, usersSelfRelationId);
                                 }}
                          /><span>&nbsp;Yes&nbsp;</span>
                          <input type="radio"
                                 value={4457}
                                 name={"positiveCancerGene"}
                                 opp-manifestation={161}
                                 defaultChecked={maniIds.includes(4457) || maniIds.includes("4457")}
                                 onChange={(e) => {
                                   handleRadioChanged(e, usersSelfRelationId);
                                 }}
                          /><span>&nbsp;No&nbsp;</span>
                        </Grid>
                      </Grid>
                      {
                        (maniIds.includes(161) || maniIds.includes("161"))
                        && <GeneticTestAddendum isVus={false}
                                                existingGenes={hasGenes}
                                                handleInputChange={handleGeneTextChange}
                                                selfRelationId={usersSelfRelationId} />
                      }
                      {
                        (maniIds.includes(4457) || maniIds.includes("4457"))
                        && (<>
                          <Grid container item xs={12}>
                            <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                              <Grid item xs={6} md={4}>
                                <label htmlFor={"wasVUS"}>
                                  Was your result inconclusive, or a Variant of Uncertain Significance (VUS) for a
                                  cancer-causing gene?
                                </label>
                              </Grid>
                              <Grid item>
                                <input type="radio"
                                       value={164}
                                       name={"wasVUS"}
                                       opp-manifestation={4516}
                                       defaultChecked={(maniIds.includes(164) || maniIds.includes("164"))}
                                       onChange={(e) => {
                                         handleRadioChanged(e, usersSelfRelationId)
                                       }}
                                /><span>&nbsp;Yes&nbsp;</span>
                                <input type="radio"
                                       value={4516}
                                       name={"wasVUS"}
                                       opp-manifestation={164}
                                       defaultChecked={(maniIds.includes(4516) || maniIds.includes("4516"))}
                                       onChange={(e) => {
                                         handleRadioChanged(e, usersSelfRelationId)
                                       }}
                                /><span>&nbsp;No&nbsp;</span>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>)
                      }
                      {
                        (maniIds.includes(164) || maniIds.includes("164"))
                        && <GeneticTestAddendum isVus={true}
                                                existingGenes={hasGenes}
                                                handleInputChange={handleGeneTextChange}
                                                selfRelationId={usersSelfRelationId} />
                      }
                    </>
                  }
                  <BackContinueButtons loading={manifestationsDbPostLoading} />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </>
      ))
}

export default Genetics;