import {Button, Grid, Typography} from "@mui/material";
import React from "react";
import {useRouteError} from "react-router-dom";
import useExitPrompt from "../Hooks/useExitPrompt";

const RootBoundary = () => {
  const error = useRouteError();
  console.error(error);
  useExitPrompt(false);

  // if (isRouteErrorResponse(error)) {
  //   if (error.status === 404) {
  //
  //     /* invalid route (unlikely to happen, but...) TODO implement
  //     - redirect to last page */
  //
  //     return <div>This page doesn't exist!</div>;
  //   }
  //
  //   if (error.status === 401) {
  //
  //     /* token probably expired TODO implement
  //     - redirect to login
  //     - todo [future]: refresh token implementation on API */
  //
  //     return <div>You aren't authorized to see this</div>;
  //   }
  //
  //   if (error.status === 503) {
  //
  //     /* retry last API call - TODO implement
  //    will integrate with full apiSlice implementation */
  //
  //     return <div>Looks like our API is down</div>;
  //   }
  //
  //   if (error.status === 418) {
  //
  //     /* server refused to brew coffee
  //     - get better server */
  //
  //     return <div>🫖</div>;
  //   }
  // }


  /*
  * generic error
    - TODO implement
  * */

  return (
    <Grid container flexDirection={"column"} item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
      <Grid item sx={{width: "80%"}} ml={"auto"} mr={"auto"}>
        <Typography textAlign={"center"} xs={8} ml={"auto"} mr={"auto"}>
          An unexpected error has occurred.<br /> Please refresh using your browser's button, or, click the button
          below.
        </Typography>
      </Grid>
      <Grid container mt={5} justifyContent="center" className="questionnaire-footer">
        <Grid item xs={"auto"}>
          <Button variant={"contained"}
                  onClick={() => window.location.reload()}>
            Reload
          </Button>
        </Grid>
      </Grid>
    </Grid>);
}

export default RootBoundary;