import {useState, useEffect} from "react";

const initBeforeUnload = (showExitPrompt) => {
  window.onbeforeunload = (e) => {
    if (showExitPrompt) {
      e.preventDefault();
      if (e) {
        e.returnValue = ""; // Legacy method for cross browser support
      }
      return ""; // Legacy method for cross browser support
    }
  }
}

// Quick fix to prevent patient data loss - prompts user to confirm page refresh
// Should make it better later so they can refresh and inputted data is preserved until they leave
// The hooook briiings you baaack
export default function useExitPrompt(bool) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);
  
  window.onload = () => {
    initBeforeUnload(showExitPrompt);
  }
  
  useEffect(() => {
    initBeforeUnload(showExitPrompt);
  }, [showExitPrompt]);
  
  return [showExitPrompt, setShowExitPrompt];
}