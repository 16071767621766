import React from "react";
import {OtherHistProvider} from "./Context/100_Other";
import {OtherHealthHistoryForm} from "./101_OtherHealthHistoryForm";

const OtherHealthHistory = () => {
  return (
    <OtherHistProvider>
      <OtherHealthHistoryForm />
    </OtherHistProvider>
  );
}

export default OtherHealthHistory;