//Libraries
import React from "react";
import {Helmet} from "react-helmet";
import {NavLink, useNavigate} from "react-router-dom";

// Components, stores, styles
import NavigationCardWide from "../Components/NavigationCardWide";
import "../Styles/Dashboard.css";
import {fetchUserData, startHealthDiary, updateUserLocation} from "../Redux/users/userSlice";
import {Container, Grid, Stack, Link, Button} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import FamilyIcon from '@mui/icons-material/Groups';
import HeartIcon from '@mui/icons-material/Favorite';

//Temp
import {useDispatch} from "react-redux";
import useExitPrompt from "../Hooks/useExitPrompt";

import {useValidateToken} from "../Hooks/useValidateToken";

const Dashboard = () => {
  const navigate = useNavigate();
  const [, , usersPatientId, , usersFetchLoading, entity, ids] = useValidateToken(false, "home");
  const dispatch = useDispatch();
  const [,] = useExitPrompt(false);

  const {
    userFirst: userFirstName,
    questionnaireReturnLater,
    passwordExpired,
    usersGender,
  } = ((entity && usersPatientId) && entity[usersPatientId]) ? entity[usersPatientId] : {
    firstName: "",
    questionnaireReturnLater: false,
    passwordExpired: false,
  };

  const showReturnToPage = !!questionnaireReturnLater;

  const beginHealthDiaryContent = "Did you know that whether there is cancer in your family or not, everyone can benefit from a personalized\
      prevention and early detection strategy? Learn what steps you can take now to reduce your risk of cancer.";

  const beginHealthDiaryContentTitle = "There is power in knowing";

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleBegin = () => {
    dispatch(startHealthDiary())
    dispatch(updateUserLocation("health-questionnaire", "basic"));
  }

  React.useEffect(() => {
    const getData = async () => {
      if ((ids.length === 0) && (!usersFetchLoading)) {
        dispatch(fetchUserData(usersPatientId));
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    getData();
    dispatch(updateUserLocation("home", "home"))
    scrollToTop();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (passwordExpired) {
      navigate("/expired-password")
    }
  }, [passwordExpired])

  return usersFetchLoading
    ? undefined
    : (<>
        <Helmet>
          <title>INHERET® Home</title>
        </Helmet>
        <NavigationCardWide
          title={beginHealthDiaryContentTitle}
          content={beginHealthDiaryContent}
          imageType={"diary"}
        />
        <Container sx={{mt: 5}}>
          <h2>Health Diary</h2>
          {usersFetchLoading
            ? undefined
            : <Grid container className="card-wrapper-dashboard">
              {(!usersFetchLoading && (showReturnToPage === false)) ?
                (<>
                    <Grid item xs={12} textAlign={"center"}>
                      <p className="blue-text">
                        When completing your diary, it is important to answer each question to the best of your ability.
                        If you are unsure of an answer, you can log out of the diary and log back in where you left off
                        at
                        any time.
                        We encourage you to speak with your family when answering these questions, as they may have more
                        information.
                      </p>
                      <p className="blue-text">
                        This is <b>Your</b> Health Diary, where the health information for both you and your family is
                        stored in one place and is accessible at any time.
                      </p>
                      <p className="blue-text">
                        Your answers will help your doctor personalize and customize your care to ensure the prevention
                        and early detection of cancer.
                      </p>
                      <p className="blue-text">
                        Keep your information up to date, whether it’s a day, a month, or a year from now - your
                        provider
                        will be alerted in real-time. Changes to your care plan will be made if needed.
                      </p>
                    </Grid>
                    <Grid container item justifyContent={"center"}>
                      <NavLink as={Link} style={{textDecoration: "none"}}
                               to="/health-questionnaire"
                               className="center-link-router">
                        <Button variant={"contained"}
                                onClick={handleBegin}>Let's get started
                        </Button>
                      </NavLink>
                    </Grid>
                  </>
                ) : (
                  <Grid container>
                    <Grid item xs={12} mb={5} textAlign={"center"}>
                      <h2>Welcome back, <b className="blue-emphasis">{userFirstName}</b>!</h2>
                      <h2>What would you like to update today?</h2>
                    </Grid>
                    <Grid container mb={5} rowSpacing={1} columnSpacing={2}>
                      <Grid item xs={4} textAlign={"center"}>
                        <h3><PersonIcon fontSize={"large"} /> My Basics</h3>
                        <Stack mt={2} spacing={2}>
                          <NavLink as={Link} variant={"dashboard"}
                                   to="/health-questionnaire/basic-info"
                                   onClick={handleBegin}>
                            Basic Information
                          </NavLink>
                          <NavLink as={Link} variant={"dashboard"}
                                   to="/health-questionnaire/basic-ancestry"
                                   onClick={handleBegin}
                          >Ancestry</NavLink>
                          <NavLink as={Link} variant={"dashboard"}
                                   to="/health-questionnaire/lifestyle-htwt"
                                   onClick={handleBegin}
                          >Height and Weight</NavLink>
                          <NavLink as={Link} variant={"dashboard"}
                                   to="/health-questionnaire/lifestyle-smoking"
                                   onClick={handleBegin}
                          >Smoking</NavLink>
                          <NavLink as={Link} variant={"dashboard"}
                                   to="/health-questionnaire/lifestyle-alcohol"
                                   onClick={handleBegin}
                          >Alcohol</NavLink>
                        </Stack>
                      </Grid>
                      <Grid item xs={4} textAlign={"center"}>
                        <h3><HeartIcon fontSize={"large"} /> My Health</h3>
                        <Stack mt={2} spacing={2}>
                          {(usersGender && usersGender !== "Male") &&
                            <NavLink as={Link} variant={"dashboard"}
                                     to="/health-questionnaire/reproductive-history"
                                     onClick={handleBegin}
                            >Reproductive History</NavLink>}
                          <NavLink as={Link} variant={"dashboard"}
                                   to="/health-questionnaire/genetics"
                                   onClick={handleBegin}
                          >Genetics Testing</NavLink>
                          <NavLink as={Link} variant={"dashboard"}
                                   to="/health-questionnaire/bc-screenings"
                                   onClick={handleBegin}
                          >Cancer Screenings</NavLink>
                          <NavLink as={Link} variant={"dashboard"}
                                   to="/health-questionnaire/biopsy-history"
                                   onClick={handleBegin}
                          >Biopsy History</NavLink>
                          <NavLink as={Link} variant={"dashboard"}
                                   to="/health-questionnaire/cancer-history"
                                   onClick={handleBegin}
                          >Cancer History</NavLink>
                          <NavLink as={Link} variant={"dashboard"}
                                   to="/health-questionnaire/other-history"
                                   onClick={handleBegin}
                          >Other History</NavLink>
                        </Stack>
                      </Grid>
                      <Grid item xs={4} textAlign={"center"}>
                        <h3><FamilyIcon fontSize={"large"} /> My Family</h3>
                        <Stack mt={2} spacing={2}>
                          <NavLink as={Link} variant={"dashboard"}
                                   to="/health-questionnaire/relations-histories"
                                   onClick={handleBegin}
                          >Your Family's Health</NavLink>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container item spacing={4} xs={12}>
                      {/*TODO: maybe add a key to user object from API that checks if they have submitted before and adjust this page accordingly*/}
                      <Grid container item xs={12} md={6} justifyContent={"center"}>
                        <NavLink as={Link} style={{textDecoration: "none"}}
                                 to="/health-questionnaire/basic-info">
                          <Button variant={"contained"}
                                  onClick={handleBegin}>
                            Start from the beginning
                          </Button>
                        </NavLink>
                      </Grid>
                      <Grid container item xs={12} md={6} justifyContent={"center"}>
                        <NavLink as={Link} style={{textDecoration: "none"}}
                                 to={"/health-questionnaire/" + questionnaireReturnLater}>
                          {(questionnaireReturnLater !== "basic-info") &&
                            <Button variant={"contained"}
                                    onClick={handleBegin}>
                              Continue from where you left off
                            </Button>}
                        </NavLink>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          }      </Container>
      </>
    );
};

export default Dashboard;
