import {useDispatch} from "react-redux";
import {
  relationsGeneticsAddEdit,
  relationsMultiManiColData,
  relationsMultiManiEdit
} from "../Redux/relations/relationSlice";

export const useRelationModalChangeHandlers = () => {
  const dispatch = useDispatch();

  const handleRelationColDataChange = (e, relationReactId) => {
    const array = e.target.name.split("-");
    const manifestationReactId = array[0];
    const colName = array[1];
    let colValue = e.target.value;

    if (colName === "ageOfDiagnosis" || colName === "quantity") {
      colValue = parseInt(colValue);
      dispatch(relationsMultiManiColData(relationReactId, manifestationReactId, colName, colValue))
    }
    if (colName === "confidenceInAgeOfDiagnosis") {
      dispatch(relationsMultiManiColData(relationReactId, manifestationReactId, colName, colValue))
    }
  }

  const handleRelationCancerResultChange = (e, relationReactId) => {
    const array = e.target.name.split("-");
    const manifestationReactId = array[0];
    const optId = parseInt([array[1]]);
    const maniOptValId = parseInt(e.target.value);
    dispatch(relationsMultiManiEdit(relationReactId, manifestationReactId, optId, maniOptValId));
  }

  const handleRelationGeneChange = (e, relationReactId, manifestationReactId, geneOptValReactId) => {
    const getSelectedValue = (e) => {
      for (let i = 0; i < e.target.list.options.length; i++) {
        if (e.target.list.options[i].value === e.target.value) {
          return e.target.list.options[i].getAttribute("data-opt-val");
        }
      }
    }
    const optValId = parseInt(getSelectedValue(e));
    const optId = 38;
    dispatch(relationsGeneticsAddEdit(relationReactId, manifestationReactId, geneOptValReactId, optId, optValId));
  }

  return {
    handleRelationColDataChange,
    handleRelationCancerResultChange,
    handleRelationGeneChange,
  }
}