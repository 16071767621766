export const validatePasswordComplexity = pass => {
  let score = 0;
  if (!pass) {
    return score;
  }

  const letters = {};
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  let variationCount = 0;
  for (let check in variations) {
    variationCount += (variations[check] === true) ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  return score;
};

export const scorePassword = score => {
  if (score >= 90) {
    if (score > 100) score = 100;
    return {text: "Very Strong", score: score, color: "#5CB85C"};
  } else if (score >= 80) {
    return {text: "Strong", score: score, color: "#5BC0DE"};
  } else if (score >= 60) {
    return {text: "Medium", score: score, color: "#F0AD4E"};
  } else {
    if (score < 30) score = 30;
    return {text: "Weak", score: score, color: "#DD6216"};
  }
}

export const validatePassReqs = (pass) => {
  let requirements = {};
  let meetsAll = true;

  if (!/[a-z]/.test(pass)) {
    requirements.hasLowercase = false;
    meetsAll = false;
  } else {
    requirements.hasLowercase = true;
  }

  if (!/[A-Z]/.test(pass)) {
    requirements.hasUppercase = false;
    meetsAll = false;
  } else {
    requirements.hasUppercase = true;
  }

  if (!/[0-9]/.test(pass)) {
    requirements.hasNumber = false;
    meetsAll = false;
  } else {
    requirements.hasNumber = true;
  }

  if (!/[~`!?\-@#$%^&*,_]/.test(pass)) {
    requirements.hasSpecial = false;
    meetsAll = false;
  } else {
    requirements.hasSpecial = true;
  }

  if (pass.length < 8) {
    requirements.hasLength = false;
    meetsAll = false;
  } else {
    requirements.hasLength = true;
  }

  if (/[/\\\[\]()<>]/.test(pass)) {
    requirements.hasIllegal = true;
    meetsAll = false;
  } else {
    requirements.hasIllegal = false;
  }
  return {meetsAll, requirements};
}

// load-bearing comment