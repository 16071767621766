import {Button, FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {relationsGeneInitialAdd, relationsGeneticsAddEdit} from "../../../Redux/relations/relationSlice";
import {RelationGenes} from "../Context/TreeList_GeneContext";
import {ReactComponent as AddIcon} from "../../../Resources/Images/Icons/addButtonSmall.svg";
import {randomId} from "../../../Helpers/utils";
import {GeneInput} from "./treeList_GeneFormRow";

export const RelationGeneForm = (props) => {
  const relationGene = useContext(RelationGenes);
  const {genes, setGenes, baseManifestationReactId, setBaseManifestationReactId, addGene, deleteGene} = relationGene;
  const {relation, patientRelationId, relationReactId, existingKgmMani, isDeceased} = props;
  const [cancerousOptValReactId, setCancerousOptValReactId] = useState("");
  const [wasCancerous, setWasCancerous] = useState(undefined);
  const [initialAdd, setInitialAdd] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!existingKgmMani) {
      setBaseManifestationReactId(randomId() + randomId());
    } else {
      setGenes(existingKgmMani.prmOptVals);
      setBaseManifestationReactId(existingKgmMani.reactId);
    }
    if ((genes && genes.length > 1)) {
      genes.forEach((optVal) => {
        if (optVal.manifestationOptionId === 66) {
          setCancerousOptValReactId(optVal.reactId);
          if (optVal.manifestationOptionValueId === 577) {
            setWasCancerous(true);
          }
          if (optVal.manifestationOptionValueId === 578) {
            setWasCancerous(false);
          }
        }
      });
    }
  }, [])

  if (genes && genes.length > 1) {
    if (cancerousOptValReactId === "") {
      genes.forEach((optVal) => {
        if (optVal.manifestationOptionId === 66) {
          setCancerousOptValReactId(optVal.reactId);
          if (optVal.manifestationOptionValueId === 577) {
            setWasCancerous(true);
            setInitialAdd(false);
          }
          if (optVal.manifestationOptionValueId === 578) {
            setWasCancerous(false);
            setInitialAdd(false);
          }
        }
      });
    }
  }

  return (<>
      <Grid container mt={5}>
        <Grid item xs={12} sm={8}>
          <FormLabel sx={{color: "#000000"}}>
            {isDeceased ? "Was " : "Has "}
            {`your ${relation.toLowerCase()} `}
            {isDeceased ? " ever " : " ever been "}
            diagnosed with a gene mutation?</FormLabel>
          <Grid item xs={"auto"} ml={1} mt={1}>
            <Button variant={"relation-add"}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!existingKgmMani && genes.length === 0) {
                        setInitialAdd(true);
                        const newId = randomId() + randomId();
                        dispatch(relationsGeneInitialAdd(patientRelationId, relationReactId, baseManifestationReactId, newId));
                        setCancerousOptValReactId(newId);
                        setGenes([{reactId: newId, existedInDb: false, manifestationOptionId: 66}]);
                      } else {
                        addGene(relationReactId, true);
                      }
                    }}>
              <AddIcon style={{width: "16px", height: "16px"}} />&nbsp;Add gene mutation
            </Button>
          </Grid>
        </Grid>
        {genes && genes.length > 0
          ?
          <Grid container item xs={12} mt={1.5}>
            <Grid item xs={12} sm={6}>
              <FormLabel id={`${relation}-cancer-gene-radio`} sx={{color: "#000000"}}>
                Is this for a cancer risk gene?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby={`${relation}-cancer-gene-radio`}
                name={`${relation}-cancer-gene-radio`}>
                <FormControlLabel
                  sx={{color: "#000000"}}
                  label="Yes"
                  control={<Radio
                    name="mutationWasCancerous"
                    id={"577"}
                    checked={initialAdd ? false : wasCancerous}
                    onChange={(e) => {
                      if (initialAdd) {
                        setInitialAdd(false);
                      }
                      setWasCancerous(true);
                      dispatch(relationsGeneticsAddEdit(relationReactId, baseManifestationReactId, cancerousOptValReactId, 66, parseInt(e.target.id)));
                      if (genes.length === 1) {
                        addGene(relationReactId, true);
                      }
                    }} />}
                />
                <FormControlLabel
                  sx={{color: "#000000"}}
                  label="No"
                  control={<Radio
                    name="mutationWasCancerous"
                    id={"578"}
                    checked={initialAdd ? false : !wasCancerous}
                    onChange={(e) => {
                      if (initialAdd) {
                        setInitialAdd(false);
                      }
                      setWasCancerous(false);
                      dispatch(relationsGeneticsAddEdit(relationReactId, baseManifestationReactId, cancerousOptValReactId, 66, parseInt(e.target.id)));
                      if (genes.length === 1) {
                        addGene(relationReactId, true);
                      }
                    }} />}
                />
              </RadioGroup>
            </Grid>
          </Grid>
          : undefined}
        {
          (genes && genes.length > 0) ?
            (genes.map((gene, index) => {
              if (gene.manifestationOptionId !== 66) {
                return (
                  <Grid container item xs={"auto"}
                        key={index}>
                    <GeneInput gene={gene}
                               index={index}
                               patientRelationId={patientRelationId}
                               relationReactId={relationReactId}
                               kgmManiReactId={baseManifestationReactId}
                               deleteGene={deleteGene} />
                  </Grid>)
              }
            })) : undefined
        }
      </Grid>
    </>
  )
}