import {Grid, Typography} from "@mui/material";
import React from "react";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {Helmet} from "react-helmet";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import useReduxFormData from "../../Hooks/useReduxFormData";
import {useValidateToken} from "../../Hooks/useValidateToken";

const BasicAncestry = () => {
  useValidateToken(true, "basic");
  const [dispatch, usersState, , , manifestationsState, addManifestations] = useReduxFormData();
  const {
    ids: maniIds,
    manifestationsDirty,
    manifestationsDbPostLoading,
    entities,
    manifestationsFetchLoading
  } = manifestationsState;
  const {usersSelfRelationId} = usersState;
  const {handleFormNavigation} = useFormNavigation();

  const {
    handleRadioChanged,
  } = useReduxDataHandler();

  const validateInputs = () => {return true};

  return (
    manifestationsFetchLoading
      ? undefined
      : (<>
        <Helmet>
          <title>Basic Ancestry</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
          <Grid item xs={8}>
            <Typography textAlign={"center"} variant="h2" mt={5}> Let's continue with the <b
              className="blue-emphasis">basics</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className={"questionnaire-content"}
                  onSubmit={async (e) => {
                    e.preventDefault()
                    if (validateInputs()) {
                      if (manifestationsDirty) {
                        await addManifestations(Object.values(entities))
                          .unwrap()
                          .then(() => {dispatch(fetchManifestations(usersSelfRelationId))})
                          .then(() => handleFormNavigation(e))
                      }
                      handleFormNavigation(e)
                    }
                  }}>
              <Grid spacing={2} container item justifyContent={"center"} xs={12} ml={"auto"} mr={"auto"}
                    className="questionnaire-inner-content">
                <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                  <Grid item xs={6} md={4}>
                    <label htmlFor="Ashkenazi">Are you from an Ashkenazi Jewish Family (not your current
                      religion)?</label>
                  </Grid>
                  <Grid item>
                    <input type="radio"
                           value={160}
                           name={"Ashkenazi Jewish"}
                           opp-manifestation={4458}
                           defaultChecked={maniIds.includes(160) || maniIds.includes("160")}
                           onChange={(e) => {
                             handleRadioChanged(e, usersSelfRelationId);
                           }} /><span className={"radio-span"}>&nbsp;Yes&nbsp;</span>
                    <input type="radio"
                           value={4458}
                           name={"Ashkenazi Jewish"}
                           opp-manifestation={160}
                           defaultChecked={maniIds.includes(4458) || maniIds.includes("4458")}
                           onChange={(e) => {
                             handleRadioChanged(e, usersSelfRelationId);
                           }} /><span className={"radio-span"}>&nbsp;No&nbsp;</span>
                  </Grid>
                </Grid>
                <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"} xs={12} mt={5}>
                  <Grid item xs={6} md={4}>
                    <label htmlFor="Maori">Are you or any of your family of Maori Descent?</label>
                  </Grid>
                  <Grid item>
                    <input type="radio"
                           value={1437}
                           name={"Maori"}
                           opp-manifestation={4459}
                           defaultChecked={maniIds.includes(1437) || maniIds.includes("1437")}
                           onChange={(e) => {
                             handleRadioChanged(e, usersSelfRelationId);
                           }} /><span className={"radio-span"}>&nbsp;Yes&nbsp;</span>
                    <input type="radio"
                           value={4459}
                           name={"Maori"}
                           opp-manifestation={1437}
                           defaultChecked={maniIds.includes(4459) || maniIds.includes("4459")}
                           onChange={(e) => {
                             handleRadioChanged(e, usersSelfRelationId);
                           }} /><span className={"radio-span"}>&nbsp;No&nbsp;</span>
                  </Grid>
                </Grid>
                <BackContinueButtons loading={manifestationsDbPostLoading} />
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>)
  )
}

export default BasicAncestry;