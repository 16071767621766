import {Button, FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@mui/material";
import {useState, useEffect, useContext} from "react";
import {useDispatch} from "react-redux";
import {randomId} from "../../../Helpers/utils";
import {relationsManifestationDelete, relationsMultiManiAddEdit} from "../../../Redux/relations/relationSlice";
import {RelationAncestry} from "../Context/TreeList_AncestryContext";
import {ReactComponent as AddIcon} from "../../../Resources/Images/Icons/addButtonSmall.svg";
import {RelationAncestryFormRow} from "./treeList_AncestryFormRow";

export const RelationAncestryForm = (props) => {
  const dispatch = useDispatch();
  const relationAncestry = useContext(RelationAncestry);
  const {
    ancestry,
    setAncestry,
    addAncestry,
    deleteAncestry
  } = relationAncestry;

  const {relation, relationReactId, patientRelationId, existing} = props;

  const [knownAncestry, setKnownAncestry] = useState({});
  const [showAncestry, setShowAncestry] = useState(false);

  useEffect(() => {
    if (existing.length > 0) {
      let unknown = false;
      existing.forEach((ancestry) => {
        if (ancestry.manifestationId === 230) {
          setKnownAncestry({known: false, unknownAncestryId: ancestry.reactId ? ancestry.reactId : undefined});
          unknown = true;
        }
      })
      if (existing.length > 0 && !unknown) {
        setKnownAncestry({known: true})
        setShowAncestry(true);
      }
      setAncestry(existing);
      if (knownAncestry.known) {
        setShowAncestry(true);
      }
    }
  }, [])

  return (
    <>
      <Grid container mt={5}>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={6}>
            <FormLabel id={`${relation}-ancestry-radio`} sx={{color: "#000000"}}>
              Do you know your {relation.toLowerCase() + "'s "} family ancestry?
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby={`${relation}-ancestry-radio`}
              name={`${relation}-known-ancestry`}>
              <FormControlLabel
                sx={{color: "#000000"}}
                value={"true"}
                label="Yes"
                control={<Radio
                  name="knownAncestry"
                  checked={knownAncestry.known}
                  onChange={() => {
                    setKnownAncestry({...knownAncestry, known: true});
                    if (!showAncestry) {
                      setShowAncestry(true)
                    }
                    if (ancestry.length >= 1) {
                      ancestry.forEach((ancestry) => {
                        if (ancestry.manifestationId === 230) {
                          dispatch(relationsManifestationDelete(relationReactId, ancestry.prmId, ancestry.reactId, ancestry.existedInDb));
                        }
                      })
                    }
                    if (ancestry.length === 0) {
                      addAncestry(patientRelationId);
                    }
                  }} />} />
              <FormControlLabel
                sx={{color: "#000000"}}
                value={"false"}
                label={"No"}
                control={<Radio
                  name="knownAncestry"
                  checked={(!knownAncestry.known && knownAncestry.unknownAncestryId !== undefined)}
                  onChange={() => {
                    let newUnknown;
                    if (!knownAncestry.unknownAncestryId) {
                      newUnknown = randomId() + randomId();
                    }
                    if (knownAncestry.unknownAncestryId) {
                      newUnknown = knownAncestry.unknownAncestryId;
                    }
                    setKnownAncestry({
                      known: false,
                      unknownAncestryId: newUnknown,
                    });
                    setShowAncestry(false);
                    if (ancestry.length > 0) {
                      ancestry.forEach((ancestry) => {
                        deleteAncestry(ancestry.reactId, relationReactId);
                      })
                    }
                    setAncestry([
                      {
                        manifestationId: 230,
                        reactId: newUnknown,
                        quantity: 1,
                        existedInDb: false,
                      }
                    ])
                    dispatch(relationsMultiManiAddEdit(relationReactId, {
                      reactId: newUnknown,
                      quantity: 1,
                    }, 230));
                  }} />} />
            </RadioGroup>
          </Grid>
          {
            (showAncestry && ancestry.length > 0)
              ? (
                <>
                  <Grid item xs={"auto"} ml={1} mt={1}>
                    <Button variant={"relation-add"}
                            onClick={(e) => {
                              e.preventDefault();
                              addAncestry(patientRelationId);
                            }}>
                      <AddIcon style={{width: "16px", height: "16px"}} />&nbsp;Add ancestry
                    </Button>
                  </Grid>
                </>)
              : undefined
          }
        </Grid>

      </Grid>
      <Grid rowSpacing={1} columnSpacing={2} container item xs={12} mt={2}>
        {
          (showAncestry && ancestry.length > 0)
            ? ancestry.map((item) => {
              return (
                <RelationAncestryFormRow
                  key={item.reactId}
                  relationReactId={relationReactId}
                  ancestry={item}
                  setAncestry={setAncestry}
                  deleteAncestry={deleteAncestry} />
              )
            })
            : undefined
        }
      </Grid>
    </>
  )
}