// noinspection DuplicatedCode

import {Grid, Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import React from "react";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";
import {DefaultInputValGetters} from "./Helpers/defaultInputValGetters";
import InputValLimiters from "./Helpers/inputValLimiters";
import useReduxFormData from "../../Hooks/useReduxFormData";
import {useValidateToken} from "../../Hooks/useValidateToken";

const ReproductiveHistoryHrt = () => {
  useValidateToken(true, "health");
  const [dispatch, usersState, , , manifestationsState, addManifestations] = useReduxFormData();
  const {
    ids: maniIds,
    manifestationsDirty,
    manifestationsDbPostLoading,
    entities,
    manifestationsFetchLoading
  } = manifestationsState;
  const {usersSelfRelationId} = usersState;
  const {handleFormNavigation} = useFormNavigation();

  const {
    handleOptValInputChange,
    handleRadioChanged,
    handleInputChange,
  } = useReduxDataHandler();

  const {getDefaultNormalFieldValue, checkForMoreValues} = DefaultInputValGetters();

  const validateInputs = () => {return true;}

  return (
    manifestationsFetchLoading
      ? undefined
      : (<>
        <Helmet>
          <title>Reproductive History</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
          <Grid item>
            <Typography textAlign={"center"} variant="h2" mt={5}>Wrapping up your <b className="blue-emphasis">reproductive
              history</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className={"questionnaire-content"}
                  onSubmit={async (e) => {
                    e.preventDefault()
                    if (validateInputs()) {
                      if (manifestationsDirty) {
                        await addManifestations(Object.values(entities).filter((m) => m.edited === true))
                          .unwrap()
                          .then(() => {dispatch(fetchManifestations(usersSelfRelationId))})
                          .then(() => handleFormNavigation(e))
                      }
                      handleFormNavigation(e)
                    }
                  }}>
              <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                    className="questionnaire-inner-content">
                <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                  <Grid item xs={6} md={4}>
                    <label htmlFor="usedHrt">Have you ever used Hormone Replacement Therapy? (not birth
                      control)</label>
                  </Grid>
                  <Grid item>
                    <input type="radio"
                           value={4469}
                           name={"usedHrt"}
                           opp-manifestation={4468}
                           defaultChecked={maniIds.includes(4469) || maniIds.includes("4469")}
                           onChange={(e) => {
                             handleRadioChanged(e, usersSelfRelationId)
                           }}
                    /><span>&nbsp;Yes&nbsp;</span>
                    <input type="radio"
                           value={4468}
                           name={"usedHrt"}
                           opp-manifestation={4469}
                           defaultChecked={maniIds.includes(4468) || maniIds.includes("4468")}
                           onChange={(e) => {
                             handleRadioChanged(e, usersSelfRelationId)
                           }}
                    /><span>&nbsp;No&nbsp;</span>
                  </Grid>
                </Grid>
                {
                  (maniIds.includes(4469) || maniIds.includes("4469")) &&
                  <Grid container item justifyContent={{sm: "center"}} xs={12} mt={3}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="howLongAgoHrt">When was the last time you used hormone replacement
                        therapy?</label>
                    </Grid>
                    <Grid item xs={2} md={1}>
                      <select className="dropdown-input"
                              name="howLongAgoHrt"
                              manioptid={2083}
                              defaultValue={getDefaultNormalFieldValue(entities, 4469, 2083)}
                              opts-for={4469}
                              onChange={(e) => {
                                handleOptValInputChange(e, false, false, manifestationsState);
                              }}>
                        <option value={null}>Select an option</option>
                        <option value={2618}>5 or more years ago</option>
                        <option value={2619}>Less than 5 years ago</option>
                        <option value={2620}>Current user</option>
                      </select>
                    </Grid>
                  </Grid>
                }
                {
                  checkForMoreValues(entities, 4469, 2083, 2619) &&
                  <Grid container item flexDirection={"row"} xs={12} sm={8} md={6} lg={5} mt={3}>
                    <Grid container item flexDirection={"column"} xs={6} md={4}>
                      <Grid item>
                        <label className={"sublabel"}
                               htmlFor={"lengthOfPastHrtUse"}>Length of use (years)</label>
                      </Grid>
                      <Grid item>
                        <input type={"text"}
                               className={"text-input-small"}
                               placeholder={"Years used"}
                               name={"lengthOfPastHrtUse"}
                               col-for={4469}
                               col-name={"ageOfDiagnosis"}
                               defaultValue={entities[4469].ageOfDiagnosis || null}
                               inputMode={"numeric"}
                               pattern={"[0-9]*"}
                               title={"Numbers only"}
                               onChange={(e) => {
                                 handleInputChange(e, manifestationsState, true)
                               }} />
                      </Grid>
                    </Grid>
                    <Grid container item flexDirection={"column"} xs={6} md={4}>
                      <Grid item>
                        <label className={"sublabel"}
                               htmlFor={"TypeOfHrt"}>Type of HRT</label>
                      </Grid>
                      <Grid item>
                        <select className={"dropdown-input"}
                                name={"TypeOfHrt"}
                                opts-for={4469}
                                manioptid={2084}
                                defaultValue={getDefaultNormalFieldValue(entities, 4469, 2084)}
                                onChange={(e) => {
                                  handleOptValInputChange(e, false, false, manifestationsState);
                                }}>
                          <option value={null}>Please select</option>
                          <option value={2621}>Oestrogen only</option>
                          <option value={2622}>Combined</option>
                        </select>
                      </Grid>
                    </Grid>
                    <Grid container item flexDirection={"column"} xs={6} md={4}>
                      <Grid item>
                        <label className={"sublabel"}
                               htmlFor={"HowLongPlannedHrt"}>How long ago was HRT used?</label><br />
                      </Grid>
                      <Grid item>
                        <input
                          type={"text"}
                          className={"text-input-small"}
                          placeholder={"Years ago"}
                          name={"HowLongPlannedHrt"}
                          col-for={4469}
                          col-name={"quantity"}
                          defaultValue={entities[4469].quantity || null}
                          inputMode={"numeric"}
                          pattern={"[0-9]*"}
                          title={"Numbers only"}
                          onChange={(e) => {
                            if (parseInt(e.target.value) >= 5) {
                              e.target.value = "5";
                            }
                            handleInputChange(e, manifestationsState, true)
                          }} />
                      </Grid>
                    </Grid>
                  </Grid>
                }
                {
                  checkForMoreValues(entities, 4469, 2083, 2620) &&
                  <Grid container item flexDirection={"row"} xs={12} sm={8} md={6} lg={5} mt={3}>
                    <Grid container item flexDirection={"column"} xs={6} md={4}>
                      <Grid item>
                        <label className={"sublabel"}
                               htmlFor={"lengthOfCurrHrtUse"}>Length of use (years)</label>
                      </Grid>
                      <Grid item>
                        <input type={"text"}
                               className={"text-input-small"}
                               placeholder={"Years used"}
                               name={"lengthOfCurrHrtUse"}
                               col-for={4469}
                               col-name={"ageOfDiagnosis"}
                               defaultValue={entities[4469].ageOfDiagnosis || null}
                               inputMode={"numeric"}
                               pattern={"[0-9]*"}
                               title={"Numbers only"}
                               onChange={(e) => {
                                 handleInputChange(e, manifestationsState, true)
                               }} />
                      </Grid>
                    </Grid>
                    <Grid container item flexDirection={"column"} xs={6} md={4}>
                      <Grid item>
                        <label className={"sublabel"}
                               htmlFor={"TypeOfHrt"}>Type of HRT</label>
                      </Grid>
                      <Grid item>
                        <select className={"dropdown-input"}
                                name={"TypeOfHrt"}
                                opts-for={4469}
                                manioptid={2084}
                                defaultValue={getDefaultNormalFieldValue(entities, 4469, 2084)}
                                onChange={(e) => {
                                  handleOptValInputChange(e, false, false, manifestationsState);
                                }}>
                          <option value={null}>Please select</option>
                          <option value={2621}>Oestrogen only</option>
                          <option value={2622}>Combined</option>
                        </select>
                      </Grid>
                    </Grid>
                    <Grid container item flexDirection={"column"} xs={6} md={4}>
                      <Grid item>
                        <label className={"sublabel"}
                               htmlFor={"HowLongPlannedHrt"}>Intended length of use (years)?</label>
                      </Grid>
                      <Grid item>
                        <input type={"text"}
                               className={"text-input-small"}
                               placeholder={"How long do you plan to use HRT?"}
                               name={"HowLongPlannedHrt"}
                               col-for={4469}
                               col-name={"quantity"}
                               defaultValue={entities[4469].quantity || null}
                               inputMode={"numeric"}
                               pattern={"[0-9]*"}
                               title={"Numbers only"}
                               onChange={(e) => {
                                 handleInputChange(e, manifestationsState, true)
                               }} />
                      </Grid>
                    </Grid>
                  </Grid>
                }
                <BackContinueButtons loading={manifestationsDbPostLoading} />
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>))
};

export default ReproductiveHistoryHrt;