import Lottie from "lottie-react";
import dnaLoading from "../Resources/LottieFiles/dna-loading.json";
import "../Styles/Components/LoadingSpinner.css";
import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner show">
      <Lottie speed="0.01" className="lottie-spinner" animationData={dnaLoading} loop={true} autoplay={true}
              initialSegment={[0, 151]}/>
    </div>
  );
}

export default LoadingSpinner;