import {
  Button,
  Grid,
  InputLabel,
  TextField
} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import React from "react";
import "../../../Styles/Components/RelationModal.css"
import {useModalFormChangeHandlers} from "../../../Hooks/useModalFormChangeHandlers";
import {useDispatch, useSelector} from "react-redux";
import {
  editRelation,
  editRelationsInDb,
  fetchRelations
} from "../../../Redux/relations/relationSlice";
import {OtherHistory} from "../Context/TreeList_OtherContextWrapper";
import {GeneHistory} from "../Context/TreeList_GeneContextWrapper";
import {CancerHistory} from "../Context/TreeList_CancerContextWrapper";
import {AncestryHistory} from "../Context/TreeList_AncestryContextWrapper";
import MultiBirthOptions from "./multiBirthOptions";

const RelationEdit = (props) => {
  const {
    handleAgeChange,
    handleMuiDateChange,
    handleNameChange,
    handleDeceased,
    handleCauseOfDeath,
  } = useModalFormChangeHandlers();
  const dispatch = useDispatch();

  const {
    reactKey,
    manifestations,
    handleExpansion,
    relationLabel,
    sideLabel,
    relationGender,
    multiBirthSelection,
  } = props;

  // All of this desperately needs refactored, but it works for now,
  // so I'm leaving it alone. Ain't got time for that.
  const {relationsDbPosting, relationsNewestMultiBirthId, entities} = useSelector(state => state.relations);
  const dynamicData = useSelector(state => state.relations.entities[reactKey]);
  const {
    name,
    deceased,
    ageAtDeath,
    ageCurrent,
    cancer,
    causeOfDeath,
    dob,
    relationManis,
    id: prId,
    relationId,
    multiBirthId,
    multiBirthType,
  } = dynamicData;

  const [relationState, setRelationState] = React.useState({
    name,
    deceased,
    ageAtDeath,
    ageCurrent,
    causeOfDeath,
    dob: dayjs(dob).toJSON(),
    relationManis,
  })

  const [checked, setChecked] = React.useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    const editedRelations = Object.values(entities).filter((rel) => {
      if (rel.edited === true && rel.reactId !== reactKey) {
        return rel;
      }
    })
    dispatch(editRelationsInDb([{...dynamicData}, ...editedRelations]))
      .then(() => {
        dispatch(fetchRelations())
        handleExpansion();
      })
  }

  React.useEffect(() => {
    if (!relationState.deceased) {
      setRelationState({
        ...relationState,
        "deceased": false,
        "ageAtDeath": null,
        "causeOfDeath": null,
      })
    }
    if (relationState.deceased) {
      setRelationState({
        ...relationState,
        "ageCurrent": null,
      })
    }
  }, [relationState.deceased])

  React.useEffect(() => {
    dispatch(editRelation(relationState, reactKey));
  }, [relationState])

  return (
    <Grid container item>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={"auto"}>
            <InputLabel
              htmlFor={`relations-name`}
              id={`${relationId}-full-name`}>
              Full name
            </InputLabel>
            <TextField
              aria-labelledby={`${relationId}-full-name`}
              id={`${relationId}-full-name`}
              name="relations-name"
              defaultValue={relationState.name ? relationState.name : ""}
              placeholder="Full name"
              onBlur={(e) => {
                handleNameChange(e, reactKey, relationState, setRelationState);
              }} />
          </Grid>
          <Grid item xs={"auto"} mt={0.5} mb={0.5}>
            <InputLabel sx={{color: "#000000"}}>
              Are they deceased? &nbsp;&nbsp;
              <input type="checkbox"
                     name="deceased"
                     checked={relationState.deceased}
                     onChange={(e) => {
                       handleDeceased(e, reactKey, relationState, setRelationState);
                     }}
              />
            </InputLabel>
          </Grid>
        </Grid>
        <Grid spacing={2} container>
          <Grid item xs={"auto"}>
            <InputLabel id={"dob"} htmlFor={"dob"}>Date of Birth (if known)</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                aria-labelledby={"dob"}
                maxDate={dayjs()}
                value={dayjs(relationState.dob).isValid() ? dayjs(relationState.dob) : null}
                onChange={(e) => {
                  handleMuiDateChange(e, reactKey, relationState, setRelationState);
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />} />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={"auto"}>
            {
              relationState.deceased
                ? (<>
                  <InputLabel htmlFor={"ageAtDeath"}>
                    Age at Death
                  </InputLabel>
                  <TextField
                    aria-labelledby={"ageAtDeath"}
                    name="ageAtDeath"
                    style={{maxWidth: "120px"}}
                    value={relationState.ageAtDeath ? relationState.ageAtDeath : ""}
                    inputProps={{inputMode: "numeric", pattern: "[0-9]*", title: "Numbers only"}}
                    placeholder="Age"
                    onChange={(e) => {
                      handleAgeChange(e, reactKey, relationState, setRelationState);
                    }} />
                </>)
                : (<>
                    <InputLabel htmlFor={"ageCurrent"} id={"ageCurrent"}>
                      Current Age
                    </InputLabel>
                    <TextField
                      aria-labelledby={"ageCurrent"}
                      style={{maxWidth: "120px"}}
                      name="ageCurrent"
                      value={relationState.ageCurrent ? relationState.ageCurrent : ""}
                      inputProps={{inputMode: "numeric", pattern: "[0-9]*", title: "Numbers only"}}
                      placeholder="Age"
                      onChange={(e) => {
                        handleAgeChange(e, reactKey, relationState, setRelationState);
                      }} />
                  </>
                )
            }
          </Grid>
          {
            dynamicData.deceased &&
            <Grid item xs={"auto"}>
              <label className="sublabel">Cause of Death</label><br />
              <input type="text"
                     maxLength={255}
                     name="causeOfDeath"
                     defaultValue={relationState.causeOfDeath ? relationState.causeOfDeath : undefined}
                     placeholder="Cause of Death"
                     className="text-input"
                     onBlur={(e) => {
                       handleCauseOfDeath(e, reactKey, relationState, setRelationState);
                     }} />
            </Grid>
          }
        </Grid>
        {
          (relationId !== 21 && relationId !== 20) &&
          <MultiBirthOptions
            reactKey={reactKey}
            multiBirthType={multiBirthType}
            multiBirthId={multiBirthId}
            multiBirthSelection={multiBirthSelection}
            relationsNewestMultiBirthId={relationsNewestMultiBirthId} />
        }

        <div className="modal-line-break">
          <hr />
        </div>

        {
          (relationId === 2 || relationId === 3)
            ? <AncestryHistory relation={relationLabel}
                               patientRelationId={prId}
                               relationReactId={reactKey}
                               existing={manifestations.ancestry} />

            : undefined
        }

        <CancerHistory relation={relationLabel}
                       relationReactId={reactKey}
                       patientRelationId={prId}
                       side={sideLabel}
                       gender={relationGender}
                       existing={manifestations.cancer}
                       isDeceased={relationState.deceased} />
        <br />
        <GeneHistory relation={relationLabel}
                     relationReactId={reactKey}
                     patientRelationId={prId}
                     side={sideLabel}
                     gender={relationGender}
                     existingKgmMani={((manifestations.gene && manifestations.gene.length > 0)) ? manifestations.gene[0] : undefined}
                     isDeceased={relationState.deceased} />
        <br />
        <OtherHistory relation={relationLabel}
                      relationReactId={reactKey}
                      patientRelationId={prId}
                      side={sideLabel}
                      gender={relationGender}
                      existing={manifestations["other"]}
                      isDeceased={relationState.deceased} />
        <Grid columnSpacing={2} justifyContent={"center"} container item mt={4}>
          <Grid item xs={"auto"}>
            <Button variant={"outlined"} className="secondary-button"
                    onClick={(e) => {
                      e.preventDefault();
                      document.querySelector("#questionnaire-wrapper").scrollTo({
                        top: (parseInt(document.querySelector(`#questionnaire-wrapper`).scrollTop) - 500)
                      });
                      // quick and dirty fix to ensure data doesn't get stale
                      // nobody likes stale data
                      // really need to implement redux toolkit
                      dispatch(fetchRelations());
                    }}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant={"contained"}
                    className={relationsDbPosting ? " button-loading" : ""}
                    type={"submit"}
                    onClick={() => {
                      sessionStorage.setItem('relationBuildScrollPosition', document.querySelector("#questionnaire-wrapper").scrollTop);
                    }}>
              <span className="backcontinue-button-text">Save</span>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

export default RelationEdit;