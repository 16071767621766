import {Button, Grid} from "@mui/material";
import {connect} from "react-redux";
import {resetContinue} from "../../../Redux/relations/relationSlice";
import {useCurrentPosition} from "../Helpers/stepOrder";
import {useDispatch} from "react-redux";

function BackContinueButtons(props) {
  const dispatch = useDispatch();
  const {isFirst, isLast, slug} = useCurrentPosition();
  const {loading, disabled, currLocation} = props;
  const renderContinue = ((isLast || (slug.path === "submission-page") || (slug.path === "finished-questionnaire")) || disabled)

  if (currLocation !== "relations-histories" && disabled) {
    dispatch(resetContinue())
  }

  return (
    <Grid container mt={5} justifyContent="center" columnSpacing={(isFirst || renderContinue || loading) ? "" : 3}
          className="questionnaire-footer">
      <Grid container item xs={"auto"} alignSelf={isFirst ? "" : "center"}>
        {isFirst ? undefined : (
          <Button variant={"outlined"}
                  type="submit"
                  name="back"
                  id="back-questionnaire"
                  sx={{display: `${loading ? "none" : "block"}`}}
          > < span className="backcontinue-button-text"
                   id="back-questionnaire-text">Back</span></Button>
        )}
      </Grid>
      <Grid container
            item
            xs={"auto"}
            alignSelf={renderContinue ? "" : "center"}>
        {renderContinue ? undefined : (
          <Button variant={"contained"}
                  className={(loading ? " button-loading" : "")}
                  type="submit"
                  name="next"
                  id="next-questionnaire"
          ><span className="backcontinue-button-text"
                 id="next-questionnaire-text">Continue</span></Button>
        )}
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    disabled: (state.relations.relationsCardIsExpanded && state.users.usersLocation.url === "relations-histories"),
    currLocation: state.users.usersLocation.url,
  }
};

export default connect(mapStateToProps)(BackContinueButtons);