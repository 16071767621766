import {Grid} from "@mui/material";
import React from "react";
import {useRelationModalChangeHandlers} from "../../../Hooks/useRelationModalChangeHandlers";
import {dataListElementsNonVus} from "../QuestionnaireComponents/32_GeneticTestAddendum";

export const GeneInput = (props) => {
  const {gene, relationReactId, kgmManiReactId, deleteGene} = props
  const {reactId: geneOptValReactId} = gene;
  const {handleRelationGeneChange} = useRelationModalChangeHandlers();
  const getGeneText = () => {
    let result = undefined;
    dataListElementsNonVus.props.children.forEach((option) => {
      if (option.props["data-opt-val"] === gene.manifestationOptionValueId) {
        result = option.props.value;
      }
    });
    return result;
  }

  return (
    <Grid item id="gene-input-container"
          style={{marginTop: "10px"}}>
      <span className={"gene-input-wrapper"}
            id={"gene-delete"}>
        <input type="text"
               className={"text-input-small"}
               style={{width: "110px"}}
               name={"geneMutations"}
               placeholder={"Gene"}
               list={"geneMutations"}
               value={getGeneText()}
               id={geneOptValReactId}
               onChange={(e) => {
                 handleRelationGeneChange(e, relationReactId, kgmManiReactId, geneOptValReactId)
               }} />
        {dataListElementsNonVus}
        <span className={"gene-input-delete-button"}
              onClick={(e) => {
                e.preventDefault();
                deleteGene(relationReactId, kgmManiReactId, geneOptValReactId)
              }}>
          &times;
        </span>
      </span>
    </Grid>)
}