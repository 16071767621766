import React, {useState, createContext} from "react";
import {
  manifestationMultiManiInitialAddNoOpts,
  manifestationsMultiManiDelete
} from "../../../Redux/manifestations/multiManifestationSlice";
import {useDispatch} from "react-redux";
import store from "../../../Redux/store/store";

export const CancerHist = createContext({});

export const CancerHistProvider = ({children}) => {
  const dispatch = useDispatch();
  const selfRelationId = store.getState().users.usersSelfRelationId;
  
  const [cancers, setCancers] = useState([]);
  
  const deleteCancerHist = (id) => {
    setCancers(cancers.filter((cancer) => cancer.reactId !== id));
    dispatch(manifestationsMultiManiDelete(id));
  }
  
  const addCancerHist = () => {
    const {reactId: newId} = dispatch(manifestationMultiManiInitialAddNoOpts(selfRelationId)).payload;
    setCancers((cancers) => [
      ...cancers,
      {
        reactId: newId
      }
    ])
  }
  
  return (
    <CancerHist.Provider value={{cancers, setCancers, addCancerHist, deleteCancerHist}}>
      {children}
    </CancerHist.Provider>
  );
}