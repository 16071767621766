// noinspection JSUnresolvedVariable

import {createSlice, createAsyncThunk, createEntityAdapter} from "@reduxjs/toolkit";
import axios from "axios";
import {apiSlice} from "../api/apiSlice";
import store from "../store/store";

let GLOBAL = require("../../Helpers/globals");

const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState({
  usersGender: null,
  usersPatientId: null,
  usersSelfRelationId: null,
  usersIsDirty: false,
  usersIsLoggedIn: false,
  usersHasValidToken: false,
  usersHasToken: null,
  usersFetchLoading: false,
  usersDbPostLoading: false,
  usersAxiosConfig: null,
  usersLocation: {},
  validating: false,
  logoutMessage: "",
  loginError: null,
  tokenError: null,
  inHealthDiary: false,
  usersRegisterLoading: false,
  questionnaireReturnLater: null,
  passwordExpired: false,
});

export const loginUser = createAsyncThunk("authenticate/login", async (credentials) => {
  const response = await
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/authenticate/login`, credentials)
      .catch((err) => {
        console.error(err.response.data);
      });
  return response.data;
});

export const registerNewPatient = createAsyncThunk("authenticate/register", async (credentials) => {
  const response = await
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/authenticate/validate/signup`, credentials);
  return response.data;
});

export const fetchUserData = createAsyncThunk("users/fetchUserData", async (usersPatientId) => {
  console.log("fetching...", usersPatientId);
  const response = await axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/users/${usersPatientId}`, store.getState().users.usersAxiosConfig)
    .catch((err) => {throw err});
  return response.data;
});

export const validateToken = createAsyncThunk("authenticate/validateToken", async (token) => {
  const response = await
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/authenticate/validate/token/${token}`)
  return response.data;
});

export const submitPwdReset = createAsyncThunk("password/forgot", async (creds) => {
  const response = await
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/authenticate/pass/forgot`, creds)
  return response.data;
});

export const submitChangedPwd = createAsyncThunk("password/change", async (creds) => {
  const response = await
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/authenticate/pass/reset`, creds)
  return response.data;
});

export const finalFormSubmit = createAsyncThunk("users/finalFormSubmit", async () => {
  const response = await
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/users/form/submit`,
      method: "POST",
      data: {patientId: store.getState().users.usersPatientId},
      headers: store.getState().users.usersAxiosConfig.headers,
    }).then((res) => {return res})
  return response.data;
});

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    loginUserSetData: {
      reducer(state, action) { },
      prepare() { }
    },
    startHealthDiary: {
      reducer(state) {
        state.inHealthDiary = !state.inHealthDiary;
      }
    },
    updateUserState: {
      reducer(state, action) {
        state.usersIsDirty = true;
        let {heightFt, heightIn, height, weight} = action.payload;
        heightFt = parseInt(heightFt);
        heightIn = parseInt(heightIn);
        if (!heightIn) {
          heightIn = 0;
        }
        height = heightFt * 12 + heightIn;
        weight = parseInt(weight);

        let formattedState = {
          ...state.entities[action.payload.patientId],
          ...action.payload,
          heightFt: heightFt,
          heightIn: heightIn,
          height: height,
          weight: weight,
        }

        usersAdapter.updateOne(state, {
          id: state.usersPatientId,
          changes: formattedState
        })
      },
    },
    logoutUser: {
      reducer(state) {
        // this function is handled in the rootReducer in store.js
        // store.js rootReducer resets the state to undefined for all slices.
        // dispatch calls this, so it needs to live here for redux to detect it.
      }
    },
    keepUserGenderUpdated: {
      reducer(state, action) {
        state.usersGender = action.payload.usersGender;
      },
      prepare(newGender) {
        if (newGender === "Male" || newGender === "Female") {
          return {
            payload: {
              usersGender: newGender
            }
          }
        }
      },
    },
    updateUserLocation: {
      reducer(state, action) {
        const {slug, activeLink} = action.payload;
        state.usersLocation = {
          url: slug,
          activeLink,
        };
      },
      prepare(slug, activeLink) {
        return {
          payload: {
            slug,
            activeLink
          }
        }
      },
    },
    updateQuestionnaireReturnLater: {
      reducer(state, action) {
        state.questionnaireReturnLater = action.payload;
        usersAdapter.updateOne(state, {
          id: state.usersPatientId,
          changes: {
            questionnaireReturnLater: action.payload
          }
        })
      },
      prepare(slug) {
        return {
          payload: slug
        }
      },
    },
    updatePasswordExpired: {
      reducer(state, action) {
        state.passwordExpired = action.payload;
        usersAdapter.updateOne(state, {
          id: state.usersPatientId,
          changes: {
            passwordExpired: action.payload
          }
        })
      },
      prepare(bool) {
        return {
          payload: bool
        }
      }
    },
    updateUserFields: {
      reducer(state, action) {
        console.log(action.payload);
        state.usersIsDirty = true;
        usersAdapter.updateOne(state, {
          id: state.usersPatientId,
          changes: action.payload,
        });
      },
      prepare(payload) {
        return {
          payload: payload
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.rootLoading = true;
        state.logoutMessage = "";
        state.loginError = "";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.rootLoading = false;
        state.usersIsLoggedIn = true;
        state.usersHasValidToken = true;
        state.usersHasToken = action.payload.token;
        state.usersPatientId = action.payload.patientId;
        if (!state.usersAxiosConfig) {
          state.usersAxiosConfig = {headers: {Authorization: `Bearer ${action.payload.token}`}};
        }
        state.logoutMessage = "";
        state.loginError = "";
        localStorage.setItem("userToken-v1", action.payload.token);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.rootLoading = false;
        state.usersIsLoggedIn = false;
        state.usersHasValidToken = false;
        state.usersHasToken = null;
        state.usersAxiosConfig = null;
        state.usersPatientId = null;
        state.loginError = "Invalid username or password.";
      })
      .addCase(validateToken.pending, (state, action) => {
        state.validating = true;
      })
      .addCase(validateToken.fulfilled, (state, action) => {
        state.validating = false;
        state.usersHasValidToken = true;
        state.usersIsLoggedIn = true;
        state.usersPatientId = action.payload.patientId;
        state.usersSelfRelationId = action.payload.selfRelationId;
        state.usersHasToken = action.payload.token;
        state.logoutMessage = "";
        state.loginError = "";
        if (!state.usersAxiosConfig) {
          state.usersAxiosConfig = {headers: {Authorization: `Bearer ${action.payload.token}`}};
        }
        localStorage.setItem("userToken-v1", action.payload.token);
      })
      .addCase(validateToken.rejected, (state) => {
        state.validating = false;
        state.usersHasValidToken = false;
        state.usersHasToken = null;
        state.usersPatientId = null;
        state.usersSelfRelationId = null;
        state.axiosToken = null;
        state.logoutMessage = "Your session has expired. Please log in again.";
        state.inHealthDiary = false;
        localStorage.removeItem("userToken-v1");
      })
      .addCase(registerNewPatient.pending, (state, action) => {
        state.usersRegisterLoading = true;
      })
      .addCase(registerNewPatient.fulfilled, (state, action) => {
        state.usersRegisterLoading = false;
      })
      .addCase(registerNewPatient.rejected, (state, action) => {
        state.usersRegisterLoading = false;
        state.loginError = "There was a problem with one or more of your inputs.";
      })
      .addCase(fetchUserData.pending, (state, action) => {
        state.usersFetchLoading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.usersFetchLoading = false;
        state.usersPatientId = action.payload.id;
        state.questionnaireReturnLater = action.payload.questionnaireReturnLater;
        state.userFirstName = action.payload.userFirst;
        state.passwordExpired = action.payload.passwordExpired;
        const userObj = action.payload;
        if (action.payload.dob) {
          userObj.dob = action.payload.dob.slice(0, 10)
        }
        if (action.payload.gender) {
          state.usersGender = action.payload.gender;
        }
        if (userObj.height > 0) {
          const feet = Math.floor(userObj.height / 12);
          const inches = userObj.height % 12;
          userObj.heightFt = feet;
          userObj.heightIn = inches;
        }
        userObj.usersPatientId = action.payload.id;
        if (action.payload.questionnaireReturnLater) {
          state.questionnaireReturnLater = action.payload.questionnaireReturnLater;
        }
        usersAdapter.addOne(state, userObj);
        state.usersIsDirty = false;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.usersFetchLoading = false;
        state.loginError = "Invalid email or password.";
      })
      .addCase(submitPwdReset.pending, (state, action) => {
        state.usersDbPostLoading = true;
      })
      .addCase(submitPwdReset.fulfilled, (state, action) => {
        state.usersDbPostLoading = false;
      })
      .addCase(submitPwdReset.rejected, (state, action) => {
        state.usersDbPostLoading = false;
      })
      .addCase(submitChangedPwd.pending, (state, action) => {
        state.usersDbPostLoading = true;
      })
      .addCase(submitChangedPwd.fulfilled, (state, action) => {
        state.usersDbPostLoading = false;
      })
      .addCase(submitChangedPwd.rejected, (state, action) => {
        state.usersDbPostLoading = false;
      })
      .addMatcher(
        apiSlice.endpoints.updateUser.matchPending,
        (state) => {
          state.usersDbPostLoading = true;
        })
      .addMatcher(
        apiSlice.endpoints.updateUser.matchFulfilled,
        (state) => {
          state.usersIsDirty = false;
          state.usersDbPostLoading = false;
        }
      )
  },
})

export const {
  keepUserGenderUpdated,
  startHealthDiary,
  updateUserState,
  logoutUser,
  updateUserLocation,
  updateQuestionnaireReturnLater,
  updateUserFields,
} = usersSlice.actions;

export default usersSlice.reducer;