import {Grid, Typography} from "@mui/material";
import React from "react";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useUpdateUserMutation} from "../../Redux/api/apiSlice";
import store from "../../Redux/store/store";
import {updateQuestionnaireReturnLater} from "../../Redux/users/userSlice";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";

export const Questionnaire_Submitted = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUserMutation();
  const userState = useSelector(state => state.users);
  const {entities, usersPatientId} = userState;
  const {patientProviderName} = entities[usersPatientId] ? entities[usersPatientId] : {patientProviderName: ""}

  const handleFinish = async () => {
    dispatch(updateQuestionnaireReturnLater("basic-info"))
    const token = store.getState().users.usersHasToken
    const patientId = store.getState().users.usersPatientId
    const usersObj = store.getState().users.entities[patientId];
    let usersUpd = {
      ...usersObj,
      dob: new Date(usersObj.dob + "T00:00:00.000Z"),
      token: token,
      usersPatientId: patientId,
    }
    await updateUser(usersUpd)
      .unwrap()
      .then(() => {
        navigate("/home");
      })
  }

  React.useLayoutEffect(() => {
    const backButtonOverride = document.getElementById("back-questionnaire");
    const backButtonTextOverride = document.getElementById("back-questionnaire-text");
    if (backButtonOverride && backButtonTextOverride) {
      backButtonTextOverride.textContent = "Back to Homepage"
      backButtonOverride.addEventListener("click", async (e) => {
        e.preventDefault();
        await handleFinish();
      });
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Questionnaire Complete!</title>
      </Helmet>
      <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper"
            id="questionnaire-wrapper">
        <Grid item xs={10} mt={5}>
          <Typography textAlign={"center"} variant="h2" mt={5}>
            Your information has been shared with {patientProviderName ? patientProviderName : "your provider"}.
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant={"body1"} textAlign={"center"} mt={2}>
            Please contact their office with any questions about the results or next steps.
          </Typography>
          <Typography variant={"body1"} textAlign={"center"} mt={2}>
            It is important to keep your health diary up-to-date.
            You will be reminded at least annually to review your
            information and provide updates so your personalized plan can be adjusted when needed.
          </Typography>
          <BackContinueButtons />
        </Grid>
      </Grid>
    </>
  )
    ;
}