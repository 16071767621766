import {Button, FormLabel, Grid} from "@mui/material";
import {RelationOther} from "../Context/TreeList_OtherContext";
import {useContext, useEffect} from "react";
import {ReactComponent as AddIcon} from "../../../Resources/Images/Icons/addButtonSmall.svg";
import {RelationOtherFormRow} from "./treeList_OtherFormRow";

export const RelationOtherForm = (props) => {
  const relationOther = useContext(RelationOther);
  const {other, setOther, addOtherHist, deleteOtherHist} = relationOther;
  const {relation, relationReactId, patientRelationId, gender, existing, isDeceased} = props;

  useEffect(() => {
    if (existing.length > 0) {
      setOther(existing);
    }
  }, [])

  return (
    <>
      <Grid container mt={5}>
        <Grid item xs={12} sm={8}>
          <FormLabel sx={{color: "#000000"}}>
            {isDeceased ? "Was " : "Has "}
            {`your ${relation.toLowerCase()} `}
            {isDeceased ? " ever " : " ever been "}
            diagnosed with any other health issue(s)?
          </FormLabel>
        </Grid>
        <Grid item xs={"auto"} ml={1} mt={1}>
          <Button variant={"relation-add"}
                  onClick={(e) => {
                    e.preventDefault();
                    addOtherHist(patientRelationId)
                  }}>
            <AddIcon style={{width: "16px", height: "16px"}} />
            &nbsp;Add condition
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={"auto"}>
        {
          (other.length > 0)
            ? other.map((other) => {
              return (
                <RelationOtherFormRow key={other.reactId}
                                      relationReactId={relationReactId}
                                      relationGender={gender}
                                      existingOther={other}
                                      deleteOtherHist={deleteOtherHist} />
              )
            })
            : undefined
        }
      </Grid>
    </>
  )
}