import React from "react";
import AddRelationButton from "./addRelationButton";
import RelationCard from "./relationCard";

import {Grid, Typography} from "@mui/material";

const RenderOwn = (props) => {
  const {selfRelations, isMobile} = props;
  const {
    patientAndRelationsId,
    motherPrId,
    fatherPrId,
  } = selfRelations;

  return (
    <>
      {
        <>
          <Typography textAlign={"center"} variant={"h2"} mt={5}
                      className={"orange-label"}>Your immediate relatives</Typography>

          {
            <Grid item xs={11} lg={8} ml="auto" mr="auto">
              <Typography
                variant="h5"
                textAlign={"left"} mt={5}
                className={"blue-label"}>
                {selfRelations.children?.length >= 0 &&
                  "Add your children"
                }
              </Typography>
            </Grid>
          }
          <AddRelationButton
            label="Add Child"
            side="NA"
            types={["Son", "Daughter"]}
            parentIds={{
              self: patientAndRelationsId,
            }} />
          {
            selfRelations.children?.length > 0 &&
            <>
              <Typography
                variant="h5"
                textAlign={"left"} mt={5}
                className={"blue-label"}>
                {selfRelations["children"]?.length >= 1 &&
                  `Your ${selfRelations["children"]?.length === 1 ? "child" : "children"}`
                }
              </Typography>
              {selfRelations.children?.map((child) => {
                return (
                  <RelationCard
                    key={child.reactId}
                    reactKey={child.reactId}
                    entity={child}
                    multiBirthSelection={selfRelations.children}
                    isMobile={isMobile}
                  />
                )
              })}
            </>
          }
        </>
      }
      {
        <>
          <Grid item xs={11} lg={8} ml="auto" mr="auto">
            <Typography
              variant="h5"
              textAlign={"left"} mt={5}
              className={"blue-label"}>
              {
                selfRelations["fullSiblings"]?.length >= 0 &&
                "Add your full siblings"
              }
            </Typography>
          </Grid>
          <AddRelationButton
            label="Add Full Sibling"
            side="NA"
            types={["Full Brother", "Full Sister"]}
            parentIds={{
              motherPrId: motherPrId,
              fatherPrId: fatherPrId,
            }} />
          {
            selfRelations["fullSiblings"]?.length > 0 &&
            <>
              <Typography
                variant="h5"
                textAlign={"left"} mt={5}
                className={"blue-label"}>
                {selfRelations["fullSiblings"]?.length >= 1 &&
                  `Your ${selfRelations["fullSiblings"]?.length === 1 ? "sibling" : "siblings"}`
                }
              </Typography>
              {selfRelations["fullSiblings"]?.map((sibling) => {
                return (
                  <RelationCard
                    key={sibling.reactId}
                    reactKey={sibling.reactId}
                    entity={sibling}
                    multiBirthSelection={[...selfRelations["fullSiblings"], selfRelations]}
                    isMobile={isMobile}
                  />
                )
              })}
            </>
          }
        </>
      }
    </>
  )
}

export default RenderOwn;
