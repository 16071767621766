import React from "react";
import {fetchRelations} from "../Redux/relations/relationSlice";
import {useDispatch, useSelector} from "react-redux";

export const useRelations = () => {
  const dispatch = useDispatch();
  const relationsState = useSelector((state) => state.relations);
  const {ids, relationsIsLoading} = relationsState;
  
  React.useEffect(() => {
    if (!(ids.length) && !relationsIsLoading) {
      dispatch(fetchRelations());
    }
  }, []);
  
  return {relationsState, relationsIsLoading};
};