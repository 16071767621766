import {MenuItem} from "@mui/material";
import React from "react";
/*
* TODO: ~sync with backend~
*  note: this is.
* */

/*
* Cancer manifestations
* TODO: reduce this to a single array of objects, and use a single function to render the list by gender.
*  NOTE: Already done in 102_OtherHistoryRow.jsx
*  NOTE: referenced in
*   src/Pages/PatientQuestionnaire/QuestionnaireComponents/92_CancerHistoryRow.jsx, 
*   and src/Pages/PatientQuestionnaire/FamilyTreeComponents/treeList_CancerForm.jsx
* */
export const allCancers =
  [{"id": 108, "label": "Acoustic Neuroma"},
    {"id": 167, "label": "Adrenal Gland"},
    {"id": 1, "label": "Adrenocortical Carcinoma"},
    {"id": 20, "label": "Any"},
    {"id": 109, "label": "Appendix"},
    {"id": 37, "label": "Biliary Tract"},
    {"id": 111, "label": "Bladder"},
    {"id": 112, "label": "Bone"},
    {"id": 2, "label": "Brain"},
    {"id": 3, "label": "Breast"},
    {"id": 113, "label": "Carcinoid"},
    {"id": 116, "label": "Chordoma"},
    {"id": 33, "label": "Choroid Plexus Tumor"},
    {"id": 4, "label": "Colon or Colorectal"},
    {"id": 1386, "label": "Duodenal Cancer"},
    {"id": 117, "label": "Endolymphatic"},
    {"id": 118, "label": "Esophagus"},
    {"id": 119, "label": "Eye"},
    {"id": 267, "label": "Gallbladder"},
    {"id": 41, "label": "Gastric, Gastrointestinal, or  Stomach"},
    {"id": 120, "label": "Germ Cell"},
    {"id": 124, "label": "Hemangioblastoma"},
    {"id": 290, "label": "Hepatoblastoma"},
    {"id": 40, "label": "Keratoacanthoma"},
    {"id": 9, "label": "Kidney or Renal"},
    {"id": 10, "label": "Leukemia"},
    {"id": 128, "label": "Lhermitte-Duclos"},
    {"id": 129, "label": "Liver"},
    {"id": 130, "label": "Lung"},
    {"id": 131, "label": "Lymphoma"},
    {"id": 132, "label": "Mouth or Throat"},
    {"id": 133, "label": "Myeloma"},
    {"id": 134, "label": "Nasopharynx"},
    {"id": 136, "label": "Other"},
    {"id": 14, "label": "Pancreatic"},
    {"id": 137, "label": "Paraganglioma"},
    {"id": 138, "label": "Parathyroid"},
    {"id": 141, "label": "Pheochromocytoma"},
    {"id": 142, "label": "Pituitary Gland"},
    {"id": 373, "label": "Renal Cell Carcinoma"},
    {"id": 34, "label": "Rhabdomyosarcoma"},
    {"id": 16, "label": "Sarcoma"},
    {"id": 303, "label": "Sebaceous Gland Carcinoma"},
    {"id": 146, "label": "Skin"},
    {"id": 38, "label": "Small Intestine"},
    {"id": 147, "label": "Spine"},
    {"id": 151, "label": "Thymus"},
    {"id": 17, "label": "Thyroid"},
    {"id": 154, "label": "Unknown"},
    {"id": 35, "label": "Ureter or Urethra"}]

export const femaleCancers =
  [{"id": 114, "label": "Cervix", "gender": "Female"},
    {"id": 7, "label": "Endometrial", "gender": "Female"},
    {"id": 265, "label": "Fallopian Tube", "gender": "Female"},
    {"id": 13, "label": "Ovarian", "gender": "Female"},
    {"id": 287, "label": "Peritoneum or Primary Peritoneal", "gender": "Female"},
    {"id": 289, "label": "Uterus or Uterine", "gender": "Female"},
    {"id": 155, "label": "Vagina", "gender": "Female"},
    {"id": 156, "label": "Vulva", "gender": "Female"}]

export const maleCancers =
  [{"id": 281, "label": "Penile or Penis", "gender": "Male"},
    {"id": 15, "label": "Prostate", "gender": "Male"},
    {"id": 354, "label": "Testicular Sertoli Cell Tumor", "gender": "Male"},
    {"id": 149, "label": "Testis", "gender": "Male"}]

export const hasMoreValues =
  {
    "2": [1, 65],
    "2-relation": [1, 65],
    "3": [2, 36, 68, 69, 73, 74],
    "3-relation": [2, 36],
    "4": [3],
    "4-relation": [3],
    "9": [5],
    "9-relation": [5],
    "14": [6],
    "14-relation": [6],
    "15": [7, 37, 51, 52],
    "15-relation": [7, 37, 51, 52],
    "16": [8],
    "16-relation": [8],
    "17": [9],
    "17-relation": [9],
    "19": [10],
    "19-relation": [10],
    "37": [11],
    "37-relation": [11],
    "38": [12],
    "38-relation": [12],
    "41": [58],
    "41-relation": [58],
    "119": [13],
    "119-relation": [13],
    "130": [64],
    "130-relation": [64],
    "131": [14],
    "131-relation": [14],
    "132": [15],
    "132-relation": [15],
    "146": [16],
    "146-relation": [16],
  }

export const guessList =
  [<MenuItem key={1} value="">Please Select</MenuItem>,
    <MenuItem key={2} value="Actual">Very confident</MenuItem>,
    <MenuItem key={3} value="CloselyAccurate">I'm pretty close</MenuItem>,
    <MenuItem key={4} value="guess">It's my best guess</MenuItem>,]

/*
* Biopsies manifestations
* */
export const biopsyTypesAll = [
  {"id": 308, "name": "Breast"},
  {"id": 4506, "name": "Colon"},
  {"id": 4507, "name": "Thyroid or Skin"},
];

export const biopsyTypesFemale = [
  {"id": 306, "name": "Endometrial"},
];

export const biopsyTypesMale = [
  {"id": 313, "name": "Prostate"},
];

// ManifestationOption ID for breast biopsy is 44
export const breastResults = [
  <MenuItem key={0} value={294}>Atypical Hyperplasia</MenuItem>,
  <MenuItem key={1} value={295}>Cancer</MenuItem>,
  <MenuItem key={2} value={296}>Fibroadenoma</MenuItem>,
  <MenuItem key={3} value={297}>Fibrocystic Changes/Disease</MenuItem>,
  <MenuItem key={4} value={2631}>Hyperplasia (not atypia)</MenuItem>,
  <MenuItem key={5} value={298}>Lobular Carcinoma in Situ (LCIS)</MenuItem>,
  <MenuItem key={6} value={299}>Other Non-Cancer</MenuItem>,
  <MenuItem key={7} value={300}>I don't know</MenuItem>,
]

// ManifestationOption ID for colon biopsy is 2085
export const colonResults = [
  <MenuItem key={0} value={2640}>Abnormal/Deficient MMR Protein Expression</MenuItem>,
  <MenuItem key={1} value={2623}>Cancer</MenuItem>,
  <MenuItem key={2} value={2624}>Microsatellite Instability (MSI)</MenuItem>,
  <MenuItem key={3} value={2625}>Immunohistochemical Staining (IHC)</MenuItem>,
  <MenuItem key={4} value={2626}>Other Non-Cancer</MenuItem>,
  <MenuItem key={5} value={2627}>I don't know</MenuItem>,
]

// ManifestationOption ID for prostate is 45
export const prostateResults = [
  <MenuItem key={0} value={2640}>Abnormal/Deficient MMR Protein Expression</MenuItem>,
  <MenuItem key={1} value={2637}>Cancer</MenuItem>,
  <MenuItem key={2} value={2638}>Non-Cancer</MenuItem>,
  <MenuItem key={3} value={2639}>Other Non-Cancer</MenuItem>,
]

// ManifestationOption ID for endometrial or uterine is 43
export const endoOrUterineResults = [
  <MenuItem key={0} value={2641}>Abnormal/Deficient MMR Protein Expression</MenuItem>,
  <MenuItem key={1} value={287}>Atypical Hyperplasia</MenuItem>,
  <MenuItem key={2} value={291}>HPV</MenuItem>,
  <MenuItem key={3} value={288}>Cancer</MenuItem>,
  <MenuItem key={4} value={289}>Fibroadenoma</MenuItem>,
  <MenuItem key={5} value={290}>Fibroids</MenuItem>,
  <MenuItem key={6} value={2632}>Immunohistochemical Staining (IHC)</MenuItem>,
  <MenuItem key={7} value={2633}>Microsatellite Instability (MSI)</MenuItem>,
  <MenuItem key={8} value={292}>Other Non-Cancer</MenuItem>,
  <MenuItem key={9} value={293}>I don't know</MenuItem>,
]

// ManifestationOption ID for thyroid or skin is 2086
export const thyroidOrSkinResults = [
  <MenuItem key={0} value={2628}>Cancer</MenuItem>,
  <MenuItem key={1} value={2629}>Other Non-cancer</MenuItem>,
  <MenuItem key={2} value={2630}>I don't know</MenuItem>,
]

export const maniOptionIdBiopsies = {
  308: 44, //breast
  4506: 2085, //colon
  313: 2087, //prostate
  306: 43, //endometrial or uterine
  4507: 2086 //thyroid or skin
}

/*
* Other health conditions manifestations
* */
export const otherHistoryList = [
  {"id": 1385, "label": "Adenoma - Duodenal"},
  {"id": 39, "label": "Adenoma - Sebaceous Gland "},
  {"id": 1410, "label": "Adenoma - Small Bowel"},
  {"id": 32, "label": "Autism Spectrum or Aspergers "},
  {"id": 30, "label": "Bannayan-Riley-Ruvalcaba Syndrome (BRRS)"},
  {"id": 355, "label": "Childhood Skin Pigmentation"},
  {"id": 27, "label": "CHRPE"},
  {"id": 1439, "label": "Cleft Lip/Palette"},
  {"id": 157, "label": "Cowden or PTEN Syndrome"},
  {"id": 26, "label": "Desmoid Tumor"},
  {"id": 158, "label": "Developmental Delay"},
  {"id": 1388, "label": "Epidermoid Cyst"},
  {"id": 368, "label": "Esophageal Glycogenic Acanthoses"},
  {"id": 374, "label": "Ganglioneuroma"},
  {"id": 1430, "label": "Intellectual Disability (IQ <=75)"},
  {"id": 369, "label": "Lipoma"},
  {"id": 11, "label": "Macrocephaly"},
  {"id": 378, "label": "Macular Pigmentation of Glans Penis", "gender": "Male"},
  {"id": 1401, "label": "Mucocutaneous Hyperpigmentation"},
  {"id": 377, "label": "Mucocutaneous Lesions"},
  {"id": 301, "label": "Odontoma"},
  {"id": 298, "label": "Osteoma"},
  {"id": 139, "label": "Parathyroid Hyperplasia"},
  {"id": 1452, "label": "Peutz-Jeghers syndrome"},
  {"id": 1427, "label": "Polyps", "relation": true,},
  {"id": 48, "label": "Polyposis"},
  {"id": 5, "label": "Skin Lesions or Dermatologic Manifestations"},
  {"id": 299, "label": "Supernumerary Teeth"},
  {"id": 375, "label": "Testicular Lipomatosis", "gender": "Male"},
  {"id": 372, "label": "Thyroid Structural Lesions"},
  {"id": 29, "label": "Trichilemmomas (Biopsy Proven) "},
  {"id": 376, "label": "Vascular Anomalies"}]

export const polypOptions = [
  <MenuItem key={0} value={""}>Please select Type</MenuItem>,
  <MenuItem key={10} value={514}>I don't know</MenuItem>,
  <MenuItem key={1} value={466}>Adenomatous</MenuItem>,
  <MenuItem key={2} value={498}>Gastric Fundic</MenuItem>,
  <MenuItem key={3} value={499}>Gastrointestinal</MenuItem>,
  <MenuItem key={4} value={500}>Hamartomatous</MenuItem>,
  <MenuItem key={5} value={501}>Juvenile</MenuItem>,
  <MenuItem key={6} value={502}>Juvenile Colon/Colorectal</MenuItem>,
  <MenuItem key={7} value={610}>Juvenile GI Tract</MenuItem>,
  <MenuItem key={8} value={515}>Other</MenuItem>,
  <MenuItem key={9} value={503}>Serrated</MenuItem>,
]

/*
* Mother/Father ancestry manifestations
* */
export const ancestryOptions = [
  {"id": "", "label": "Please Select"},
  {"id": 184, "label": "African (Unknown Origin)"},
  {"id": 185, "label": "African American"},
  {"id": 186, "label": "Albanian"},
  {"id": 187, "label": "Algerian"},
  {"id": 188, "label": "Alsatian"},
  {"id": 336, "label": "Angolan"},
  {"id": 1453, "label": "Argentine"},
  {"id": 189, "label": "Armenian"},
  {"id": 1462, "label": "Aruban"},
  {"id": 190, "label": "Austrian"},
  {"id": 191, "label": "Bangladeshi"},
  {"id": 318, "label": "Bantu"},
  {"id": 192, "label": "Belgian"},
  {"id": 319, "label": "Benin"},
  {"id": 320, "label": "Berber"},
  {"id": 193, "label": "Bermudian"},
  {"id": 1456, "label": "Bolivian"},
  {"id": 321, "label": "Botswana"},
  {"id": 194, "label": "Brazilian"},
  {"id": 322, "label": "Burkino Faso"},
  {"id": 323, "label": "Burundi"},
  {"id": 195, "label": "Cambodian"},
  {"id": 324, "label": "Cameroon"},
  {"id": 196, "label": "Canadian"},
  {"id": 1466, "label": "Caribbean"},
  {"id": 325, "label": "Central African Republic"},
  {"id": 326, "label": "Chad"},
  {"id": 197, "label": "Chilean"},
  {"id": 198, "label": "Chinese"},
  {"id": 199, "label": "Colombian"},
  {"id": 327, "label": "Comoros"},
  {"id": 328, "label": "Congo (Democratic Republic)"},
  {"id": 329, "label": "Congo (Republic of)"},
  {"id": 330, "label": "Cote d'Ivoire"},
  {"id": 200, "label": "Cuban"},
  {"id": 1465, "label": "Curacaoan"},
  {"id": 201, "label": "Czech"},
  {"id": 202, "label": "Danish"},
  {"id": 331, "label": "Djibouti"},
  {"id": 203, "label": "Dominican"},
  {"id": 204, "label": "Dutch"},
  {"id": 1455, "label": "Ecuadorian"},
  {"id": 205, "label": "Egyptian"},
  {"id": 206, "label": "English"},
  {"id": 332, "label": "Equatorial Guinea"},
  {"id": 333, "label": "Eritrea"},
  {"id": 334, "label": "Ethiopia"},
  {"id": 207, "label": "Filipino"},
  {"id": 208, "label": "Finnish"},
  {"id": 209, "label": "French"},
  {"id": 210, "label": "French Canadian"},
  {"id": 1461, "label": "French Guianese"},
  {"id": 335, "label": "Gabon"},
  {"id": 337, "label": "Gambia"},
  {"id": 211, "label": "German"},
  {"id": 338, "label": "Ghana"},
  {"id": 212, "label": "Greek"},
  {"id": 213, "label": "Guatemalan"},
  {"id": 339, "label": "Guinea"},
  {"id": 340, "label": "Guinea-Bissau"},
  {"id": 1458, "label": "Guyanese"},
  {"id": 214, "label": "Hungarian"},
  {"id": 215, "label": "Icelandic"},
  {"id": 216, "label": "Indian"},
  {"id": 217, "label": "Iranian"},
  {"id": 218, "label": "Iraqi"},
  {"id": 219, "label": "Irish"},
  {"id": 383, "label": "Israeli"},
  {"id": 220, "label": "Italian"},
  {"id": 221, "label": "Japanese"},
  {"id": 341, "label": "Kenya"},
  {"id": 222, "label": "Korean"},
  {"id": 223, "label": "Kuwaiti"},
  {"id": 224, "label": "Laotian"},
  {"id": 225, "label": "Latvian"},
  {"id": 226, "label": "Lebanese"},
  {"id": 342, "label": "Lesotho"},
  {"id": 343, "label": "Liberia"},
  {"id": 345, "label": "Libya"},
  {"id": 227, "label": "Lithuanian"},
  {"id": 346, "label": "Madagascar"},
  {"id": 348, "label": "Malawi"},
  {"id": 350, "label": "Mali"},
  {"id": 347, "label": "Mauritania"},
  {"id": 349, "label": "Mauritius"},
  {"id": 228, "label": "Mexican"},
  {"id": 229, "label": "Native American"},
  {"id": 231, "label": "Norwegian"},
  {"id": 232, "label": "Other:"},
  {"id": 311, "label": "Pacific Islands"},
  {"id": 233, "label": "Pakistani"},
  {"id": 234, "label": "Palestinian"},
  {"id": 1460, "label": "Paraguayan"},
  {"id": 235, "label": "Peruvian"},
  {"id": 236, "label": "Polish"},
  {"id": 312, "label": "Polynesian"},
  {"id": 237, "label": "Portuguese"},
  {"id": 238, "label": "Romanian"},
  {"id": 239, "label": "Russian"},
  {"id": 240, "label": "Scandinavian"},
  {"id": 241, "label": "Scottish"},
  {"id": 242, "label": "Slovakian"},
  {"id": 243, "label": "South African"},
  {"id": 244, "label": "Spanish"},
  {"id": 1459, "label": "Surinamese"},
  {"id": 245, "label": "Swedish"},
  {"id": 246, "label": "Swiss"},
  {"id": 247, "label": "Syrian"},
  {"id": 248, "label": "Taiwanese"},
  {"id": 249, "label": "Thai"},
  {"id": 1464, "label": "Tobagonian"},
  {"id": 1463, "label": "Trinidadian"},
  {"id": 250, "label": "Turkish"},
  {"id": 251, "label": "Ukrainian"},
  {"id": 1457, "label": "Uruguayan"},
  {"id": 253, "label": "Venezuelan"},
  {"id": 254, "label": "Vietnamese"},
  {"id": 255, "label": "Welsh"}]