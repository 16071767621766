import {Grid, Typography} from "@mui/material";
import React from "react";
import {Helmet} from "react-helmet";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import DualCounter from "./FamilyTreeComponents/dualCounter";
import "../../Styles/FamilyTree.css";
import {useValidateToken} from "../../Hooks/useValidateToken";
import {useRelations} from "../../Hooks/useRelations";
import {addRelationsToDb} from "../../Redux/relations/relationSlice";
import {useDispatch, useSelector} from "react-redux";
import {filterRelationsIntoArray, filterRelationsWithNoData} from "./Helpers/filterRelations";
import {relationIDs} from "./Helpers/relationsMap";

const TreeList_SelfRelations = () => {
  useValidateToken(true, "history");
  const {relationsState, relationsIsLoading} = useRelations();
  const {relationsUserId, selfRelationId, motherPrId, fatherPrId, relationsDbPosting} = relationsState;
  const usersGender = useSelector((state) => state.users.usersGender);

  const {handleFormNavigation} = useFormNavigation();
  const [selfRelativeState, setSelfRelativeState] = React.useState([]);
  const dispatch = useDispatch();
  return (
    relationsIsLoading
      ? undefined
      : <>
        <Helmet>
          <title>Your Immediate Family</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
          <Grid item>
            <Typography textAlign={"center"} variant="h2" mt={5} mb={5}>
              Enough about you, let's talk about <b className="blue-emphasis">your family</b>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className="questionnaire-content family-tree"
                  onSubmit={(e) => {
                    e.preventDefault();
                    const filtered = filterRelationsWithNoData(selfRelativeState, relationsUserId);
                    const arrToPost = filterRelationsIntoArray(filtered, relationsUserId);
                    dispatch(addRelationsToDb(arrToPost))
                      .then(() => {
                        handleFormNavigation(e);
                      })
                  }}>
              <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                    className="questionnaire-inner-content">
                {!relationsIsLoading &&
                  <>
                    <div className={"family-tree-counters"}>
                      <div className={"family-tree-labels"}>
                        <label className="bold-text"
                               style={{marginBottom: "25px"}}>How many of the following do you have?</label>
                      </div>
                      <br />
                      <DualCounter relationId={relationIDs.Daughter}
                                   side={"NA"}
                                   parentIds={usersGender === "Male" ? {fatherPrId: selfRelationId} : {motherPrId: selfRelationId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState}
                      />
                      <DualCounter relationId={relationIDs.Son}
                                   parentIds={usersGender === "Male" ? {fatherPrId: selfRelationId} : {motherPrId: selfRelationId}}
                                   side={"NA"}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState}
                      />
                      <br />
                      <DualCounter relationId={relationIDs.Brother}
                                   side={"NA"}
                                   parentIds={{motherPrId, fatherPrId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState}
                      />
                      <DualCounter relationId={relationIDs.Sister}
                                   side={"NA"}
                                   parentIds={{motherPrId, fatherPrId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState}
                      />
                      <label className="blue-label">Mom's Side</label>
                      <DualCounter relationId={relationIDs.Half_Brother}
                                   side={"Maternal"}
                                   parentIds={{motherPrId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState} />
                      <DualCounter relationId={relationIDs.Half_Sister}
                                   side={"Maternal"}
                                   parentIds={{motherPrId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState}
                      />
                      <label className="blue-label">Dad's Side</label>
                      <DualCounter relationId={relationIDs.Half_Brother}
                                   side={"Paternal"}
                                   parentIds={{fatherPrId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState}
                                   parentKey={"a" + relationIDs.Half_Brother}//for uniqueness on the page
                      />
                      <DualCounter relationId={relationIDs.Half_Sister}
                                   side={"Paternal"}
                                   parentIds={{fatherPrId}}
                                   relativeState={selfRelativeState}
                                   setRelatives={setSelfRelativeState}
                                   parentKey={"a" + relationIDs.Half_Sister}//for uniqueness on the page
                      />
                    </div>
                  </>
                }
                <BackContinueButtons loading={relationsDbPosting} />
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>
  );
}
export default TreeList_SelfRelations;