import React from "react";
import {CancerHistProvider} from "./Context/90_Cancer";
import {CancerHistoryForm} from "./91_CancerHistoryForm";

const CancerHistory = () => {
  return (
    <CancerHistProvider>
      <CancerHistoryForm />
    </CancerHistProvider>
  );
}

export default CancerHistory;