import React from "react";
import {useDispatch} from "react-redux";
import {manifestationsGeneDelete} from "../../../Redux/manifestations/manifestationSlice";
import {randomId} from "../../../Helpers/utils";

import {Grid, IconButton} from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';

export const dataListElementsNonVus = (<datalist id="geneMutations">
    <option data-opt-val={172}
            value={"BRCA 1/2"}>BRCA 1/2
    </option>
    <option data-opt-val={173}
            value={"ATM"}>ATM
    </option>
    <option data-opt-val={174}
            value={"BRIP 1"}>BRIP 1
    </option>
    <option data-opt-val={175}
            value={"CDH1"}>CDH1
    </option>
    <option data-opt-val={176}
            value={"CDKN2A"}>CDKN2A
    </option>
    <option data-opt-val={177}
            value={"CHEK2"}>CHEK2
    </option>
    <option data-opt-val={178}
            value={"TP53"}>TP53
    </option>
    <option data-opt-val={179}
            value={"OTHER"}>OTHER
    </option>
    <option data-opt-val={180}
            value={"UNKNOWN"}>UNKNOWN
    </option>
    <option data-opt-val={181}
            value={"NF1"}>NF1
    </option>
    <option data-opt-val={182}
            value={"PALB2"}>PALB2
    </option>
    <option data-opt-val={183}
            value={"PTEN"}>PTEN
    </option>
    <option data-opt-val={184}
            value={"RAD51C"}>RAD51C
    </option>
    <option data-opt-val={185}
            value={"RAD51D"}>RAD51D
    </option>
    <option data-opt-val={186}
            value={"STK11"}>STK11
    </option>
    <option data-opt-val={187}
            value={"MSH2"}>MSH2
    </option>
    <option data-opt-val={188}
            value={"MLH1"}>MLH1
    </option>
    <option data-opt-val={189}
            value={"MSH6"}>MSH6
    </option>
    <option data-opt-val={190}
            value={"PMS2"}>PMS2
    </option>
    <option data-opt-val={191}
            value={"EPCAM"}>EPCAM
    </option>
    <option data-opt-val={192}
            value={"NBN"}>NBN
    </option>
    <option data-opt-val={585}
            value={"RET"}>RET
    </option>
  </datalist>
);

export const dataListElementsVus = (<datalist id="geneMutations">
  <option data-opt-val={193} value={"BRCA 1/2"}>BRCA 1/2</option>
  <option data-opt-val={194} value={"ATM"}>ATM</option>
  <option data-opt-val={195} value={"BRIP 1"}>BRIP 1</option>
  <option data-opt-val={196} value={"CDH1"}>CDH1</option>
  <option data-opt-val={197} value={"CDKN2A"}>CDKN2A</option>
  <option data-opt-val={198} value={"CHEK2"}>CHEK2</option>
  <option data-opt-val={199} value={"TP53"}>TP53</option>
  <option data-opt-val={200} value={"OTHER:"}>OTHER:</option>
  <option data-opt-val={201} value={"UNKNOWN"}>UNKNOWN</option>
  <option data-opt-val={202} value={"NF1"}>NF1</option>
  <option data-opt-val={203} value={"PALB2"}>PALB2</option>
  <option data-opt-val={204} value={"PTEN"}>PTEN</option>
  <option data-opt-val={205} value={"RAD51C"}>RAD51C</option>
  <option data-opt-val={206} value={"RAD51D"}>RAD51D</option>
  <option data-opt-val={207} value={"STK11"}>STK11</option>
  <option data-opt-val={208} value={"MSH2"}>MSH2</option>
  <option data-opt-val={209} value={"MLH1"}>MLH1</option>
  <option data-opt-val={210} value={"MSH6"}>MSH6</option>
  <option data-opt-val={211} value={"PMS2"}>PMS2</option>
  <option data-opt-val={212} value={"EPCAM"}>EPCAM</option>
  <option data-opt-val={213} value={"NBN"}>NBN</option>
</datalist>)

const GeneticTestAddendum = (props) => {
  const {isVus, handleInputChange, selfRelationId, existingGenes} = props;
  const dispatch = useDispatch();
  const [genes, setGenes] = React.useState(existingGenes ? existingGenes : [{reactId: "aaaaaaaa"}]);
  const addGene = () => {
    setGenes([...genes, {reactId: randomId() + randomId()}]);
  }

  const removeGene = (reactId) => {
    const geneIndex = genes.findIndex(gene => gene.reactId === reactId);
    if (genes[geneIndex].prmovId) {
      dispatch(manifestationsGeneDelete(selfRelationId, reactId, isVus))
    }
    setGenes(genes.filter(gene => gene.reactId !== reactId));
  }
  const getGeneText = (reactId, isVus) => {
    let result = undefined;
    const gene = genes.find(g => g.reactId === reactId);
    isVus
      ? dataListElementsVus.props.children.forEach((option) => {
        if (option.props["data-opt-val"] === gene.manifestationOptionValueId) {
          result = option.props.value;
        }
      })
      : dataListElementsNonVus.props.children.forEach((option) => {
        if (option.props["data-opt-val"] === gene.manifestationOptionValueId) {
          result = option.props.value;
        }
      })
    return result;
  }

  const geneInput = (id) => {
    return (
      <Grid item key={id} id={"gene-input-container"}>
        <span className={"gene-input-wrapper"} id={"gene-delete"}>
          <input type="text"
                 className={"text-input-small"}
                 name={"geneMutations"}
                 placeholder={"Gene"}
                 list={"geneMutations"}
                 style={{marginTop: "0.5rem"}}
                 value={getGeneText(id, isVus)}
                 id={id}
                 onChange={(e) => handleInputChange(e, selfRelationId, isVus)} />
          {isVus ? dataListElementsVus : dataListElementsNonVus}
          <span className={"gene-input-delete-button"}
                style={{marginTop: "10px"}}
                onClick={(e) => {
                  e.preventDefault();
                  removeGene(id);
                }}>
            &times;
          </span>
        </span>
      </Grid>
    )
  }

  const addButton = (
    <Grid item>
      <IconButton
        color={"secondary"}
        size={"small"}
        disableRipple
        onClick={(e) => {
          e.preventDefault();
          addGene()
        }}>
        <AddBoxIcon />&nbsp;Add
      </IconButton>
    </Grid>)

  return (<>
      <Grid container item xs={11} sm={8} md={5} className={"white-card"} ml={"auto"} mr={"auto"}>
        <Grid container item justifyContent={"space-between"} xs={12}>
          <Grid item>
            <label htmlFor={"geneMutations"}>What gene(s)?</label>
          </Grid>
          {addButton}
        </Grid>
        {(genes.length > 0) && genes.map((gene) => {
          return geneInput(gene.reactId)
        })}
      </Grid>
    </>
  )

}

export default GeneticTestAddendum