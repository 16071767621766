import {Grid, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import React, {useContext} from "react";
import {CancerHist} from "../Context/90_Cancer";
import InputValLimiters from "../Helpers/inputValLimiters";
import {labelAndOptValsByOptId} from "../Helpers/optValObjsCancer";
import {DefaultInputValGetters} from "../Helpers/defaultInputValGetters";
import {allCancers, femaleCancers, maleCancers, guessList, hasMoreValues} from "../Helpers/manifestations";
import {ReactComponent as TrashIcon} from "../../../Resources/Images/Icons/Trash.svg";

const CancerHistoryRow = (props) => {
  const {reactId, existingCancer, typeChangeHandler, colChangeHandler, resultChangeHandler, gender} = props;
  const {deleteCancerHist} = useContext(CancerHist);
  const {existedInDb} = existingCancer ? existingCancer : {existedInDb: false};
  const {getMultiInputModularFieldValue} = DefaultInputValGetters();
  const [microState, setMicroState] = React.useState({})
  const [hasMore, setHasMore] = React.useState([])

  const cancersMap =
    gender === "Male"
      ? [...allCancers, ...maleCancers]
      : [...allCancers, ...femaleCancers]


  const deleteButton = (
    <Grid container item xs={"auto"}>
      <Tooltip title={"Delete this entry"}>
        <button className="trash-button"
                style={{marginTop: "20px"}}
                onClick={() => deleteCancerHist(reactId)}>
          <TrashIcon aria-label={"Delete this entry"} />
        </button>
      </Tooltip>
    </Grid>
  )

  const checkForMoreQuestions = (maniId) => {
    if (hasMoreValues[maniId]) {
      setHasMore(hasMoreValues[maniId]);
    }
    if (!hasMoreValues[maniId]) {
      setHasMore([]);
    }
  }


  React.useEffect(() => {
    if (existedInDb) {
      if (Object.keys(existingCancer).length > 0) {
        setMicroState((prevState) => {
          return {
            ...prevState,
            age: existingCancer.ageOfDiagnosis,
            type: existingCancer.manifestationId,
            confidence: existingCancer.confidenceInAgeOfDiagnosis,
          }
        })
      }
      if (existingCancer.prmOptVals && existingCancer.prmOptVals.length > 0) {
        let more = [];
        existingCancer.prmOptVals
          .forEach((val) => {
            setMicroState((prevState) => {
              return {...prevState, [val.manifestationOptionId]: val.manifestationOptionValueId}
            })
            more.push(val.manifestationOptionId)
          })
        setHasMore(more)
      }
      if (existingCancer.manifestationId) {
        checkForMoreQuestions(existingCancer.manifestationId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (<>
      <Grid container item xs={12} flexDirection={"row"} style={{maxWidth: "780px"}} mt={1} className={"white-card"}
            id={`cancer-${reactId}`}>
        <Grid spacing={2} container item xs columns={15}>
          <Grid item xs={"auto"}>
            <InputLabel id={`${reactId}-cancer-type`}>Type of Cancer</InputLabel>
            <Select name={`${reactId}-cancer-type`}
                    labelId={`${reactId}-cancer-type`}
                    required
                    displayEmpty
                    value={microState.type ? microState.type : ""}
                    id={`${reactId}`}
                    onChange={(e) => {
                      setMicroState({...microState, type: e.target.value});
                      checkForMoreQuestions(e.target.value);
                      typeChangeHandler(e)
                    }}>
              <MenuItem value={""}>Please select</MenuItem>
              {
                cancersMap
                  .sort((a, b) => (a.label > b.label) ? 1 : -1)
                  .map((cancer, index) => {
                    return (<MenuItem key={index}
                                      value={cancer.id}>{cancer.label}</MenuItem>)
                  })
              }
            </Select>
          </Grid>
          <Grid item xs={"auto"}>
            <InputLabel htmlFor={`${reactId}-ageOfDiagnosis`} id={`${reactId}-ageDiag`}>
              Age of Diagnosis
            </InputLabel>
            <TextField
              aria-labelledby={`${reactId}-ageDiag`}
              style={{maxWidth: "150px"}}
              name={`${reactId}-ageOfDiagnosis`}
              required
              inputProps={{inputMode: 'numeric', pattern: '[0-9]*', title: "Numbers only"}}
              id={`${reactId}-ageOfDiagnosis`}
              placeholder="Age"
              value={microState.age ? microState.age : ""}
              onChange={(e) => {
                setMicroState({...microState, age: e.target.value});
                colChangeHandler(e)
              }} />
          </Grid>
          <Grid item xs={"auto"}>
            <InputLabel id={`${reactId}-confidence`}>
              How sure are you of the age?
            </InputLabel>
            <Select
              name={`${reactId}-confidenceInAgeOfDiagnosis`}
              labelId={`${reactId}-confidence`}
              displayEmpty
              id={`${reactId}-confidenceInAgeOfDiagnosis`}
              value={microState.confidence ? microState.confidence : ""}
              onChange={(e) => {
                setMicroState({...microState, confidence: e.target.value})
                colChangeHandler(e)
              }}>
              {guessList}
            </Select>
          </Grid>
          {
            (hasMore.length > 0) &&
            hasMore.map((id, index) => {
              return (
                <Grid item key={`cancer-more-${reactId}-${index}`}>
                  <InputLabel id={`${reactId}-${id}`}>{labelAndOptValsByOptId[id].label}</InputLabel>
                  <Select labelId={`${reactId}-${id}`}
                          displayEmpty
                          name={`${reactId}-${id}`}
                          id={`${reactId}-${id}`}
                          value={microState[id] ? microState[id] : ""}
                          onChange={(e) => {
                            setMicroState({...microState, [id]: e.target.value})
                            resultChangeHandler(e)
                          }}>
                    {labelAndOptValsByOptId[id].select}
                  </Select>
                </Grid>
              )
            })
          }
        </Grid>
        {deleteButton}
      </Grid>
    </>
  )
}

export default CancerHistoryRow;