import React from "react";
import {BiopsyProvider} from "./Context/80_Biopsy";
import {BiopsyForm} from "./81_BiopsyForm";

const BiopsyHistory = () => {
  return (
    <BiopsyProvider>
      <BiopsyForm />
    </BiopsyProvider>
  );
}

export default BiopsyHistory;