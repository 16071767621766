import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import store from "../Redux/store/store";

import LandingPageFamilyPhotoGrid from "../Components/LandingPageFamilyPhotoGrid";

import {fetchUserData, loginUser} from "../Redux/users/userSlice";

import useExitPrompt from "../Hooks/useExitPrompt";

import {Button, Grid, IconButton, Input, InputAdornment, useMediaQuery, useTheme} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Visibility, VisibilityOff} from "@mui/icons-material";

const Login = (props) => {
  const {buttonLoad} = props;
  const [, setShowExitPrompt] = useExitPrompt(false);
  const [showPass, setShowPass] = useState(false);
  const {logoutMessage, loginError} = store.getState().users;
  const theme = useTheme();
  const hideImageText = useMediaQuery(theme.breakpoints.down("md"));
  const handleLogin = async (e) => {
    e.preventDefault();
    const {email, password} = e.target;
    const credentials = {email: email.value, password: password.value};
    await store
      .dispatch(loginUser(credentials))
      .then((res) => {
        store.dispatch(fetchUserData(store.getState().users.patientId));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleShowPass = (e) => {
    e.preventDefault();
    setShowPass(!showPass);
  }

  useEffect(() => {
    setShowExitPrompt(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>Welcome to INHERET®</title>
      </Helmet>
      <Grid container mt={5}>
        <LandingPageFamilyPhotoGrid />
        <Grid item xs={12} sm={12} md={6} className="loginForm"
              sx={hideImageText ? {borderRadius: "15px", zIndex: 5} : {zIndex: 5}}>
          <img className="img-logo"
               src={require("../Resources/Images/INHERET/inheret-logo.png")}
               alt="INHERET Logo" /><br />
          <form onSubmit={(e) => handleLogin(e)}>
            <br /><br /><br />
            <h4 className="">Log In</h4><br />
            <label className="margintop20px">
              Email Address <br />
              {/**
               *  TODO: add a "remember email" checkbox
               */}
            </label>
            <Input name="email"
                   type="email"
                   placeholder="Email"
                   disableUnderline={true}
                   className="text-input-wide" />
            <br /><br />
            <label>
              Password <br />
            </label>
            <Input name="password"
                   type={`${showPass ? "text" : "password"}`}
                   placeholder="Password"
                   className="text-input-wide"
                   disableUnderline={true}
                   endAdornment={
                     <InputAdornment position={"end"}>
                       <IconButton onClick={handleShowPass}>
                         {showPass ? <Visibility /> : <VisibilityOff />}
                       </IconButton>
                     </InputAdornment>
                   }
            />
            <br /><br />
            {
              (logoutMessage || loginError) &&
              <div className="error-text">&nbsp;{`${logoutMessage ? logoutMessage : loginError}`}</div>
            }
            <Grid container flexDirection={"column"}>
              <Link to={"/forgot-password"}>Forgot Password?</Link>
              <Grid item xs={4} sx={{marginLeft: "auto"}}>
                <Button type="submit"
                        variant="contained"
                        name="next"
                        className={buttonLoad ? " button-loading" : ""}>
                  <span className="backcontinue-button-text">Log In </span>
                  <ArrowForwardIcon className="backcontinue-button-text" sx={{ml: 1}} />
                </Button>
              </Grid>
              <br /><br />
            </Grid>
            <br /><br />
            <div style={{margin: "0 auto", width: "fit-content"}}>Need an account? <Link to="/signup">Register
              today</Link></div>
          </form>
        </Grid>
      </Grid>
    </>)
};

export default Login;
