import React from "react";
import {OtherProvider} from "./TreeList_OtherContext";
import {RelationOtherForm} from "../FamilyTreeComponents/treeList_OtherForm";

export const OtherHistory = (props) => {
  const {relation, relationReactId, patientRelationId, side, gender, existing, isDeceased} = props;
  return (
    <OtherProvider>
      <RelationOtherForm relation={relation}
                         relationReactId={relationReactId}
                         patientRelationId={patientRelationId}
                         side={side}
                         gender={gender}
                         existing={existing}
                         isDeceased={isDeceased} />
    </OtherProvider>
  );
}