// global stylesheets
import {Container, ThemeProvider} from "@mui/material";
import React, {Suspense} from "react";
import {useSelector} from "react-redux";

// necessary libraries for the app to work
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import "./App.css";
import LoadingSpinner from "./Components/LoadingSpinner";

// components
import Footer from "./Components/MainPageLayout/Footer";
import TitleAnnouncer from "./Components/MainPageLayout/TitleAnnouncer";
import RootBoundary from "./errors/ErrorBoundary";
import "./index.css";
import {outerTheme} from "./materialConfig";

// pages
import Dashboard from "./Pages/Dashboard";
import ExpiredPasswordReset from "./Pages/ExpiredPasswordReset";
import ForgotPassword from "./Pages/ForgotPassword";
import ForgotPasswordReset from "./Pages/ForgotPasswordReset";
import Layout from "./Pages/Layout";
import Login from "./Pages/Login";
import AccountSettings from "./Pages/PatientAccount/AccountSettings";
import InsuranceInformation from "./Pages/PatientAccount/InsuranceInformation";
import PersonalInformation from "./Pages/PatientAccount/PersonalInformation";
import PatientAccountDetails from "./Pages/PatientAccountDetails";
import Questionnaire from "./Pages/Questionnaire";
import Signup from "./Pages/Signup";
import store from "./Redux/store/store";
import {fetchUserData, validateToken} from "./Redux/users/userSlice";

// variable/temporary (for testing/demo/dev purposes)

const App = () => {
  const usersState = useSelector(state => state.users);
  // TODO documentation on what does what, why, and how the API is structured
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const getData = async () => {
      if (localStorage.getItem("userToken-v1") != null) {
        await store.dispatch(validateToken(localStorage.getItem("userToken-v1"))).then(async () => {
          await store.dispatch(fetchUserData(store.getState().users.usersPatientId));
        })
      }
    }
    // noinspection JSIgnoredPromiseFromCall
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {usersHasValidToken, usersIsLoggedIn, rootLoading, usersRegisterLoading} = usersState

  // The delay keeps the login page from flashing for a split second before the token is validated
  // also cool spinny loading thing
  setTimeout(() => {
    setLoading(false);
    let spinnerExists = document.body.contains(document.getElementById("loading-spinner"));
    if (spinnerExists) {
      document.getElementById("loading-spinner").style.visibility = "hidden";
    }
  }, 1250)

  const unAuthenticatedRouter = createBrowserRouter([
    {path: "/", element: <Login buttonLoad={rootLoading} />},
    {path: "/signup", element: <Signup buttonLoad={usersRegisterLoading} />},
    {path: "/forgot-password", element: <ForgotPassword />},
    {path: "/reset-password", element: <ForgotPasswordReset />},
    {path: "*", element: <Navigate to="/" />},
  ]);

  const loginForm = (
    <>
      <ThemeProvider theme={outerTheme}>
        <Container>
          <RouterProvider router={unAuthenticatedRouter}>
            <TitleAnnouncer />
          </RouterProvider>
        </Container>
        <Footer />
      </ThemeProvider>
    </>)

  const authenticatedRouter = createBrowserRouter([
    {
      path: "/", element: <Layout />, children:
        [
          {
            errorElement: <RootBoundary />,
            children:
              [{path: "/", index: true, element: <Dashboard />,},
                {path: "/home", element: <Dashboard />},
                {path: "/health-questionnaire/*", element: <Questionnaire />,},
                {
                  path: "/account",
                  element: <PatientAccountDetails />,
                  children: [
                    {path: "/account/settings", element: <AccountSettings />},
                    {path: "/account/insurance", element: <InsuranceInformation />},
                    {path: "/account/personal", element: <PersonalInformation />},
                  ]
                },
                {path: "/expired-password", element: <ExpiredPasswordReset />},
                {path: "*", element: <Navigate to="/home" />},
              ]
          }]
    }]);

  const mainApp = (
    <>
      <ThemeProvider theme={outerTheme}>
        <Suspense fallback={<LoadingSpinner />}> {/* TODO add basic spinner */}
          <RouterProvider router={authenticatedRouter}>
            <TitleAnnouncer />
          </RouterProvider>
        </Suspense>
        <Footer />
      </ThemeProvider>
    </>);

  if (!usersIsLoggedIn || !usersHasValidToken) {
    return (loading ? <LoadingSpinner /> : loginForm);
  } else if (usersIsLoggedIn && usersHasValidToken) {
    return (loading ? <LoadingSpinner /> : mainApp);
  }
}

export default App;
