import {Card, CardHeader, Grid, Skeleton} from "@mui/material";
import React from "react";

export const loadingArrayMap =
  [
    "a",
    2, 3, 4,
    "a",
    6, 7, 8,
    "a",
    9, 10, 11,
    "a",
    12, 13, 14,
    "a",
    16, 17, 18,
    "a",
    19, 20, 21,
    "a",
    22, 23, 24
  ];

const RelationCardSkeleton = () => {
  return loadingArrayMap.map((item, index) => {
    if (typeof (item) === "string") {
      return (<Grid key={index} container justifyContent={"center"} my={5}>
        <Skeleton variant={"text"} width={"80%"} sx={{fontSize: "2rem"}} />
        <Skeleton variant={"text"} width={"80%"} sx={{fontSize: "1rem"}} />
      </Grid>)
    }
    return (
      <Grid container key={index}>
        <Card variant={"family-tree"} sx={{mt: 0.5}}>
          <CardHeader
            avatar={<Skeleton animation={"wave"}
                              sx={{bgcolor: "#D8EEF2"}}
                              variant={index % 2 === 0 ? "circular" : "rounded"}
                              width={40} height={40} />}
            title={<Skeleton animation={"wave"} height={20} width={"80%"}
                             style={{marginBottom: 6}} />}
            subheader={
              <Grid container alignItems="center" columnSpacing={2}>
                <Grid item>
                  <Skeleton animation={"wave"} height={20} width={120} />
                </Grid>
                <Grid item>
                  <Skeleton animation={"wave"} height={20} width={120} />
                </Grid>
              </Grid>
            }
            action={
              <Grid container spacing={3}>
                <Grid item>
                  <Skeleton animation={"wave"}
                            sx={{bgcolor: "#D8EEF2"}}
                            variant={"rectangular"}
                            width={24} height={24} />
                </Grid>
                <Grid item>
                  <Skeleton animation={"wave"}
                            sx={{bgcolor: "#D8EEF2"}}
                            variant={"circular"}
                            width={24} height={24} />
                </Grid>
              </Grid>
            }
          />
          <hr />
          <Grid container justifyContent={"center"}>
            <Skeleton animation={"wave"} height={20} width={"85%"} style={{marginBottom: 6}} />
            <Skeleton animation={"wave"} height={20} width={"85%"} style={{marginBottom: 6}} />
          </Grid>
        </Card>
      </Grid>)
  });
}

export default RelationCardSkeleton;