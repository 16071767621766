module.exports = {
  URLS: {
    LOCAL_DEV_API: "https://localhost:7015",
  },
  AXIOS_CONFIG: {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("userToken-v1"),
    }
  }
}

/* API ENDPOINTS:
* Authentication:
* POST /api/authenticate/login/{email}?pass={password}
* GET /api/authenticate/validate/token/{token}
* GET /api/authenticate/validate/pass/{pass}?hashedPass={hashedPass}
* 
* Users:
* GET /api/users
* POST /api/users
* GET /api/users/{id}
* DELETE /api/users/{id}
* PUT /api/users/{id}
* 
* Manifestations:
* GET /api/manifestations
* POST /api/manifestations
* GET /api/manifestations/{id}
* DELETE /api/manifestations/{id}
* PUT /api/manifestations/{id}
* 
* todo: fix this
* */