// noinspection JSUnresolvedVariable,DuplicatedCode

import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Button, Grid, IconButton, Input, InputAdornment, useMediaQuery, useTheme} from "@mui/material";
import React, {useState} from "react";
import {Helmet} from "react-helmet";

import "../Styles/Login.css"
import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import LandingPageFamilyPhotoGrid from "../Components/LandingPageFamilyPhotoGrid";
import PasswordProgressBar from "../Components/PasswordProgressBar";
import {scorePassword, validatePassReqs, validatePasswordComplexity} from "../Helpers/passwordComplexity";
import {submitChangedPwd} from "../Redux/users/userSlice";

const ForgotPasswordReset = () => {
  // TODO: check if that there request is valid (not expired, etc.)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const hideImageText = useMediaQuery(theme.breakpoints.down("md"));

  const [searchParams] = useSearchParams();
  const xUserPwdResetGuid = Object.fromEntries([...searchParams]);

  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});

  const [passReqs, setPassReqs] = useState({});
  const [passScore, setPassScore] = useState({text: "Weak", score: 0, className: "progress-bar-danger"});
  const [showPass, setShowPass] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (successMessage) {
      navigate("/");
      return;
    }

    let validPassword = validate();
    let {meetsAll} = validatePassReqs(inputs["newPassword"]);
    if (!meetsAll) {
      return;
    }
    if (validPassword && xUserPwdResetGuid["key"] && xUserPwdResetGuid["id"]) {
      const {newPassword} = inputs;
      const postData = {id: xUserPwdResetGuid["id"], guid: xUserPwdResetGuid["key"], newPassword: newPassword};

      let result = await dispatch(submitChangedPwd(postData));
      if (!result.payload) {
        setErrorMessage("There was an error changing your password. Please try again.");
        return;
      }
      if (result.payload.passWasReset) {
        setSuccessMessage("Your password has been changed successfully. You may now log in with your new password.");
        document.getElementById("newPassword").value = "";
        document.getElementById("confirmNewPassword").value = "";
        setInputs({});
      }
    }
  }

  const handleChange = (e) => {
    setErrors({...errors, [e.target.name]: ""});
    setInputs({...inputs, [e.target.name]: e.target.value});
  }

  const handlePassChange = (e) => {
    setErrors({...errors, [e.target.name]: ""});
    let passStrength = validatePasswordComplexity(e.target.value);
    const {requirements} = validatePassReqs(e.target.value);
    setPassReqs(requirements);
    let status = scorePassword(passStrength);
    setPassScore(status);
    setInputs({...inputs, [e.target.name]: e.target.value});
  }

  const handleShowPass = (e) => {
    e.preventDefault();
    setShowPass(!showPass);
  }

  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!inputs["newPassword"]) {
      isValid = false;
      errors["newPassword"] = "Please enter a new password.";
    }

    if (!inputs["confirmNewPassword"]) {
      isValid = false;
      errors["confirmNewPassword"] = "Please confirm your new password.";
    }

    if (inputs["newPassword"] !== inputs["confirmNewPassword"]) {
      isValid = false;
      errors["confirmNewPassword"] = "Passwords do not match.";
    }

    setErrors(errors);
    return isValid;
  }

  return (
    <>
      <Helmet>
        <title>Forgotten Password Reset</title>
      </Helmet>
      <Grid container mt={5}>
        <LandingPageFamilyPhotoGrid />
        <Grid item xs={12} sm={12} md={6} className="loginForm"
              sx={hideImageText ? {borderRadius: "15px", zIndex: 5} : {zIndex: 5}}>
          <img className="img-logo"
               src={require("../Resources/Images/INHERET/inheret-logo.png")}
               alt="INHERET Logo" /><br />
          <Grid mt={5}>
            <h4>Password Reset</h4>
            <form onSubmit={handleSubmit}>
              <label className="margintop20px">
                New Password
              </label>
              <Input name="newPassword"
                     id="newPassword"
                     type={`${showPass ? "text" : "password"}`}
                     placeholder="New Password"
                     className="text-input-wide"
                     onChange={(e) => {handlePassChange(e)}}
                     disableUnderline
                     endAdornment={
                       <InputAdornment position={"end"}>
                         <IconButton onClick={handleShowPass}>
                           {showPass ? <Visibility /> : <VisibilityOff />}
                         </IconButton>
                       </InputAdornment>
                     }
              /> {errors.newPassword && <div className="error-text">{errors.newPassword}</div>}
              <br /><br />
              <label>Confirm New Password</label>
              <Input name="confirmNewPassword"
                     id="confirmNewPassword"
                     type={`${showPass ? "text" : "password"}`}
                     placeholder="Confirm New Password"
                     className="text-input-wide"
                     onChange={(e) => {handleChange(e)}}
                     disableUnderline
                     endAdornment={
                       <InputAdornment position={"end"}>
                         <IconButton onClick={handleShowPass}>
                           {showPass ? <Visibility /> : <VisibilityOff />}
                         </IconButton>
                       </InputAdornment>
                     }
              /> <br />
              {errors.confirmNewPassword && <div className="error-text">{errors.confirmNewPassword}</div>}
              <Grid item xs={12} mt={5}>
                {
                  passScore.score > 0 &&
                  <Grid item xs={6}>
                    <PasswordProgressBar passScore={passScore} />
                  </Grid>
                }
                <div className="error-text underline">Your password must contain:</div>
                {passReqs.hasLowercase
                  ? <div className="pass-req">&#10003; A lowercase character</div>
                  : <div className="pass-req-not">A lowercase character</div>}
                {passReqs.hasUppercase
                  ? <div className="pass-req">&#10003; An uppercase character</div>
                  : <div className="pass-req-not">An uppercase character</div>}
                {passReqs.hasNumber
                  ? <div className="pass-req">&#10003; A number</div>
                  : <div className="pass-req-not">A number</div>}
                {passReqs.hasSpecial
                  ? <div className="pass-req">&#10003; A special character ~ ` ! ? @ # $ % ^ & * , - _</div>
                  : <div className="pass-req-not">A special character ~ ` ! ? @ # $ % ^ & * , - _</div>}
                {passReqs.hasLength
                  ? <div className="pass-req">&#10003; At least 8 characters</div>
                  : <div className="pass-req-not">At least 8 characters</div>}
                <div className="error-text underline">Your password cannot contain:</div>
                {passReqs.hasIllegal
                  ? <div className="pass-req-not">Illegal characters: {`/ \\ ( ) < > [ ]`} </div>
                  : <div className="pass-req"> &#10003; Illegal characters: {`/ \\ ( ) < > [ ]`}</div>}
              </Grid>

              <br />
              {successMessage && <div className="success-text">{successMessage}</div>}
              {errorMessage && <div className="error-text">{errorMessage}</div>}
              <br />
              <Button variant={"contained"}
                      type={"submit"}
                      className={"main-button"}>
                <span className={"backcontinue-button-text"}>
                  {successMessage
                    ? "Return to Login"
                    : "Change password"}</span>
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>);
}

export default ForgotPasswordReset;