// noinspection JSUnresolvedVariable

import {createSlice, createAsyncThunk, createEntityAdapter} from "@reduxjs/toolkit";
import axios from "axios";
import store from "../store/store";
import {validateToken} from "../users/userSlice";
import {apiSlice} from "../api/apiSlice";
import {randomId} from "../../Helpers/utils";

let GLOBAL = require("../../Helpers/globals");

export const manifestationsAdapter = createEntityAdapter(
  {
    selectId: (manifestation) => parseInt(manifestation.manifestationId),
  });

const initialState = manifestationsAdapter.getInitialState({

  manifestationsFetchLoading: false,
  manifestationsButtonDisabled: false,
  manifestationsStatus: "idle",
  manifestationsRelationId: null,
  manifestationsDirty: false,
  manifestationsDbPostLoading: false,
  manifestationsAxiosConfig: null,
});

export const fetchManifestations = createAsyncThunk("manifestations/fetchManifestations", async (relationId) => {
  const response = await axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/manifestations/${relationId}`, store.getState().manifestations.manifestationsAxiosConfig);
  return response.data;
});

const manifestationsSlice = createSlice({
  name: "manifestations",
  initialState,
  reducers: {
    manifestationUpsert: {
      reducer(state, action) {
        state.manifestationsStatus = "idle";
        const {
          selectedManiId,
          relatedManis,
          relationId,
          quantity,
          da,
          date,
          dac,
        } = action.payload;
        // existing manifestation objects at redux level
        const maniIds = [];
        state.ids.forEach((id) => maniIds.push(parseInt(id)));
        let maniExists = false;
        // TODO ...this...look at it 😂
        for (let i in relatedManis) {
          // check if redux has the manifestation or one of its related manifestations
          // e.g. patient switches between "currentSmoker" or "pastSmoker" or "neverSmoked"
          // only one of those manifestations needs to exist in the database
          if (maniIds.includes(relatedManis[i])) {
            maniExists = true;
            state.manifestationsDirty = true;

            manifestationsAdapter.updateOne(state, {
              id: relatedManis[i],
              changes: {
                patientRelationId: relationId,
                manifestationId: selectedManiId,
                quantity: quantity,
                manifestationDate: date,
                ageOfDiagnosis: da,
                confidenceInAgeOfDiagnosis: dac,
                edited: true,
              }
            })
            break;
          }
        }
        // if not, add it
        if (!maniExists) {
          state.manifestationsDirty = true;
          manifestationsAdapter.addOne(state, {
            manifestationId: selectedManiId,
            patientRelationId: relationId,
            quantity: quantity,
            edited: true,
            prmOptVals: [],
          })
        }
      },
      prepare(selectedManiId, relatedManis, relationId) {
        // related manifestations gets sent as comma-delimited string, split into array
        relatedManis = relatedManis.split(",");
        // related manifestations need to be integers to compare to existing state
        let relatedManisInts = [];
        // if there's related manifestations, start loop
        if (relatedManis.length > 0) {
          // loop through and convert strings to integers
          for (let i in relatedManis) {
            relatedManisInts.push(parseInt(relatedManis[i]));
          }
        }

        return {
          payload: {
            selectedManiId: selectedManiId,
            relatedManis: relatedManisInts || [],
            relationId: relationId,
            quantity: 1,
          },
        }
      }
    },
    manifestationColUpdate: {
      reducer(state, action) {
        state.manifestationsStatus = "idle";
        const {selectedManiId, colName, colValue} = action.payload;

        const maniIds = [];

        state.ids.forEach((id) => maniIds.push(parseInt(id)));
        let maniExists = false;

        if (maniIds.includes(selectedManiId)) {
          maniExists = true;
          state.manifestationsDirty = true;
          manifestationsAdapter.updateOne(state, {
            id: selectedManiId,
            changes: {
              [colName]: colValue,
              edited: true,
            }
          });
        }
        if (!maniExists) {
          throw new Error("Manifestation does not exist in state");
        }
      },
      prepare(selectedManiId, colData) {
        let colName = colData.colName;
        let colVal;
        switch (colName) {
          case "quantity":
            colVal = parseInt(colData.colValue);
            break;
          case "ageOfDiagnosis":
            colVal = parseInt(colData.colValue);
            break;
          case "manifestationDate":
            colVal = colData.colValue;
            break;
          case "confidenceInAgeOfDiagnosis":
            break;
          default:
            break;
        }
        return {
          payload: {
            selectedManiId: selectedManiId,
            colName: colName,
            colValue: colVal,
          },
        }
      },
    },
    manifestationsRemove: {
      reducer(state, action) {
        state.manifestationsStatus = "idle";
        const {manifestationId} = action.payload;
        const maniIds = [];
        state.ids.forEach((id) => maniIds.push(parseInt(id)));

        if (maniIds.includes(manifestationId)) {
          state.manifestationsDirty = true;
          manifestationsAdapter.removeOne(state, manifestationId);
        }
      },
      prepare(manifestationId) {
        return {
          payload: {
            manifestationId: parseInt(manifestationId),
          },
        }
      }
    },
    manifestationOptValsUpsert: {
      reducer(state, action) {
        state.manifestationsStatus = "idle";
        const {optVals} = action.payload;
        const maniIds = [];
        state.ids.forEach((id) => maniIds.push(parseInt(id)));
        const optValsByMani = {};

        if (optVals) {
          optVals.forEach((optVal) => {
            optValsByMani[`${optVal.attachesToManifestation}`]
              ? optValsByMani[`${optVal.attachesToManifestation}`].push(optVal)
              : optValsByMani[`${optVal.attachesToManifestation}`] = [optVal];
          })

          for (let key in optValsByMani) {
            if (maniIds.includes(parseInt(key))) {
              state.manifestationsDirty = true;
              manifestationsAdapter.updateOne(state, {
                id: parseInt(key),
                changes: {
                  prmOptVals: optValsByMani[key],
                  edited: true,
                }
              })
            }
          }
        }
      },
      prepare(optionValues) {
        return {
          payload: {
            optVals: optionValues,
          },
        }
      }
    },
    manifestationRadioUpsert: {
      reducer(state, action) {
        state.manifestationsStatus = "idle";
        // For radio button, get both possible manifestations for Yes/No question
        const {selected, opposite, relationId} = action.payload;
        const maniIds = [];
        state.ids.forEach((id) => maniIds.push(parseInt(id)));
        let maniExists = false;
        // Search for the opposing manifestation input, if exists, change it to selected
        if (maniIds.includes(opposite)) {
          maniExists = true;
          state.manifestationsDirty = true;
          manifestationsAdapter.updateOne(state, {
            id: opposite,
            changes: {
              manifestationId: selected,
              ageOfDiagnosis: null,
              confidenceInAgeOfDiagnosis: null,
              manifestationDate: null,
              quantity: 1,
              edited: true,
            }
          })
        }
        if ((maniIds.includes(opposite) && selected === 161)) {
          maniExists = true;
          state.manifestationsDirty = true;
          manifestationsAdapter.updateOne(state, {
            id: opposite,
            changes: {
              manifestationId: selected,
              ageOfDiagnosis: null,
              confidenceInAgeOfDiagnosis: null,
              manifestationDate: null,
              quantity: 1,
              edited: true,
              prmOptVals: [
                {
                  attachesToManifestation: opposite,
                  manifestationOptionId: 66,
                  manifestationOptionValueId: 578,
                }
              ],
            }
          })
        }
        if ((maniIds.includes(opposite) && selected === 4457)) {
          maniExists = true;
          state.manifestationsDirty = true;
          manifestationsAdapter.updateOne(state, {
            id: opposite,
            changes: {
              manifestationId: selected,
              ageOfDiagnosis: null,
              confidenceInAgeOfDiagnosis: null,
              manifestationDate: null,
              quantity: 1,
              edited: true,
              prmOptVals: [
                {
                  attachesToManifestation: opposite,
                  manifestationOptionId: 66,
                  manifestationOptionValueId: 577,
                }
              ],
            }
          })
        }
        // For gene mutations
        if (!maniExists && selected === 161) {
          state.manifestationsDirty = true;
          manifestationsAdapter.addOne(state, {
            manifestationId: selected,
            patientRelationId: relationId,
            prmOptVals: [
              {
                attachesToManifestation: selected,
                manifestationOptionId: 66,
                manifestationOptionValueId: 577,
              }
            ],
            quantity: 1,
            edited: true,
          })
        }
        if (!maniExists && selected === 4457) {
          state.manifestationsDirty = true;
          manifestationsAdapter.addOne(state, {
            manifestationId: selected,
            patientRelationId: relationId,
            prmOptVals: [
              {
                attachesToManifestation: selected,
                manifestationOptionId: 66,
                manifestationOptionValueId: 578,
              }
            ],
            quantity: 1,
            edited: true,
          })
        }
        if (!maniExists) {
          state.manifestationsDirty = true;
          manifestationsAdapter.addOne(state, {
            manifestationId: selected,
            patientRelationId: relationId,
            quantity: 1,
            edited: true,
          })
        }
      },
      prepare(selected, opposite, relationId) {
        return {
          payload: {
            selected: parseInt(selected),
            opposite: parseInt(opposite),
            relationId: relationId,
          },
        }
      }
    },
    manifestationsGeneticsUpsert: {
      reducer(state, action) {
        state.manifestationsStatus = "idle";
        let existed = false;
        const {relationId, manifestationId, manifestationOptionValueId, reactId, isVus} = action.payload;

        state.manifestationsDirty = true;
        if (!relationId || !manifestationId || !manifestationOptionValueId) {
          return;
        }

        if (state.entities[manifestationId].prmOptVals.length > 1) {
          state.entities[manifestationId].prmOptVals.forEach((optVal, index) => {
            if (Object.values(optVal).includes(reactId)) {
              existed = true;
              manifestationsAdapter.updateOne(state, {
                id: manifestationId,
                changes: {
                  prmOptVals: [
                    ...state.entities[manifestationId].prmOptVals.slice(0, index),
                    {
                      attachesToManifestation: manifestationId,
                      manifestationOptionId: isVus ? 39 : 38,
                      manifestationOptionValueId: manifestationOptionValueId,
                      reactId: reactId,
                    },
                  ]
                }
              });
            }
          })
        }
        if (!existed) {
          manifestationsAdapter.updateOne(state, {
            id: manifestationId,
            changes:
              {
                patientRelationId: relationId,
                prmOptVals: [
                  ...state.entities[manifestationId].prmOptVals,
                  {
                    attachesToManifestation: manifestationId,
                    manifestationOptionId: isVus ? 39 : 38,
                    manifestationOptionValueId,
                    reactId: reactId,
                  }
                ],
                edited: true,
              }
          })
        }
      },
      prepare(relationId, maniIdKGM, optValId, reactId, isVus) {
        return {
          payload: {
            relationId: relationId,
            manifestationId: maniIdKGM,
            manifestationOptionValueId: optValId,
            reactId,
            isVus,
          },
        }
      },
    },
    manifestationsGeneDelete: {
      reducer(state, action) {
        const {relationId, manifestationId, reactId} = action.payload;
        state.manifestationsDirty = true;
        if (!relationId || !manifestationId || !reactId) {
          return;
        }

        if (state.entities[manifestationId].prmOptVals.length > 1) {
          state.entities[manifestationId].prmOptVals.forEach((optVal, index) => {
              if (optVal.reactId === reactId) {
                state.entities[manifestationId].prmOptVals[index].markForDeletion = true;
                manifestationsAdapter.updateOne(state, {
                  id: manifestationId,
                  changes: {
                    edited: true
                  }
                })
              }
            }
          )
        }
      },
      prepare(relationId, reactId, isVus) {
        return {
          payload: {
            relationId: relationId,
            manifestationId: isVus ? 164 : 161,
            reactId: reactId,
          }
        }
      }
    },
  },
  extraReducers:
    builder => {
      builder
        .addCase(validateToken.fulfilled, (state, action) => {
          if (!state.manifestationsAxiosConfig) {
            state.manifestationsAxiosConfig = {headers: {Authorization: `Bearer ${action.payload.token}`}}
          }
        })
        .addCase(fetchManifestations.pending, (state, action) => {
          state.manifestationsFetchLoading = true;
        })
        .addCase(fetchManifestations.fulfilled, (state, action) => {
          Object.keys(action.payload).forEach((key) => {
            action.payload[key]["reactId"] = randomId() + randomId();
            action.payload[key]["edited"] = false;
            action.payload[key]["existedInDb"] = true;
            if (action.payload[key].prmOptVals.length > 0) {
              if ((action.payload[key].manifestationId === 161) || (action.payload[key].manifestationId === 164)) {
                action.payload[key].prmOptVals.forEach((optVal) => {
                  if ((optVal.manifestationOptionId === 38) || (optVal.manifestationOptionId === 39)) {
                    optVal.reactId = randomId() + randomId();
                  }
                })
              }
              action.payload[key].prmOptVals.forEach((optVal) => {
                optVal.attachesToManifestation = parseInt(action.payload[key].manifestationId);
              })
            }
          });
          manifestationsAdapter.setAll(state, action.payload);
          state.manifestationsRelationId = action.payload[0].patientRelationId;
          state.manifestationsFetchLoading = false;
          const manis = action.payload;
          let removeManisKeys = [];
          manis.filter((man) => {
            const {manifestationLookupCategory: lookupId, manifestationId: maniId} = man;
            if ((lookupId === 1749 &&
              (!(maniId !== 4524) &&
                !(maniId !== 4525))) ||
              (lookupId === 1752 &&
                (!(maniId === 161) &&
                  !(maniId === 164))) ||
              (lookupId === 1970 &&
                (!(maniId === 4522) &&
                  !(maniId === 4523)))) {
              removeManisKeys.push(man["manifestationId"])
            }
          })
          manifestationsAdapter.removeMany(state, removeManisKeys);
        })
        .addCase(fetchManifestations.rejected, (state, action) => {
          // TODO: handle server error/failure
          state.manifestationsFetchLoading = false;
        })
        .addMatcher(
          apiSlice.endpoints.updateManifestations.matchPending,
          (state) => {
            state.manifestationsStatus = "posting"
            state.manifestationsDbPostLoading = true;
          })
        .addMatcher(
          apiSlice.endpoints.updateManifestations.matchFulfilled,
          (state) => {
            state.manifestationsStatus = "idle"
            state.manifestationsDirty = false;
            state.manifestationsDbPostLoading = false;
          }
        )
        .addMatcher(
          apiSlice.endpoints.updateManifestations.matchRejected,
          (state) => {
            state.manifestationsStatus = "failed"
            state.manifestationsDbPostLoading = false;
          }
        )
    }
})

export const {
  manifestationUpsert,
  manifestationOptValsUpsert,
  manifestationRadioUpsert,
  manifestationsRemove,
  manifestationColUpdate,
  manifestationsGeneticsUpsert,
  manifestationsGeneDelete,
} = manifestationsSlice.actions;

export default manifestationsSlice.reducer;