import {Box, Button, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {fetchUserData} from "../Redux/users/userSlice";

const PatientAccountDetails = () => {
  const dispatch = useDispatch();
  const usersState = useSelector(state => state.users);
  const {usersFetchLoading, usersPatientId, usersIsDirty} = usersState;

  const navigate = useNavigate();
  const location = useLocation();
  // keep active tab in case user refreshes
  // create the array, use one portion of the array, then just forget about the rest
  // who really needs memory management anyway
  const userLocation = location.pathname.split('/').pop();
  // to set the tab index, locationMap[userLocation]
  const locationMap = {
    "settings": 0,
    "insurance": 1,
    "personal": 2
  }

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    const url = event.target.getAttribute("path");
    setTabIndex(newValue)
    navigate(url);
  };

  const theme = useTheme();
  const isSmol = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    handleTabChange({target: {getAttribute: () => userLocation}}, locationMap[userLocation]);
  }, [])

  return (
    <>
      <Helmet>
        <title>Account Settings</title>
      </Helmet>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12}>
          <Typography variant={"h1"} textAlign={"center"} mb={5}>
            &nbsp;
          </Typography>
        </Grid>

        <Grid container item xs={11} md={9} lg={7} p={"25px"} alignSelf={"center"} className="white-card">
          <Box sx={{width: '100%'}} mb={5}>
            <Tabs value={tabIndex} ml="auto" mr="auto"
                  centered={true} variant={isSmol ? "fullWidth" : "standard"}
                  onChange={handleTabChange}>
              <Tab label="Account Settings" path="settings" />
              <Tab label="Insurance Information" path="insurance" />
              <Tab label="Personal Information" path="personal" />
            </Tabs>
          </Box>
          <Outlet />
          <Grid item mt={3} sx={{width: '100%'}}>
            <hr />
          </Grid>
          <Grid ml="auto" mr="auto" item mt={3}>
            <Button variant={"contained"}
                    className={(usersFetchLoading ? " button-loading" : "")}
                    color={"primary"}
                    onClick={() => {
                      navigate("/home")
                      dispatch(fetchUserData(usersPatientId));
                    }}>
                <span className="backcontinue-button-text"
                      id="next-questionnaire-text">
                  Return to Your Homepage
                </span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PatientAccountDetails;
