import React from "react";
import {AncestryProvider} from "./TreeList_AncestryContext";
import {RelationAncestryForm} from "../FamilyTreeComponents/treeList_AncestryForm";

export const AncestryHistory = (props) => {
  const {relation, relationReactId, patientRelationId, existing} = props;
  return (
    <AncestryProvider>
      <RelationAncestryForm relation={relation}
                            relationReactId={relationReactId}
                            patientRelationId={patientRelationId}
                            existing={existing} />
    </AncestryProvider>
  );
}