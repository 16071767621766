import React, {useState, createContext} from "react";
import {
  manifestationMultiManiInitialAddWithOpts, manifestationsMultiManiDelete
} from "../../../Redux/manifestations/multiManifestationSlice";
import {useDispatch} from "react-redux";
import store from "../../../Redux/store/store";

export const Biopsy = createContext({});

export const BiopsyProvider = ({children}) => {
  const dispatch = useDispatch();
  const selfRelationId = store.getState().users.usersSelfRelationId;

  const [biopsies, setBiopsies] = useState([]);

  const deleteBiopsy = (id) => {
    setBiopsies(biopsies.filter((biopsy) => biopsy.reactId !== id));
    dispatch(manifestationsMultiManiDelete(id));
  }

  const addBiopsy = () => {
    const {reactId: newId} = dispatch(manifestationMultiManiInitialAddWithOpts(selfRelationId)).payload;
    setBiopsies((biopsies) => [
      ...biopsies,
      {
        reactId: newId,
        manifestationLookupCategory: 1970,
      }
    ]);
  }

  return (
    <Biopsy.Provider value={{biopsies, setBiopsies, addBiopsy, deleteBiopsy}}>
      {children}
    </Biopsy.Provider>
  );
}