import {Button, Grid, TextField, Typography} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useUpdateUserMutation} from "../../Redux/api/apiSlice";
import {updateUserFields} from "../../Redux/users/userSlice";
import {useFormik} from "formik";
import * as yup from "yup";

const InsuranceInformation = () => {
  const dispatch = useDispatch();
  const usersState = useSelector(state => state.users);
  const [updateUser, {isLoading: userApiIsLoading}] = useUpdateUserMutation();
  const [updateSuccess, setUpdateSuccess] = React.useState(false);


  const {
    userFirst,
    userLast,
    userEmail,
    userPwd,
    insuranceCompanyName,
    insuranceGroupNo,
    insurancePolicyNo,
    insuranceFirstName,
    insuranceLastName,
    insuranceRelationToPatient,
    insuranceDob,
    insuranceAddressLine1,
    insuranceAddressLine2,
    insuranceAddressZip,
    insuranceCity,
    insuranceState,
    insuranceCountry,
  } = usersState.entities[usersState.ids[0]];

  const formSchema = yup.object({
    insuranceCompanyName: yup.string().required("Insurance company name is required"),
    insuranceGroupNo: yup.string().required("Insurance group number is required"),
    insurancePolicyNo: yup.string().required("Insurance policy number is required"),
    insuranceRelationToPatient: yup.string().required("Relation to insured is required"),
    insuranceFirstName: yup.string().required("First name of insured is required"),
    insuranceLastName: yup.string().required("Last name of insured is required"),
    insuranceDob: yup.date().required("Date of birth is required"),
    insuranceAddressLine1: yup.string().required("Address (ex: 123 Main St.) is required"),
    insuranceAddressZip: yup.string().required("Zip code is required"),
    insuranceCity: yup.string().required("City is required"),
    insuranceState: yup.string().required("State is required"),
    insuranceCountry: yup.string().required("Country is required"),
  })

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    onSubmit,
    handleReset,
  } = useFormik({
    initialValues: {
      insuranceCompanyName: insuranceCompanyName ? insuranceCompanyName : "",
      insuranceGroupNo: insuranceGroupNo ? insuranceGroupNo : "",
      insurancePolicyNo: insurancePolicyNo ? insurancePolicyNo : "",
      insuranceRelationToPatient: insuranceRelationToPatient ? insuranceRelationToPatient : "",
      insuranceFirstName: insuranceFirstName ? insuranceFirstName : "",
      insuranceLastName: insuranceLastName ? insuranceLastName : "",
      insuranceDob: insuranceDob ? insuranceDob : null,
      insuranceAddressLine1: insuranceAddressLine1 ? insuranceAddressLine1 : "",
      insuranceAddressLine2: insuranceAddressLine2 ? insuranceAddressLine2 : "",
      insuranceAddressZip: insuranceAddressZip ? insuranceAddressZip : "",
      insuranceCity: insuranceCity ? insuranceCity : "",
      insuranceState: insuranceState ? insuranceState : "",
      insuranceCountry: insuranceCountry ? insuranceCountry : "",
    },
    onSubmit: async (values) => {
      values.insuranceDob = dayjs(values.insuranceDob).toJSON();
      await dispatch(updateUserFields(values))
      await updateUser({
        ...usersState.entities[usersState.ids[0]],
        token: usersHasToken,
        usersPatientId: usersPatientId,
      })
        .unwrap()
        .then(() => {
          setUpdateSuccess(true);
        })
    },
    validationSchema: formSchema,
  })

  React.useEffect(() => {
    dispatch(updateUserFields(values));
    setUpdateSuccess(false);
  }, [touched])

  const {usersHasToken, usersAxiosConfig, usersFetchLoading, usersPatientId} = usersState;

  return usersFetchLoading ? (
    <div>
      Loading...
    </div>
  ) : (<>
    <Grid item xs={12} mb={5}>
      <Typography variant={"h5"} textAlign={"center"} className={"blue-text"}>
        Insurance Info
      </Typography>
    </Grid>
    <form className="insurance-info"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}>
      <Grid justifyContent={{xs: "center", sm: "flex-start"}}
            columns={{xs: 4, sm: 8, md: 12}}
            container spacing={{xs: 2, md: 3}} mt={1}>
        <Grid item sx={{width: {sm: '100%'}}}>
          <Typography variant={"h5"} className={"blue-text"}>
            Primary Insurance Info
          </Typography>
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insuranceCompanyName"
                     name="insuranceCompanyName" label="Insurance Company"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["insuranceCompanyName"]}
                     helperText={errors["insuranceCompanyName"]}
                     value={values.insuranceCompanyName ? values.insuranceCompanyName : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insuranceGroupNo"
                     name="insuranceGroupNo" label="Insurance Group No."
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["insuranceGroupNo"]}
                     helperText={errors["insuranceGroupNo"]}
                     value={values.insuranceGroupNo ? values.insuranceGroupNo : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insurancePolicyNo"
                     name="insurancePolicyNo" label="Insurance Policy No."
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["insurancePolicyNo"]}
                     helperText={errors["insurancePolicyNo"]}
                     value={values.insurancePolicyNo ? values.insurancePolicyNo : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insuranceRelationToPatient"
                     name="insuranceRelationToPatient" label="Relation to Insured"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["insuranceRelationToPatient"]}
                     helperText={errors["insuranceRelationToPatient"]}
                     value={values.insuranceRelationToPatient ? values.insuranceRelationToPatient : ""}
                     variant="outlined" />
        </Grid>
        <Grid item sx={{width: {sm: '100%'}}}>
          <Typography variant={"h5"} className={"blue-text"}>
            Insurance Holder Personal Info
          </Typography>
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insuranceFirstName"
                     name="insuranceFirstName" label="Insured First Name"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["insuranceFirstName"]}
                     helperText={errors["insuranceFirstName"]}
                     value={values.insuranceFirstName ? values.insuranceFirstName : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insuranceLastName"
                     name="insuranceLastName" label="Insured Last Name"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["insuranceLastName"]}
                     helperText={errors["insuranceLastName"]}
                     value={values.insuranceLastName ? values.insuranceLastName : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Insured Date of Birth"
              id={"insuranceDob"}
              name={"insuranceDob"}
              maxDate={dayjs()}
              format={"MM/dd/yyyy"}
              value={dayjs(values.insuranceDob).isValid() ? dayjs(values.insuranceDob) : null}
              onChange={(value) => setFieldValue("insuranceDob", value.$d, true)}
              renderInput={(params) =>
                <TextField {...params}
                           helperText={touched.insuranceDob && errors.insuranceDob} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item sx={{width: {sm: '100%'}}}>
          <Typography variant={"h5"} className={"blue-text"}>
            Insurance Holder Address
          </Typography>
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insuranceAddressLine1"
                     name="insuranceAddressLine1" label="Insured Address Line 1"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["insuranceAddressLine1"]}
                     helperText={errors["insuranceAddressLine1"]}
                     value={values.insuranceAddressLine1 ? values.insuranceAddressLine1 : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insuranceAddressLine2"
                     name="insuranceAddressLine2" label="Insured Address Line 2"
                     onChange={handleChange} onBlur={handleBlur}
                     error={!!errors["insuranceAddressLine2"]}
                     helperText={errors["insuranceAddressLine2"]}
                     value={values.insuranceAddressLine2 ? values.insuranceAddressLine2 : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insuranceAddressZip"
                     name="insuranceAddressZip" label="Insured Zip Code"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["insuranceAddressZip"]}
                     helperText={errors["insuranceAddressZip"]}
                     value={values.insuranceAddressZip ? values.insuranceAddressZip : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insuranceCity"
                     name="insuranceCity" label="Insured City"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["insuranceCity"]}
                     helperText={errors["insuranceCity"]}
                     value={values.insuranceCity ? values.insuranceCity : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insuranceState"
                     name="insuranceState" label="Insured State"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["insuranceState"]}
                     helperText={errors["insuranceState"]}
                     value={values.insuranceState ? values.insuranceState : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto" sm={4} md={4} lg="auto">
          <TextField type="text" id="insuranceCountry"
                     name="insuranceCountry" label="Insured Country"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["insuranceCountry"]}
                     helperText={errors["insuranceCountry"]}
                     value={values.insuranceCountry ? values.insuranceCountry : ""}
                     variant="outlined" />
        </Grid>
        <Grid item>
          {updateSuccess && <Typography variant={"h5"} sx={{color: "green"}}>
            Successfully updated your insurance information.
          </Typography>}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={(userApiIsLoading ? " button-loading" : "")}
            disabled={!Object.keys(touched).length > 0}
            type="submit">
                <span className="backcontinue-button-text"
                      id="next-questionnaire-text">
                  Save Changes
                </span>
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="outlined"
            sx={{display: `${userApiIsLoading ? "none" : ""}`}}
            disabled={!Object.keys(touched).length > 0}
            onClick={(e) => {
              e.preventDefault();
              handleReset(e);
            }}>
            Reset Changes
          </Button>
        </Grid>
      </Grid>
    </form>
  </>);
}

export default InsuranceInformation;