import {Grid, Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import React from "react";
import {useValidateToken} from "../../Hooks/useValidateToken";

import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {keepUserGenderUpdated, updateUserState} from "../../Redux/users/userSlice";
import useReduxFormData from "../../Hooks/useReduxFormData";

const BasicInfo = () => {
  useValidateToken(true, "basic");
  //TODO - refactor this to use a redux type data handler, and not local state
  const [dispatch, usersState, updateUser, userApiIsLoading] = useReduxFormData();
  const [loadNum, setLoadNum] = React.useState(0);

  const {
    entities,
    usersPatientId,
    usersIsDirty: dirty,
    usersFetchLoading: fetchUserLoading,
    usersHasToken
  } = usersState;
  const {handleFormNavigation} = useFormNavigation();

  const getInitialFormState = (id) => {
    let {
      userFirst,
      userLast,
      dob,
      patientRace,
      gender,
      height,
      heightFt,
      heightIn,
      weight,
      genderIdentity,
      preferredPronouns,
    } = entities[id];

    return {
      userFirst: userFirst ? userFirst : "",
      userLast: userLast ? userLast : "",
      dob: dob ? dob : null,
      patientRace: patientRace ? patientRace : null,
      gender: gender ? gender : null,
      genderIdentity: genderIdentity ? genderIdentity : null,
      preferredPronouns: preferredPronouns ? preferredPronouns : null,
      height: height ? height : 0,
      weight: weight ? weight : 0,
      heightFt: heightFt ? heightFt : 0,
      heightIn: heightIn ? heightIn : 0,
    }
  }

  const {inputs, errors, setErrors, handleBasicInputChange} =
    useReduxDataHandler(getInitialFormState(usersPatientId));

  React.useEffect(() => {
    setLoadNum(loadNum + 1);
    dispatch(updateUserState(inputs))
  }, [inputs])

  const validateInputs = () => {
    let isValid = true;
    let errors = {};

    if (!inputs["userFirst"]) {
      isValid = false;
      errors["userFirst"] = "This is a required field.";
    }

    if (!inputs["userLast"]) {
      isValid = false;
      errors["userLast"] = "This is a required field.";
    }

    if (!inputs["dob"]) {
      isValid = false;
      errors["dob"] = "This is a required field.";
    }

    if (!inputs["gender"]) {
      isValid = false;
      errors["gender"] = "This is a required field."
    }

    setErrors(errors);
    return isValid;
  }

  return (
    fetchUserLoading
      ? undefined
      : (<>
        <Helmet>
          <title>Basic Information</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
          <Grid item xs={8}>
            <Typography textAlign={"center"} variant={"h2"} mt={5}>Let's start with the <b
              className="blue-emphasis">basics</b></Typography>
          </Grid>
          <Grid item xs={12}>
            <form className="questionnaire-content"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    if (validateInputs()) {
                      if (dirty && loadNum > 1) {
                        let dob = inputs["dob"];
                        let userMod = {
                          ...inputs,
                          dob: new Date(dob + "T00:00:00.000Z"),
                          token: usersHasToken,
                          usersPatientId: usersPatientId
                        };
                        await updateUser(userMod)
                          .unwrap()
                          .then(() => handleFormNavigation(e))
                      }
                      handleFormNavigation(e)
                    }
                  }}>
              <Grid spacing={2} container justifyContent={"center"} item xs={12} ml={"auto"} mr={"auto"}
                    className="questionnaire-inner-content">
                <Grid container item xs={8}>
                  <Grid container item flexDirection={"column"} justifyContent={"center"} xs={12} md={6}>
                    <label htmlFor="userFirst">First Name *</label><br />
                    <input type="text"
                           className={errors["userFirst"] ? "text-input error" : "text-input"}
                           name={"userFirst"}
                           defaultValue={(inputs.userFirst && inputs.userFirst) || ""}
                           onChange={handleBasicInputChange} /><br />
                    {errors["userFirst"] && <div className="error-text">{errors["userFirst"]}</div>}
                  </Grid>
                  <Grid container item flexDirection={"column"} justifyContent={"center"} xs={12} md={6}>
                    <label htmlFor="userLast">Last Name *</label><br />
                    <input type="text"
                           className={errors["userLast"] ? "text-input error" : "text-input"}
                           name="userLast"
                           defaultValue={(inputs.userLast && inputs.userLast) || ""}
                           onChange={handleBasicInputChange} /><br />
                    {errors["userLast"] && <div className="error-text">{errors["userLast"]}</div>}
                  </Grid>
                  <Grid container item flexDirection={"column"} justifyContent={"center"} xs={12} md={6}>
                    <label htmlFor="dob">Date of Birth *</label><br />
                    <input type="date"
                           name="dob"
                           className={errors["dob"] ? "text-input text-input-figma error" : "text-input text-input-figma"}
                           defaultValue={(inputs.dob && inputs.dob) || ""}
                           onChange={handleBasicInputChange} /><br />
                    {errors["dob"] && <div className="error-text">{errors["dob"]}</div>}
                  </Grid>
                  <Grid container item flexDirection={"column"} justifyContent={"center"} xs={12} md={6}>
                    <label className=""
                           htmlFor="gender">
                      Sex at Birth *
                    </label><br />
                    <Grid container item flexDirection={"row"}>
                      <input type="radio"
                             value="Female"
                             name="gender"
                             defaultChecked={(inputs.gender && inputs.gender === "Female") || null}
                             onChange={(e) => {
                               dispatch(keepUserGenderUpdated(e.target.value));
                               handleBasicInputChange(e);
                             }} />
                      &nbsp;Female&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             value="Male"
                             name="gender"
                             defaultChecked={(inputs.gender && inputs.gender === "Male") || null}
                             onChange={(e) => {
                               dispatch(keepUserGenderUpdated(e.target.value));
                               handleBasicInputChange(e);
                             }} />
                      &nbsp;Male&nbsp;
                    </Grid>
                    {errors["gender"] && <div className={"error-text"}>{errors["gender"]}</div>}
                  </Grid>
                  <Grid container item flexDirection={"column"} justifyContent={"center"} xs={12} md={6}>
                    <label htmlFor="genderIdentity">Gender Identity</label><br />
                    <select className="dropdown-input"
                            name="genderIdentity"
                            defaultValue={(inputs.genderIdentity && inputs.genderIdentity) || ""}
                            onChange={handleBasicInputChange}>
                      <option value="">Please select</option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                      <option value="Non-Binary">Non-binary</option>
                      <option value="Other">Other</option>
                      <option value="Non-Conforming">Gender Queer / Non-Conforming</option>
                      <option value="Decline">Prefer not to say</option>
                    </select>
                  </Grid>
                  <Grid container item flexDirection={"column"} justifyContent={"center"} xs={12} md={6}>
                    <label htmlFor="patientRace">Race</label><br />
                    <select className="dropdown-input"
                            name="patientRace"
                            defaultValue={(inputs.patientRace && inputs.patientRace) || ""}
                            onChange={handleBasicInputChange}>
                      <option value="">Please select</option>
                      <option value="White">White</option>
                      <option value="African American">African-American</option>
                      <option value="Chinese American">Chinese-American</option>
                      <option value="Filipino American">Filipino-American</option>
                      <option value="Japanese American">Japanese-American</option>
                      <option value="Hawaiian American">Hawaiian-American</option>
                      <option value="Hispanic American (US born)">Hispanic-American (US born)</option>
                      <option value="Hispanic American (Foreign born)">Hispanic-American (Foreign born)</option>
                      <option value="Other Pacific Islander">Other Pacific Islander</option>
                      <option value="Other Asian">Other Asian</option>
                      <option value="Other/Unknown">Other/Unknown</option>
                      <option value="Decline">Decline to answer</option>
                    </select>
                  </Grid>
                  <Grid container item flexDirection={"column"} justifyContent={"center"} xs={12} md={6}>
                    <label htmlFor="preferredPronouns">Preferred Pronouns</label><br />
                    <select className="dropdown-input"
                            name="preferredPronouns"
                            defaultValue={(inputs.preferredPronouns && inputs.preferredPronouns) || undefined}
                            onChange={handleBasicInputChange}>
                      <option value={undefined}>Please select</option>
                      <option value="She/Her/Hers">She / Her / Hers</option>
                      <option value="He/Him/His">He / Him / His</option>
                      <option value="They/Them/Theirs">They / Them / Theirs</option>
                      <option value="Other">Other</option>
                    </select>
                  </Grid>
                </Grid>
                <BackContinueButtons loading={userApiIsLoading} />
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>)
  )
}

export default BasicInfo;