import React, {useState, createContext} from "react";
import {
  manifestationMultiManiInitialAddNoOpts,
  manifestationsMultiManiDelete
} from "../../../Redux/manifestations/multiManifestationSlice";
import {useDispatch} from "react-redux";
import store from "../../../Redux/store/store";

export const OtherHist = createContext({});

export const OtherHistProvider = ({children}) => {
  const dispatch = useDispatch();
  const selfRelationId = store.getState().users.usersSelfRelationId;
  
  const [otherHealth, setOtherHealth] = useState([]);
  
  const deleteOtherHist = (id) => {
    setOtherHealth(otherHealth.filter((other) => other.reactId !== id));
    dispatch(manifestationsMultiManiDelete(id));
  };
  
  const addOtherHist = () => {
    const {reactId: newId} = dispatch(manifestationMultiManiInitialAddNoOpts(selfRelationId)).payload;
    setOtherHealth((others) => [
      ...others,
      {
        reactId: newId
      }
    ])
  }
  
  return (
    <OtherHist.Provider value={{otherHealth, setOtherHealth, addOtherHist, deleteOtherHist}}>
      {children}
    </OtherHist.Provider>
  );
}