import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {Grid, Select, InputLabel, MenuItem, TextField, IconButton} from "@mui/material";
import React from "react";
import {polypOptions} from "../Helpers/manifestations";

const PolypRow = (props) => {
  const {handleTypeChange, handleQtyChange, polypReactId, handleDeletePolyp, selfRelationId, polypMani} = props;
  const [microFormState, setMicroFormState] = React.useState({})

  React.useEffect(() => {
    if (polypMani) {
      if (Object.keys(polypMani).length > 0) {
        setMicroFormState((prevState) => {
          return {
            ...prevState,
            polypType: polypMani.prmOptVals[0].manifestationOptionValueId,
            polypQuantity: polypMani.quantity,
          }
        })
      }
    }
  }, [])

  return (
    <>
      <Grid container spacing={1} item xs={12} justifyContent={"space-between"}>
        <Grid item xs={"auto"}>
          <InputLabel id={`${polypReactId}-polyp-type`}>Polyp Type</InputLabel>
          <Select name={`${polypReactId}-57`}
                  labelId={`${polypReactId}-polyp-type`}
                  required
                  displayEmpty
                  style={{minWidth: "240px"}}
                  value={microFormState.polypType ? microFormState.polypType : ""}
                  onChange={(e) => {
                    setMicroFormState({...microFormState, polypType: e.target.value})
                    handleTypeChange(e);
                  }}>
            {polypOptions}
          </Select>
        </Grid>
        <Grid item xs={"auto"}>
          <InputLabel id={`${polypReactId}-polyp-quantity`}>Polyp Quantity</InputLabel>
          <TextField
            aria-labelledby={`${polypReactId}-polyp-quantity`}
            style={{maxWidth: "75px"}}
            required
            name={`${polypReactId}-quantity`}
            placeholder={"Qty"}
            inputProps={{inputMode: "numeric", pattern: "[0-9]*", title: "Numbers only"}}
            value={microFormState.polypQuantity ? microFormState.polypQuantity : ""}
            onChange={(e) => {
              setMicroFormState({...microFormState, polypQuantity: e.target.value})
              handleQtyChange(e);
            }} />
        </Grid>
        <Grid item xs={"auto"} alignSelf={"center"} ml={"auto"}>
          <IconButton color={"secondary"} onClick={(e) => {
            e.preventDefault();
            if(window.confirm("Are you sure you want to delete this result?")){
            handleDeletePolyp(polypMani.reactId);}
          }}>
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid>
        <hr style={{color: "lightgray", width: "75%", opacity: ".35"}} />
      </Grid>
    </>
  )
}

export default PolypRow;