import React, {useState, createContext} from "react";
import {useDispatch} from "react-redux";
import {editRelationsInDb, fetchRelations} from "../../../Redux/relations/relationSlice";

export const FamilyTreeList = createContext({});

export const TreeListProvider = ({children}) => {
  const dispatch = useDispatch();

  const [treeList, setTreeList] = useState([]);

  const deleteRelation = (id) => {
    let deletedEntity = treeList[id];
    sessionStorage.setItem('relationBuildScrollPosition', document.querySelector("#questionnaire-wrapper").scrollTop)
    dispatch(editRelationsInDb([{...deletedEntity, markForDeletion: true}]))
      .then(() => dispatch(fetchRelations()))
  }

  return (
    <FamilyTreeList.Provider value={{treeList, setTreeList, deleteRelation}}>
      {children}
    </FamilyTreeList.Provider>
  );
}