import {createTheme} from "@mui/material";

export const outerTheme = createTheme({
  palette: {
    primary: {
      light: "#D8EEF2",
      main: "#2DA5B9",
      mainGradient: "linear-gradient(90deg, #1B6481 0%, #23A6BA 100%)",
    },
    secondary: {
      light: "#DDE8EC",
      main: "#1B6481",
      dark: "#1B6481",
    },
    error: {
      // light: generated automatically
      main: "#FF5638",
      // dark: generated automatically
    },
    warning: {
      main: "#FDBB40",
    },
    success: {
      main: "#89C77B",
    },
    info: {
      main: "#9BE9DF",
    },
    inputs: {
      main: "#F27931",
      mainGradient: "linear-gradient(90deg, #F27931 0%, #FC7556 100%) no repeat 0 0",
    },
    navbar: {
      main: "#FFFFFF",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 4.5,
    // Determines how far to shift the 'main' color to get light and dark versions
    // within the color's palette.
    tonalOffset: 0.2,
  },
  typography: {
    // extra quotes look goofy, but if they aren't there, backup fonts fail
    fontFamily: [
      'Open Sans', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', '"Fira Sans"', '"Droid Sans"', '"Helvetica Neue"',
      'sans-serif'
    ].join(','),
  },
  components: {
    MuiButton: {
      variants: [
        // The styles that should win need to be applied last
        // Can also override MUI variants, as seen below
        {
          props: {variant: "outlined"},
          style: {
            background: "white",
            border: "2px solid #CA3D00",
            color: "#c93e00",
            textTransform: "none",
            "&:hover": {
              background: "white",
              border: "2px solid #c93e00",
              boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
            },
          }
        },
        {
          props: {variant: "undecorated"},
          style: {
            all: "unset",
            cursor: "pointer",
          }
        },
        {
          props: {variant: "contained"},
          style: {
            background: "#c93e00",
            textTransform: "none",
            "&:hover": {
              background: "#c93e00",
            },
          }
        },
        {
          props: {variant: "disabled"},
          style: {
            background: "#D8EEF2",
          },
        },
        {
          props: {variant: "personal-add"},
          style: {
            minWidth: "300px",
            height: "70px",
            background: "#DDE8EC",
            borderRadius: "8px",
            color: "#000",
            fontSize: "1.25rem",
            textTransform: "none",
          },
        },
        {
          props: {variant: "relation-add"},
          style: {
            background: "#DDE8EC",
            borderRadius: "8px",
            color: "#000",
            fontSize: "1rem",
            textTransform: "none",
          },
        },
        {
          props: {variant: "relation-add-relative"},
          style: {
            background: "#DDE8EC",
            borderRadius: "8px",
            color: "#000",
            fontSize: "1rem",
            textTransform: "none",
            width: "220px",
          },
        },
      ],
      styleOverrides: {
        root: {
          color: "white",
          height: "48px",
          background: "#c93e00 linear-gradient(90deg, #F27931 0%, #FC7556 100%) no-repeat 0 0",
          borderRadius: "40px",
          paddingLeft: "32px",
          paddingRight: "32px",
          boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        }
      }
    },
    MuiLink: {
      variants: [
        {
          props: {variant: "dashboard"},
          style: {
            color: "#1B6481",
            cursor: "pointer",
            '&:hover': {
              color: "#2DA5B9",
            },
          },
        }
      ]
    },
    MuiCard: {
      variants: [
        {
          props: {variant: "family-tree"},
          style: {
            background: "#FFFFFF 0 0 no-repeat padding-box",
            padding: "16px 32px",
            boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
            opacity: 1,
            height: "fit-content",
            width: "100%",
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#000000",
          overflow: "visible",
          whiteSpace: "unset",
          textOverflow: "unset",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: "#EAEDF0 0 0 no-repeat padding-box",
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#EAEDF0 0 0 no-repeat padding-box",
          color: "#1B6481",
          borderRadius: "12px",
          '& fieldset': {
            borderColor: '#DDE8EC',
          },
          '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DA5B9',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#1B6481',
          },
          '& fieldset legend': {
            fontSize: '1rem',
          },
        },
      }
    },
    MuiAccordion: {
      variants: [
        {
          props: {variant: "family-tree"},
          style: {
            background: "#FFFFFF 0 0 no-repeat padding-box",
            padding: "16px 32px",
            boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
            opacity: 1,
            height: "fit-content",
            width: "100%",
            "&:last-of-type": {
              borderRadius: "12px",
            },
            "&:first-of-type": {
              borderRadius: "12px",
            },
          },
        },
      ],
    }
  },
  props: {},
  overrides: {},
});