//todo: get all of this from the db
//in an effort for ease of reading
export const relationIDs = {
  Self: 1,
  Mother: 2,
  Father: 3,
  Sister: 4,
  Brother: 5,
  Daughter: 6,
  Son: 7,
  Grandmother: 8,
  Grandfather: 9,
  Granddaughter: 10,
  Grandson: 11,
  Aunt: 12,
  Uncle: 13,
  Niece: 14,
  Nephew: 15,
  Half_Sister: 16,
  Half_Brother: 17,
  Female_First_Cousin: 18,
  Male_First_Cousin: 19,
  Great_Grandmother: 20,
  Great_Grandfather: 21,
  Great_Granddaughter: 22,
  Great_Grandson: 23,
  Great_Aunt: 29,
  Great_Uncle: 30,
  Half_Niece: 32,
  Half_Nephew: 33,
  Half_Aunt: 34,
  Half_Uncle: 35
}

export const relationChildrenIDs = {//only the ones that need it.
  4: {son: relationIDs.Nephew, daughter: relationIDs.Niece},//sister
  5: {son: relationIDs.Nephew, daughter: relationIDs.Niece},//brother
  6: {son: relationIDs.Grandson, daughter: relationIDs.Granddaughter},//daughter
  7: {son: relationIDs.Grandson, daughter: relationIDs.Granddaughter},//son
  10: {son: relationIDs.Great_Grandson, daughter: relationIDs.Great_Granddaughter},//granddaughter
  11: {son: relationIDs.Great_Grandson, daughter: relationIDs.Great_Granddaughter},//grandson
  12: {son: relationIDs.Male_First_Cousin, daughter: relationIDs.Female_First_Cousin},//Aunt
  13: {son: relationIDs.Male_First_Cousin, daughter: relationIDs.Female_First_Cousin},//uncle
  16: {son: relationIDs.Half_Nephew, daughter: relationIDs.Half_Niece},//half-sister
  17: {son: relationIDs.Half_Nephew, daughter: relationIDs.Half_Niece},//half-brother
}

export const relationsAndRelations = {
  1: {
    label: "Self",
  },
  2: {
    counterLabel: "Mom",
    label: "Mother",
    gender: "female",
    daughterId: 16,
    daughterLabel: "Half Sister",
    sonId: 17,
    sonLabel: "Half Brother",
  },
  "2-8": {
    counterLabel: "Mom's mom",
  },
  "2-9": {
    counterLabel: "Mom's dad",
  },
  3: {
    counterLabel: "Dad",
    label: "Father",
    gender: "male",
    daughterId: 16,
    daughterLabel: "Half Sister",
    sonId: 17,
    sonLabel: "Half Brother",
  },
  "3-8": {
    counterLabel: "Dad's mom",
  },
  "3-9": {
    counterLabel: "Dad's dad",
  },
  4: {
    counterLabel: "Full Sisters",
    label: "Sister",
    gender: "female",
    daughterId: 14,
    daughterLabel: "Niece",
    sonId: 15,
    sonLabel: "Nephew",
  },
  5: {
    counterLabel: "Full Brothers",
    label: "Brother",
    gender: "male",
    daughterId: 14,
    daughterLabel: "Niece",
    sonId: 15,
    sonLabel: "Nephew",
  },
  6: {
    counterLabel: "Daughters",
    label: "Daughter",
    gender: "female",
    daughterId: 10,
    daughterLabel: "Granddaughter",
    sonId: 11,
    sonLabel: "Grandson",
  },
  7: {
    counterLabel: "Sons",
    label: "Son",
    gender: "male",
    daughterId: 10,
    daughterLabel: "Granddaughter",
    sonId: 11,
    sonLabel: "Grandson",
  },
  8: {
    counterLabel: "Grandmother",
    label: "Grandmother",
    gender: "female",
    daughterId: 34,
    daughterLabel: "Half Aunt",
    sonId: 35,
    sonLabel: "Half Uncle",
  },
  9: {
    counterLabel: "Grandfather",
    label: "Grandfather",
    gender: "male",
    daughterId: 34,
    daughterLabel: "Half Aunt",
    sonId: 35,
    sonLabel: "Half Uncle",
  },
  10: {
    counterLabel: "Granddaughters",
    label: "Granddaughter",
    gender: "female",
    daughterId: 22,
    daughterLabel: "Great Granddaughter",
    sonId: 23,
    sonLabel: "Great Grandson",
  },
  11: {
    counterLabel: "Grandsons",
    label: "Grandson",
    gender: "male",
    daughterId: 22,
    daughterLabel: "Great Granddaughter",
    sonId: 23,
    sonLabel: "Great Grandson",
  },
  12: {
    counterLabel: "Sisters (Aunts)",
    label: "Aunt",
    gender: "female",
    daughterId: 18,
    daughterLabel: "Female First Cousin",
    sonId: 19,
    sonLabel: "Male First Cousin",
  },
  13: {
    counterLabel: "Brothers (Uncles)",
    label: "Uncle",
    gender: "male",
    daughterId: 18,
    daughterLabel: "Female First Cousin",
    sonId: 19,
    sonLabel: "Male First Cousin",
  },
  14: {
    counterLabel: "Nieces",
    label: "Niece",
    gender: "female",
  },
  15: {
    counterLabel: "Nephews",
    label: "Nephew",
    gender: "male",
  },
  16: {
    counterLabel: "Half Sisters",
    label: "Half Sister",
    gender: "female",
    daughterId: 32,
    daughterLabel: "Half Niece",
    sonId: 33,
    sonLabel: "Half Nephew",
  },
  17: {
    counterLabel: "Half Brothers",
    label: "Half Brother",
    gender: "male",
    daughterId: 32,
    daughterLabel: "Half Niece",
    sonId: 33,
    sonLabel: "Half Nephew",
  },
  18: {
    counterLabel: "Female First Cousins",
    label: "Female First Cousin",
    gender: "female",
  },
  19: {
    counterLabel: "Male First Cousins",
    label: "Male First Cousin",
    gender: "male",
  },
  20: {
    label: "Great Grandmother",
    gender: "female",
  },
  21: {
    label: "Great Grandfather",
    gender: "male",
  },
  22: {
    counterLabel: "Great Granddaughters",
    label: "Great Granddaughter",
    gender: "female",
  },
  23: {
    counterLabel: "Great Grandsons",
    label: "Great Grandson",
    gender: "male",
  },
  29: {
    counterLabel: "Sisters (Great Aunts)",
    label: "Great Aunt",
    gender: "female",
  },
  30: {
    counterLabel: "Brothers (Great Uncles)",
    label: "Great Uncle",
    gender: "male",
  },
  32: {
    counterLabel: "Half Nieces",
    label: "Half Niece",
    gender: "female",
  },
  33: {
    counterLabel: "Half Nephews",
    label: "Half Nephew",
    gender: "male",
  },
  34: {
    label: "Half Aunt",
    gender: "female",
  },
  35: {
    label: "Half Uncle",
    gender: "male",
  },
}

export const getRelationCardLabel = (relationId, side) => {
  let result = {
    "relation": "",
    "side": "",
  };

  if ((side === "Maternal" && relationId !== 2)) {
    result["side"] = "(Mom's side)";
  }

  if ((side === "Paternal" && relationId !== 3)) {
    result["side"] = "(Dad's side)";
  }
  result["relation"] = relationId ? relationsAndRelations[relationId].label : "";

  return result;
}