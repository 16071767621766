import React from "react";
import {CancerProvider} from "./TreeList_CancerContext";
import {RelationCancerForm} from "../FamilyTreeComponents/treeList_CancerForm";

export const CancerHistory = (props) => {
  const {relation, relationReactId, patientRelationId, side, gender, existing, isDeceased} = props;
  return (
    <CancerProvider>
      <RelationCancerForm relation={relation}
                          relationReactId={relationReactId}
                          patientRelationId={patientRelationId}
                          side={side}
                          gender={gender}
                          existing={existing}
                          isDeceased={isDeceased} />
    </CancerProvider>
  );
}