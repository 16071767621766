import {
  CssBaseline,
  Grid,
  Typography,
} from "@mui/material";
import React, {useContext} from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {useValidateToken} from "../../Hooks/useValidateToken";
import {useRelations} from "../../Hooks/useRelations";
import {useSelector} from "react-redux";
import RelationCardSkeleton from "./FamilyTreeComponents/relationCardSkeleton";
import RenderRelations from "./FamilyTreeComponents/renderRelations";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {FamilyTreeList} from "./Context/TreeList_BuildListContext";

const TreeList_BuildList = () => {
  useValidateToken(true, "history");
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);
  const navigate = useNavigate();
  const {relationsIsLoading} = useRelations();

  const relationsState = useSelector((state) => state.relations);
  const {
    ids: reactIds,
    entities,
    relationsWereFetched,
    selfRelations,
    maternalRelations,
    paternalRelations,
    relationsCardIsExpanded,
    relationsCardExpandedCount,
    relationsDbPosting,
    relationsFilteredBy,
  } = relationsState;

  const familyTree = useContext(FamilyTreeList);
  const {setTreeList} = familyTree;

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 600);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setIsMobile(window.innerWidth < 600);
      });
    }
  }, [])

  React.useEffect(() => {
    setTreeList(entities);
  }, [relationsWereFetched]);

  React.useEffect(() => {
    const scrollPosition = sessionStorage.getItem('relationBuildScrollPosition');
    if (scrollPosition) {
      document.querySelector("#questionnaire-wrapper").scrollTo({
        top: (parseInt(scrollPosition))
      });
    }
    if (!relationsIsLoading) {
      sessionStorage.removeItem('relationBuildScrollPosition');
    }
  }, [relationsIsLoading])

  React.useEffect(() => {
    document.getElementById("next-questionnaire")
      ?.addEventListener("click", () => {
        navigate("/health-questionnaire/submission-page");
      });
    let backButton = document.getElementById("back-questionnaire")
    if (backButton) {
      backButton.style.visibility = "hidden";
    }
  }, [relationsIsLoading, relationsCardIsExpanded, relationsFilteredBy])

  return (
    relationsIsLoading
      ? <>
        <Helmet>
          <title>Your Family</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper"
              id="questionnaire-wrapper">
          <Grid item>
            <Typography textAlign={"center"} variant="h2" mt={5}>
              Let's talk about your <b className="blue-emphasis">family members</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className="questionnaire-content family-tree"
                 id={"family-tree-form"}>
              <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                    className="questionnaire-inner-content">
                <Grid container item justifyContent={"center"} xs={12} sm={11} md={8}>
                  <RelationCardSkeleton />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
      : <>
        <CssBaseline />
        <Helmet>
          <title>Your Family</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper"
              id="questionnaire-wrapper">
          <Grid item>
            <Typography textAlign={"center"} variant="h2" mt={5}>
              Let's talk about your <b className="blue-emphasis">family members</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className="questionnaire-content family-tree"
                 id={"family-tree-form"}>
              <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                    className="questionnaire-inner-content">
                <RenderRelations
                  selfRelations={selfRelations}
                  maternalRelations={maternalRelations}
                  paternalRelations={paternalRelations}
                  relationsFilteredBy={relationsFilteredBy}
                  isMobile={isMobile} />
                <BackContinueButtons />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
  );
}

export default TreeList_BuildList;