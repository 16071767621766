import {Grid, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import React, {useContext} from "react";
import {OtherHist} from "../Context/100_Other";
import InputValLimiters from "../Helpers/inputValLimiters";
import {otherHistoryList, guessList, polypOptions} from "../Helpers/manifestations";
import {ReactComponent as TrashIcon} from "../../../Resources/Images/Icons/Trash.svg";

const OtherHistoryRow = (props) => {
  const {id, existingHistory, typeChangeHandler, colChangeHandler, gender, polypOptValChangeHandler} = props;
  const {deleteOtherHist} = useContext(OtherHist);
  const {existedInDb} = existingHistory ? existingHistory : {existedInDb: false};
  const {reactId: manifestationReactId} = existingHistory;

  const [microState, setMicroState] = React.useState({})

  const [showPolypOptions, setShowPolypOptions] = React.useState(false);

  const existingOptVals = (
    existingHistory && (existingHistory.prmOptVals && existingHistory.prmOptVals.length > 0)
      ? existingHistory.prmOptVals
      : undefined
  )

  React.useEffect(() => {
    if (existingHistory) {
      if (Object.keys(existingHistory).length > 0) {
        setMicroState((prevState) => {
          return {
            ...prevState,
            age: existingHistory.ageOfDiagnosis,
            type: existingHistory.manifestationId,
            confidence: existingHistory.confidenceInAgeOfDiagnosis,
            polypType: existingOptVals ? existingHistory.prmOptVals[0].manifestationOptionValueId : "",
            polypQuantity: existingOptVals ? existingHistory.quantity : ""
          }
        })
      }
    }
  }, [])

  React.useEffect(() => {
    if (existingOptVals) {
      setShowPolypOptions(true);
    }
  }, [existingOptVals])

  const deleteButton = (
    <Grid container item xs={"auto"}>
      <Tooltip title={"Delete this entry"}>
        <button className="trash-button"
                style={{marginTop: "20px"}}
                onClick={() => deleteOtherHist(id)}>
          <TrashIcon aria-label={"Delete this entry"} />
        </button>
      </Tooltip>
    </Grid>
  )

  return (<>
    <Grid container item xs={12} flexDirection={"row"} style={{maxWidth: "780px"}} mt={1} className={"white-card"}
          id={`other-${id}`}>
      <Grid spacing={2} container item xs columns={15}>
        <Grid item xs={"auto"}>
          <InputLabel id={`${id}-other`}>Condition</InputLabel>
          <Select name={`${id}`}
                  labelId={`${id}-other`}
                  required
                  displayEmpty
                  value={microState.type ? microState.type : ""}
                  onChange={(e) => {
                    if (e.target.value === 1427) {
                      setShowPolypOptions(true);
                    }
                    setMicroState({
                      ...microState,
                      type: e.target.value
                    })
                    typeChangeHandler(e)
                  }}>
            <MenuItem value={""}>Please select</MenuItem>
            {
              otherHistoryList.map((other, index) => {
                return (
                  (((other.gender === "" || other.gender === undefined) || other.gender === gender) && !other.relation))
                  ? (<MenuItem key={index}
                               value={parseInt(other.id)}>{other.label}</MenuItem>)
                  : ""
              })
            }
          </Select>
        </Grid>
        <Grid item xs={"auto"}>
          <InputLabel htmlFor={`${id}-ageOfDiagnosis`} id={`${id}-ageDiag`}>
            Age at Diagnosis
          </InputLabel>
          <TextField
            aria-labelledby={`${id}-ageDiag`}
            style={{maxWidth: "150px"}}
            name={`${id}-ageOfDiagnosis`}
            required
            inputProps={{inputMode: "numeric", pattern: "[0-9]*", title: "Numbers only"}}
            id={`${id}-ageOfDiagnosis`}
            placeholder={"Age"}
            value={microState.age ? microState.age : ""}
            onChange={(e) => {
              setMicroState({...microState, age: e.target.value})
              colChangeHandler(e)
            }} />
        </Grid>
        <Grid item xs={"auto"}>
          <InputLabel id={`${id}-confidence`}>
            How sure are you of the age?
          </InputLabel>
          <Select
            name={`${id}-confidenceInAgeOfDiagnosis`}
            labelId={`${id}-confidence`}
            displayEmpty
            id={`${id}-confidenceInAgeOfDiagnosis`}
            value={microState.confidence ? microState.confidence : ""}
            onChange={(e) => {
              setMicroState({...microState, confidence: e.target.value})
              colChangeHandler(e)
            }}>
            {guessList}
          </Select>
        </Grid>
        {showPolypOptions
          ? <Grid spacing={2} container item>
            <Grid item xs={"auto"}>
              <InputLabel id={`${manifestationReactId}-polyps`}>Polyp Type</InputLabel>
              <Select
                name={`${manifestationReactId}-57`}
                labelId={`${manifestationReactId}-polyps`}
                displayEmpty
                value={microState.polypType ? microState.polypType : ""}
                onChange={(e) => {
                  setMicroState({...microState, polypType: e.target.value})
                  polypOptValChangeHandler(e)
                }}>
                {polypOptions}
              </Select>
            </Grid>
            <Grid item xs={"auto"}>
              <InputLabel id={`${manifestationReactId}-polypQty`}>
                How many polyps?
              </InputLabel>
              <TextField
                aria-labelledby={`${manifestationReactId}-polypQty`}
                style={{maxWidth: "150px"}}
                inputProps={{inputMode: "numeric", pattern: "[0-9]*", title: "Numbers only"}}
                name={`${manifestationReactId}-quantity`}
                placeholder={"Quantity"}
                value={microState.polypQuantity ? microState.polypQuantity : ""}
                onChange={(e) => {
                  setMicroState({...microState, polypQuantity: e.target.value})
                  colChangeHandler(e)
                }} />
            </Grid>
          </Grid>
          : undefined}
      </Grid>
      {deleteButton}
    </Grid>
  </>)
}

export default OtherHistoryRow;