import {Outlet} from "react-router-dom";
import AppMenuBar from "../Components/MainPageLayout/MaterialAppBar";

const Layout = () => {
  return (
    <>
      <AppMenuBar />
      <Outlet />
    </>
  )
}

export default Layout;