import React from "react";
import {useDispatch} from "react-redux";
import {
  manifestationMultiManiEdit, manifestationsMultiManiColData,
  manifestationsMultiManiEditNoOpts
} from "../Redux/manifestations/multiManifestationSlice";
import {maniOptionIdBiopsies} from "../Pages/PatientQuestionnaire/Helpers/manifestations";

export function useModularReduxData() {
  const dispatch = useDispatch();
  // change handler to insert base manifestation
  const handleBiopsyTypeChange = (e) => {
    const reactId = e.target.name.split("-")[0];
    const maniId = e.target.value;
    dispatch(manifestationMultiManiEdit(maniId, reactId));
  }

  const handleBiopsyResultChange = (e) => {
    const array = e.target.name.split("-");
    const reactId = array[0];
    const optId = parseInt(maniOptionIdBiopsies[array[1]]);
    const maniOptValId = parseInt(e.target.value);
    dispatch(manifestationMultiManiEdit(null, reactId, optId, maniOptValId));
  }
  const handleCancerResultsChange = (e) => {
    const array = e.target.name.split("-");
    const reactId = array[0];
    const optId = parseInt([array[1]]);
    const maniOptValId = parseInt(e.target.value);
    dispatch(manifestationMultiManiEdit(null, reactId, optId, maniOptValId));
  }

  const handleCancerTypeChange = (e) => {
    const reactId = e.target.name.split("-")[0];
    const maniId = e.target.value;

    dispatch(manifestationsMultiManiEditNoOpts(reactId, maniId));
  }

  const handleCancerColDataChange = (e) => {
    const array = e.target.name.split("-");
    const reactId = array[0];
    const colName = array[1];
    let colValue = e.target.value;

    if (colName === "ageOfDiagnosis") {
      colValue = parseInt(colValue);
      dispatch(manifestationsMultiManiColData(reactId, colName, colValue));
    }
    if (colName === "confidenceInAgeOfDiagnosis") {
      dispatch(manifestationsMultiManiColData(reactId, colName, colValue));
    }
    if (colName === "quantity") {
      dispatch(manifestationsMultiManiColData(reactId, colName, colValue));
    }
  }

  const handlePolypOptValChange = (e) => {
    const array = e.target.name.split("-");
    const reactId = array[0];
    const optId = parseInt([array[1]].toString());
    const maniOptValId = parseInt(e.target.value);
    dispatch(manifestationMultiManiEdit(1427, reactId, optId, maniOptValId));
  }

  return {
    handleBiopsyTypeChange,
    handleBiopsyResultChange,
    handleCancerTypeChange,
    handleCancerColDataChange,
    handleCancerResultsChange,
    handlePolypOptValChange,
  };
}