// noinspection JSUnresolvedVariable
import dayjs from "dayjs";
import React from "react";
import {useDispatch} from "react-redux";
import {
  manifestationColUpdate, manifestationOptValsUpsert, manifestationRadioUpsert, manifestationsGeneticsUpsert,
  manifestationsRemove, manifestationUpsert
} from "../Redux/manifestations/manifestationSlice";
import {optValGetters} from "../Pages/PatientQuestionnaire/Helpers/optValGetters";

export function useReduxDataHandler(initialData = {}) {
  const [inputs, setInputs] = React.useState(initialData);
  const [errors, setErrors] = React.useState({});
  const [formState, setFormState] = React.useState({});
  const [optionValues, setOptionValues] = React.useState([]);
  const dispatch = useDispatch();

  const handleBasicInputChange = (event, override = undefined) => {
    setInputs({...inputs, [event.target.name]: event.target.checked});
    if (event.target.type === "checkbox") {
      if (override) {
        let merged = {...inputs, ...override, [event.target.name]: event.target.checked};
        setInputs(merged);
      }
      setErrors({...errors, [event.target.name]: ""});
    } else {
      setErrors({...errors, [event.target.name]: ""});
      setInputs({...inputs, [event.target.name]: event.target.value});
      if (override) {
        let merged = {...inputs, ...override, [event.target.name]: event.target.value};
        setInputs(merged);
      }
    }
  }

  const handleDateColChange = (dateState, selectedManiId, loadNum) => {
    // loadNum is part of ScreeningBoxes state; when fetching the date that exists from the db
    // state gets set to be able to track both the month and year, and dirties the form.
    // loadNum checks if it has changed more than once before setting the entity as changed.
    if (loadNum > 1) {
      if (dateState.month && dateState.year) {
        let date = dayjs(`${dateState.year}-${dateState.month}`);
        dispatch(manifestationColUpdate(selectedManiId, {colName: "manifestationDate", colValue: date.toJSON()}));
      }
    }
  }

  const handleInputChange = (
    e, manifestations,
    colData = false,
    removeMani = false,
  ) => {

    if (removeMani) {
      dispatch(manifestationsRemove(parseInt(e.target.getAttribute("maniid"))));
      return;
    }

    if (colData) {
      let selectedManiId = parseInt(e.target.getAttribute("col-for"));
      let colData = {
        colName: e.target.getAttribute("col-name"),
        colValue: e.target.value
      }
      dispatch(manifestationColUpdate(selectedManiId, colData));
      return;
    }

    const selectedManiId = parseInt(e.target.value);
    const relatedManis = e.target.getAttribute("related-manis");
    const relationId = manifestations.manifestationsRelationId;
    dispatch(manifestationUpsert(selectedManiId, relatedManis, relationId));
  }

  const handleOptValInputChange = (e, resetState = false, freeTextType = false, manifestations) => {
    // TODO - Refactor this to pass responsibility to manifestationSlice
    // TODO - Use immer to update state within redux rather than processing it here
    const {getFreeTextOptVals} = optValGetters();

    const optValsForManiId = parseInt(e.target.getAttribute("opts-for"));
    const selectedOptId = parseInt(e.target.getAttribute("manioptid"));

    const selectedOptValId =
      freeTextType
        ? getFreeTextOptVals(optValsForManiId, selectedOptId, parseInt(e.target.value.replace(/[^0-9]/g, "")))
        : parseInt(e.target.value);
    let dbOptVals = [];
    let sessionOptVals = [];
    let updateOptVals;

    if (manifestations.entities[optValsForManiId].prmOptVals
      && manifestations.entities[optValsForManiId].prmOptVals.length > 0) {
      dbOptVals =
        manifestations.entities[optValsForManiId].prmOptVals
          .filter((optVal) => optVal.prmovId !== undefined);
      sessionOptVals = manifestations.entities[optValsForManiId].prmOptVals;
    }

    if (dbOptVals.length > 0) {
      let existingOptVal =
        dbOptVals
          .find(optVal =>
            optVal["manifestationOptionId"] === selectedOptId);

      updateOptVals = existingOptVal
        ? dbOptVals.map((optVal) => {
          return optVal["manifestationOptionId"] === selectedOptId
            ? {...optVal, "manifestationOptionValueId": selectedOptValId, "markForDeletion": false}
            : optVal;
        })
        : [...dbOptVals, {
          "manifestationOptionId": selectedOptId,
          "manifestationOptionValueId": selectedOptValId,
          "attachesToManifestation": optValsForManiId,
        }];
    }
    if (sessionOptVals.length > 0) {
      let existingOptVal =
        sessionOptVals
          .find(optVal =>
            optVal["manifestationOptionId"] === selectedOptId);

      updateOptVals = existingOptVal
        ? sessionOptVals.map((optVal) => {
          return optVal["manifestationOptionId"] === selectedOptId
            ? {...optVal, "manifestationOptionValueId": selectedOptValId}
            : optVal;
        })
        : [...sessionOptVals, {
          "manifestationOptionId": selectedOptId,
          "manifestationOptionValueId": selectedOptValId,
          "attachesToManifestation": optValsForManiId,
        }];
    }
    if (dbOptVals.length === 0 && sessionOptVals.length === 0) {
      updateOptVals = [{
        "manifestationOptionId": selectedOptId,
        "manifestationOptionValueId": selectedOptValId,
        "attachesToManifestation": optValsForManiId,
      }]
    }
    setOptionValues(updateOptVals);
    dispatch(manifestationOptValsUpsert(updateOptVals))
  }

  const handleRadioChanged = (e, prId) => {
    if (!prId) console.error("MISSING PRID")
    const selected = parseInt(e.target.value);
    const opposite = parseInt(e.target.getAttribute("opp-manifestation"));
    dispatch(manifestationRadioUpsert(selected, opposite, prId));
  }

  const handleGeneTextChange = (e, prId, isVus) => {
    const getSelectedValue = (e) => {
      for (let i = 0; i < e.target.list.options.length; i++) {
        if (e.target.list.options[i].value === e.target.value) {
          return e.target.list.options[i].getAttribute("data-opt-val");
        }
      }
    }
    const reactId = e.target.getAttribute("id");
    const optionValueId = parseInt(getSelectedValue(e));
    const manifestationId = isVus ? 164 : 161;
    dispatch(manifestationsGeneticsUpsert(prId, manifestationId, optionValueId, reactId, isVus));
  }

  return {
    inputs, setInputs,
    formState, setFormState,
    errors, setErrors,
    optionValues,
    setOptionValues,
    handleBasicInputChange,
    handleInputChange,
    handleOptValInputChange,
    handleRadioChanged,
    handleGeneTextChange,
    handleDateColChange,
  };
}