import {MenuItem} from "@mui/material";

export const labelAndOptValsByOptId = {
  1: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={1}>Astrocytoma</MenuItem>,
        <MenuItem key={2} value={2}>Ependymoma</MenuItem>,
        <MenuItem key={3} value={3}>Glioblastoma</MenuItem>,
        <MenuItem key={4} value={4}>Glioma</MenuItem>,
        <MenuItem key={5} value={5}>Medulloblastoma</MenuItem>,
        <MenuItem key={6} value={6}>Meningioma</MenuItem>,
        <MenuItem key={7} value={7}>Neuroblastoma</MenuItem>,
        <MenuItem key={8} value={8}>Pinealblastoma</MenuItem>,
        <MenuItem key={9} value={9}>Schwannoma</MenuItem>,
        <MenuItem key={10} value={10}>I Don't Know</MenuItem>,
        <MenuItem key={11} value={529}>Other</MenuItem>,
      ]
    ,
  },
  65: {
    label: "Where did the brain cancer start?",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={571}>Brain</MenuItem>,
        <MenuItem key={2} value={572}>Breast</MenuItem>,
        <MenuItem key={3} value={573}>Colon</MenuItem>,
        <MenuItem key={4} value={574}>Lung</MenuItem>,
        <MenuItem key={5} value={575}>Other Part of the Body</MenuItem>,
        <MenuItem key={6} value={576}>I Don't Know</MenuItem>,
      ]

  },
  2: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={504}>Metastatic</MenuItem>,
        <MenuItem key={2} value={11}>DCIS</MenuItem>,
        <MenuItem key={3} value={12}>Invasive Ductal</MenuItem>,
        <MenuItem key={4} value={13}>Invasive Lobular</MenuItem>,
        <MenuItem key={5} value={14}>Triple Negative</MenuItem>,
        <MenuItem key={6} value={15}>Other</MenuItem>,
        <MenuItem key={7} value={16}>I Don't Know</MenuItem>,
      ]
    ,
  },
  36: {
    label: "Side",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={162}>Left</MenuItem>,
        <MenuItem key={2} value={163}>Right</MenuItem>,
        <MenuItem key={3} value={164}>BOTH Bi-lateral</MenuItem>,
        <MenuItem key={4} value={165}>I Don't Know</MenuItem>,
      ]
    ,
  },
  68: {
    label: "Was it hormone receptor positive?",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={586}>Yes</MenuItem>,
        <MenuItem key={2} value={587}>No</MenuItem>,
        <MenuItem key={3} value={588}>I Don't Know</MenuItem>,
      ]
    ,
  },
  69: {
    label: "Was it HER2 positive?",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={589}>Yes</MenuItem>,
        <MenuItem key={2} value={590}>No</MenuItem>,
        <MenuItem key={3} value={591}>I Don't Know</MenuItem>,
      ]
    ,
  },
  73: {
    label: "Did the cancer respond to Chemotherapy?",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={592}>Yes</MenuItem>,
        <MenuItem key={2} value={593}>No</MenuItem>,
        <MenuItem key={3} value={594}>No Chemo Treatment/Not Applicable</MenuItem>,
        <MenuItem key={4} value={595}>I Don't Know</MenuItem>,
      ]
    ,
  },
  74: {
    label: "Was it found in your lymph nodes?",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={596}>Yes {">=4"} Lymph Nodes</MenuItem>,
        <MenuItem key={2} value={597}>Yes {"<=3"} Lymph Nodes</MenuItem>,
        <MenuItem key={3} value={598}>Yes, Unsure of How Many</MenuItem>,
        <MenuItem key={4} value={599}>No</MenuItem>,
        <MenuItem key={5} value={600}>I Don't Know</MenuItem>,
      ]
    ,
  },
  3: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={530}>Other</MenuItem>,
        <MenuItem key={2} value={17}>Anus</MenuItem>,
        <MenuItem key={3} value={18}>Colon</MenuItem>,
        <MenuItem key={4} value={19}>Rectum</MenuItem>,
        <MenuItem key={5} value={20}>I Don't Know</MenuItem>,
      ]
    ,
  },
  5: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={28}>Renal Pelvis</MenuItem>,
        <MenuItem key={2} value={29}>Wilms tumor</MenuItem>,
        <MenuItem key={3} value={30}>Clear Cell Renal</MenuItem>,
        <MenuItem key={4} value={31}>Papillary</MenuItem>,
        <MenuItem key={5} value={32}>Transitional</MenuItem>,
        <MenuItem key={6} value={33}>I Don't Know</MenuItem>,
        <MenuItem key={7} value={518}>Other</MenuItem>,
      ]
    ,
  },
  6: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={520}>Other</MenuItem>,
        <MenuItem key={2} value={34}>Ampulla of Vater</MenuItem>,
        <MenuItem key={3} value={35}>Apudoma</MenuItem>,
        <MenuItem key={4} value={36}>Pancreatic Islet Cell</MenuItem>,
        <MenuItem key={5} value={37}>Periampullary</MenuItem>,
        <MenuItem key={6} value={38}>I Don't Know</MenuItem>,
      ]
    ,
  },
  7: {
    label: "Gleason Score",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={39}>6 or Under</MenuItem>,
        <MenuItem key={2} value={40}>8 or Above</MenuItem>,
        <MenuItem key={3} value={550}>7</MenuItem>,
        <MenuItem key={4} value={516}>I Don't Know</MenuItem>,
      ]
    ,
  },
  37: {
    label: "Risk Group",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={166}>Very Low</MenuItem>,
        <MenuItem key={2} value={167}>Low</MenuItem>,
        <MenuItem key={3} value={168}>Intermediate</MenuItem>,
        <MenuItem key={4} value={169}>High</MenuItem>,
        <MenuItem key={5} value={170}>Very High</MenuItem>,
        <MenuItem key={6} value={171}>I Don't Know</MenuItem>,
      ]
    ,
  }, 51: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={313}>Intraductal</MenuItem>,
        <MenuItem key={2} value={314}>Cribriform</MenuItem>,
        <MenuItem key={3} value={315}>Metastatic</MenuItem>,
        <MenuItem key={4} value={316}>Other</MenuItem>,
        <MenuItem key={5} value={317}>I Don't Know</MenuItem>,
      ]
    ,
  }, 52: {
    label: "PSA Value",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={318}>Less than 10ng/ml</MenuItem>,
        <MenuItem key={2} value={319}>10-20ng/ml</MenuItem>,
        <MenuItem key={3} value={320}>Greater than 20ng/ml</MenuItem>,
        <MenuItem key={4} value={321}>I Don't Know</MenuItem>,
      ]
    ,
  }, 8: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={517}>Other</MenuItem>,
        <MenuItem key={2} value={481}>Osteosarcoma</MenuItem>,
        <MenuItem key={3} value={482}>Soft Tissue</MenuItem>,
        <MenuItem key={4} value={42}>Chondrosarcoma</MenuItem>,
        <MenuItem key={5} value={43}>Dermatofibrosarcoma</MenuItem>,
        <MenuItem key={6} value={44}>Fibrosarcoma</MenuItem>,
        <MenuItem key={7} value={45}>Leiomyosarcoma</MenuItem>,
        <MenuItem key={8} value={46}>Liposarcoma</MenuItem>,
        <MenuItem key={9} value={47}>I Don't Know</MenuItem>,
      ]
    ,
  }, 9: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={48}>Follicular</MenuItem>,
        <MenuItem key={2} value={49}>Papillary</MenuItem>,
        <MenuItem key={3} value={50}>I Don't Know</MenuItem>,
        <MenuItem key={4} value={483}>Cribriform</MenuItem>,
        <MenuItem key={5} value={496}>Cribriform Variant Papillary</MenuItem>,
        <MenuItem key={6} value={497}>Follicular Variant Papillary</MenuItem>,
        <MenuItem key={7} value={468}>Medullary</MenuItem>,
        <MenuItem key={8} value={521}>Other</MenuItem>,
      ]
    ,
  }, 10: {
    label: "Side",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={51}>Left</MenuItem>,
        <MenuItem key={2} value={52}>Right</MenuItem>,
        <MenuItem key={3} value={53}>Unknown</MenuItem>,
      ]
    ,
  }, 11: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={54}>Bile Duct</MenuItem>,
        <MenuItem key={2} value={55}>Other</MenuItem>,
        <MenuItem key={3} value={528}>I Don't Know</MenuItem>,
      ]
    ,
  }, 12: {
    label: "Area",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={533}>I Don't Know</MenuItem>,
        <MenuItem key={2} value={534}>Small Bowel</MenuItem>,
        <MenuItem key={3} value={535}>Jejunum</MenuItem>,
        <MenuItem key={4} value={536}>Ileum</MenuItem>,
        <MenuItem key={5} value={56}>Duodenum</MenuItem>,
        <MenuItem key={6} value={57}>Other</MenuItem>,
      ]
    ,
  }, 58: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={505}>Diffuse Gastric</MenuItem>,
        <MenuItem key={2} value={506}>Other</MenuItem>,
        <MenuItem key={3} value={507}>I Don't Know</MenuItem>,
      ]
    ,
  }, 13: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={58}>Conjunctival</MenuItem>,
        <MenuItem key={2} value={59}>Eyelid Carcinoma</MenuItem>,
        <MenuItem key={3} value={60}>Hemangioma</MenuItem>,
        <MenuItem key={4} value={61}>Intraocular Lymphoma</MenuItem>,
        <MenuItem key={5} value={62}>Lacrimal Gland Tumor</MenuItem>,
        <MenuItem key={6} value={63}>Retinoblastoma</MenuItem>,
        <MenuItem key={7} value={64}>I Don't Know</MenuItem>,
      ]
    ,
  },
  64: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={552}>Adenocarcinoma</MenuItem>,
        <MenuItem key={2} value={553}>Carcinoid Tumors</MenuItem>,
        <MenuItem key={3} value={554}>Adenosquamous Carcinoma</MenuItem>,
        <MenuItem key={4} value={555}>Non-small Cell</MenuItem>,
        <MenuItem key={5} value={556}>Squamous Cell</MenuItem>,
        <MenuItem key={6} value={557}>Large-cell Undifferentiated Carcinoma</MenuItem>,
        <MenuItem key={7} value={558}>Small Cell</MenuItem>,
        <MenuItem key={8} value={559}>Lung Carcinoid</MenuItem>,
        <MenuItem key={9} value={560}>Metastatic</MenuItem>,
        <MenuItem key={10} value={561}>Large Cell Neuroendocrine Carcinoma</MenuItem>,
        <MenuItem key={11} value={562}>Salivary Gland-type</MenuItem>,
        <MenuItem key={12} value={563}>Mesothelioma</MenuItem>,
        <MenuItem key={13} value={564}>Other</MenuItem>,
        <MenuItem key={14} value={565}>I Don't Know</MenuItem>,
      ]
    ,
  }, 14: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={519}>Other</MenuItem>,
        <MenuItem key={2} value={65}>B Cell</MenuItem>,
        <MenuItem key={3} value={66}>Hodgkins</MenuItem>,
        <MenuItem key={4} value={67}>Non-Hodgkins</MenuItem>,
        <MenuItem key={5} value={68}>I Don't Know</MenuItem>,
      ]
    ,
  }, 15: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={69}>Larynx</MenuItem>,
        <MenuItem key={2} value={70}>Parotid Gland</MenuItem>,
        <MenuItem key={3} value={71}>Pharynx</MenuItem>,
        <MenuItem key={4} value={72}>Pharyngeal</MenuItem>,
        <MenuItem key={5} value={73}>Salivary Gland</MenuItem>,
        <MenuItem key={6} value={74}>Tongue</MenuItem>,
        <MenuItem key={7} value={75}>Tonsil</MenuItem>,
        <MenuItem key={8} value={76}>I Don't Know</MenuItem>,
        <MenuItem key={9} value={531}>Other</MenuItem>,
      ]
    ,
  }, 16: {
    label: "Type",
    select:
      [
        <MenuItem key={0} value={""}>Please Select</MenuItem>,
        <MenuItem key={1} value={532}>Other</MenuItem>,
        <MenuItem key={2} value={548}>Melanoma</MenuItem>,
        <MenuItem key={3} value={549}>Uveal Melanoma</MenuItem>,
        <MenuItem key={4} value={551}>Atypical Spitz Tumor</MenuItem>,
        <MenuItem key={5} value={77}>Basal Cell Carcinoma</MenuItem>,
        <MenuItem key={6} value={78}>Sebaceous Skin Neoplasm</MenuItem>,
        <MenuItem key={7} value={79}>Squamous Cell Carcinoma</MenuItem>,
        <MenuItem key={8} value={80}>I Don't Know</MenuItem>,
      ]
    ,
  },
}