import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useCurrentPosition} from "../Pages/PatientQuestionnaire/Helpers/stepOrder";
import {updateQuestionnaireReturnLater} from "../Redux/users/userSlice";

export function useFormNavigation() {
  const dispatch = useDispatch();
  const usersState = useSelector(state => state.users);
  const navigate = useNavigate();
  const {
    entities,
    usersPatientId,
  } = usersState;
  const {gender} = ((entities && usersPatientId) && entities[usersPatientId]) ? entities[usersPatientId] : undefined;
  const {previous, next} = useCurrentPosition(gender);

  /*
  * This structure was a remnant of the old questionnaire and is hacked together with redux instead of being properly fixed
  * TODO update 10_BasicInfo and 13_LifestyleHtWt to use redux reducers instead of this
  * */

  // const getInitialFormState = () => {
  //   let {
  //     firstName,
  //     lastName,
  //     dob,
  //     patientRace,
  //     gender,
  //     height,
  //     heightFt,
  //     heightIn,
  //     weight,
  //     genderIdentity,
  //     preferredPronouns,
  //     questionaireReturnLater
  //   } = entities[usersPatientId];
  //  
  //   return {
  //     firstName: firstName ? firstName : "",
  //     lastName: lastName ? lastName : "",
  //     dob: dob ? dob : null,
  //     patientRace: patientRace ? patientRace : null,
  //     gender: gender ? gender : null,
  //     genderIdentity: genderIdentity ? genderIdentity : null,
  //     preferredPronouns: preferredPronouns ? preferredPronouns : null,
  //     height: height ? height : 0,
  //     weight: weight ? weight : 0,
  //     heightFt: heightFt ? heightFt : 0,
  //     heightIn: heightIn ? heightIn : 0,
  //     questionaireReturnLater: questionaireReturnLater ? questionaireReturnLater : null
  //   }
  // }

  const handleFormNavigation = (e) => {
    e.preventDefault();
    // JetBrains hates 'var'
    let newSlug;
    switch (e.nativeEvent.submitter.name) {
      case "back":
        newSlug = previous.path
        break;
      case "next":
        newSlug = next.path
        break;
      default:
        newSlug = undefined
        break;
    }
    // update user with return to slug

    // this was blocking other API calls on form submission
    // only one API PUT/POST can be made at a time unless they are chained together
    // updateUser(modUser).unwrap()
    //   .then(() => navigate(`/health-questionnaire/${newSlug}`));
    dispatch(updateQuestionnaireReturnLater(newSlug));
    navigate(`/health-questionnaire/${newSlug}`);
  }

  return {
    handleFormNavigation,
  };
}